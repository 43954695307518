export default <template>
<div class="wechatmpbinding">
  <FullLoading v-if="isLoading"></FullLoading>
  <template v-else>
    <img class="top__img" src="./images/account_binding_ illustration@2x.png" alt="绑定成功">
    <!-- <div class="wechatmpbinding__title">当前微信已绑定如下账号</div> -->
    <!-- <div class="wechatmpbinding__phone_number">{{ this.bindingInfo.phone }}</div> -->
    <!-- <div class="wechatmpbinding__title_small">
      如需更换绑定账号，请点击<span class="change-binding" @click="goChangeWechatBinding">更换绑定账号<i class="xiaoicon ringtIocnStyle">&#xe678;</i></span>
    </div> -->
    <div class="bottom">
      <div class="bottom__student-list-container">
        <template v-if="bindingInfo.studentList.length === 0">
          <span class="bottom__student-empty-tips">当前微信暂无绑定账号</span>
          <div class="bottom__student-btn-bind-sm" @click="goBindWechat">绑定新账号</div>
        </template>
        <template v-else>
          <div class="bottom__student-list">
            <div v-for="item in bindingInfo.studentList" :key="item.user_student_id" class="bottom__student-list-student">
              <div class="bottom__student-list-student-info">
                <div class="bottom__student-list-student-name">{{item.name}}</div>
                <div class="bottom__student-list-student-phone">{{item.phone}}</div>
              </div>
              <div class="bottom__student-list-student-btn-group">
                <div class="bottom__student-list-student-btn bottom__student-list-student-btn-relievebinding" @click="goUnbindWechat(item)">解绑</div>
                <div class="bottom__student-list-student-btn bottom__student-list-student-btn-changebinding" @click="goChangeWechatBinding(item)">换绑</div>
              </div>
            </div>
          </div>
          <div class="bottom__student-btn-bind-large" @click="goBindWechat">绑定新账号</div>
        </template>
      </div>
    </div>
  </template>
  <!-- <div class="wechatmpbinding__failed" v-if="isBindFailed">绑定公众号失败</div> -->
  <ModalTips 
    :visible="modalTips.show" 
    :title="modalTips.title" 
    :content="modalTips.content" 
    :btn="modalTips.btn">
  </ModalTips>
  <ModalRelationship 
    :visible="showRelationshipModal" 
    :student="studentBinded"
    @select="handleRelationshipSelect" 
    @cancel="showRelationshipModal = false">
  </ModalRelationship>
</div>
</template>

<style lang="sass" src="./css/WechatMpBinding.scss"></style>

<script>
import Vue from 'vue'
import http from 'utils/http'
import FullLoading from 'components/FullLoading.vue'
import { getRedirectHost } from 'utils/host'
import globalCookieHandler from 'src/store/cookie'
import ModalTips from './components/ModalTips.vue'
import ModalRelationship from './components/ModalRelationship.vue'
import MsgToast from './components/toast'
import removeURLParameter from 'utils/removeURLParameter.js'

const BIND_WECHAT_TYPE = {
  BIND: '1', // 绑定
  UNBIND: '2', // 解绑
  CHANGE: '3' // 换绑
}

export default {
  components: { FullLoading, ModalTips, ModalRelationship },
  data() {
    return {
      isLoading: false,
      // isBindSuccess: true,
      // isBindFailed: false,
      bindingInfo: {
        studentList: [],
        // uuid: null,
        wx_user_id: null
      },
      showRelationshipModal: false,
      modalTips: {
        show: false,
        title: '',
        content: '',
        btn: {
          text: '我知道了',
          handleBtnClick: () => {this.modalTips.show = false}
        }
      },
      studentBinded: {
        name: "",
        phone: "",
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if(!Vue.prototype.$UA.weixin) {
      // 不是微信环境，直接跳转首页
      next('/')
    } else {
      if(to.query.code) {
        next()
      } else {
        // 获取微信公众号code
        const REDIRECT_FILE_ADDRESS = `${__HOST__}/wechat/mp/`
        const _next = `${getRedirectHost()}${to.fullPath}`
        const url = '/koala/api/v1.5/auth/wechat-mp-code'
        const redirect_uri = encodeURIComponent(`${REDIRECT_FILE_ADDRESS}?next=${encodeURIComponent(_next)}`)  
        http.get(url, { params: { redirect_uri } })
          .then(res => {
            // 根据跳转这个接口拿到code。拿到code后又会跳回来，走获取绑定状态的过程
            window.location.href = res.data.oauth
          })
          .catch(() => {
            // 该接口不再判断登录状态
            // Vue.prototype.$xiaoToast.warning('获取授权失败')
            MsgToast({
              msg: '获取授权失败',
              type: 'warning'
            })
          })
      }
    }
  },
  created() {
    this.bindwechattype = sessionStorage.getItem('bindwechattype') || ''
    sessionStorage.removeItem('bindwechattype')
    this.bindingInfo.wx_user_id = sessionStorage.getItem('bindingWechatUserId') || ''

    this.curStudentToken = globalCookieHandler['token'] || ''
    this.curStudentId = globalCookieHandler['user_id'] || ''
    const lastStudentToken = sessionStorage.getItem('lastStudentToken') || ''
    const lastStudent = sessionStorage.getItem('lastStudentBindWechat') || ''
    sessionStorage.removeItem('lastStudentBindWechat')
    sessionStorage.removeItem('lastStudentToken')
    
    //未登录： 未登录返回
    //登录状态下： 未登录返回/登录同一个/登录不同账号

    // 未登录返回（由浏览器页面后退回来）
    // 每次登录的token不一样
    if(this.curStudentToken === lastStudentToken) {
      // 获取绑定列表
      this.getWechatBindingStatus()
      return
    }
    // 登录返回
    if(this.bindwechattype === BIND_WECHAT_TYPE.BIND) { 
      // 由绑定新账号登录回来
      this.bindingWechat()
    } else if(this.bindwechattype === BIND_WECHAT_TYPE.UNBIND) {
      // 由解绑登录回来
      if(globalCookieHandler.user_id !== sessionStorage.getItem('unBindingWechatTarget')) {
        sessionStorage.removeItem('unBindingWechatTarget')
        this.modalTips.title = '解绑失败'
        this.modalTips.content = '您登录的账号与解绑账号不一致，请登录需要解绑的账号。'
        this.modalTips.show = true
        this.getWechatBindingStatus()
        return
      }
      this.unbindWechat()
    } else if(this.bindwechattype === BIND_WECHAT_TYPE.CHANGE) { 
      // 由换绑登录回来
      this.changeBindWechat()
    } else {
      // 获取绑定状态
      this.getWechatBindingStatus()
    }
    this.isRequesting = false
  },
  methods: {
    // 绑定微信，跳转登录
    goBindWechat() {
      if(this.$UA.weixin){
        sessionStorage.setItem('lastStudentToken', globalCookieHandler['token'] || '')
        sessionStorage.setItem('lastStudentBindWechat', this.curStudentId)
        sessionStorage.setItem('bindwechattype', BIND_WECHAT_TYPE.BIND)
        sessionStorage.setItem('afterLoginRoute', window.location.href)
        window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
      }
    },
    // 更换账号，跳转登录
    goChangeWechatBinding(student) {
      if(this.$UA.weixin){
        sessionStorage.setItem('lastStudentToken', globalCookieHandler['token'] || '')
        sessionStorage.setItem('lastStudentBindWechat', this.curStudentId)
        sessionStorage.setItem('bindwechattype', BIND_WECHAT_TYPE.CHANGE)
        sessionStorage.setItem('changeBindingWechatTarget', student.user_student_id)
        sessionStorage.setItem('afterLoginRoute', window.location.href)
        window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
      }
    },
    // 解绑微信，跳转登录
    goUnbindWechat(student) {
      if(this.$UA.weixin){
        sessionStorage.setItem('bindwechattype', BIND_WECHAT_TYPE.UNBIND)
        // 如果当前登录的学员不是要解绑的学员，需要先登录
        if(student.user_student_id !== this.curStudentId) {
          sessionStorage.setItem('lastStudentToken', globalCookieHandler['token'] || '')
          sessionStorage.setItem('lastStudentBindWechat', this.curStudentId)
          sessionStorage.setItem('unBindingWechatTarget', student.user_student_id)
          sessionStorage.setItem('afterLoginRoute', window.location.href)
          window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
        } else {
          // 解绑当前登录的学员时，bindwechattype取最新的状态，否则可能会进入绑定流程
          this.bindwechattype = sessionStorage.getItem('bindwechattype')
          sessionStorage.removeItem('bindwechattype')
          this.unbindWechat()
        }
      }
    },
    // 获取微信绑定账号列表
    getWechatBindingStatus () {
      this.isLoading = true
      const code = this.$route.query.code
      const wx_user_id = this.bindingInfo.wx_user_id
      const params = {}
      if (wx_user_id) {
        params.wx_user_id = Number(wx_user_id)
      } else {
        params.code = code
      }
      http.get('/koala/api/v1.5/auth/wechat/mp/binding', { params }).then(res => {
        this.bindingInfo.studentList = res.data.student_list || []
        this.bindingInfo.wx_user_id = res.data.wx_user_id
        this.isLoading = false
        sessionStorage.setItem('bindingWechatUserId', res.data.wx_user_id)
        // 如果当前微信没有绑定学员，进入绑定流程
        // 如果是解绑最后一个学员，进入空状态的页面，不进入绑定流程
        const bindwechattype = this.bindwechattype || ''
        if(this.bindingInfo.studentList.length === 0 && bindwechattype !== BIND_WECHAT_TYPE.UNBIND) {
          this.bindingWechat()
        }
      }).catch(err => {
        this.isLoading = false
        if (err.response && err.response.data.err === 3621) {
          MsgToast({
            msg: '绑定失败: code错误',
            type: 'warning'
          })
          this.getWeChatCode()
        } else if(err.response && err.response.data.err === 3620) {
          this.modalTips.title = '绑定失败'
          this.modalTips.content = '请先关注公众号！'
          this.modalTips.show = true
        } else {
          MsgToast({
            msg: '获取账号列表失败: 未知错误',
            type: 'warning'
          })
        }
      })
    },
    // 绑定微信
    bindingWechat () {
      this.isLoading = true
      const code = this.$route.query.code
      const wx_user_id = this.bindingInfo.wx_user_id
      let params = {}
      if (wx_user_id) {
        params = {
          wx_user_id: Number(wx_user_id)
        }
      } else {
        params = {
          code,
          wx_user_id: Number(wx_user_id)
        }
      }
      http.post('/koala/api/v1.5/auth/bind-wechat-mp', params).then(res => {
        this.isLoading = false
        this.getWechatBindingStatus()
        this.studentBinded.name = res.data.name
        this.studentBinded.phone = res.data.phone
        this.bindingInfo.wx_user_id = res.data.wx_user_id
        this.toggleShowRelationshipModal(true)
        if(!res.data.is_binded) { // is_binded表示是否已绑定过
          MsgToast({
            msg: '绑定成功！',
            type: 'success'
          })
        }
      }).catch(error => {
        this.isLoading = false
        if (error && error.response) {
          if (error.response.status === 401) {
            MsgToast({
              msg: '请先登录',
              type: 'warning'
            })
            setTimeout(() => {
              // 存一下uuid，换绑的时候需要
              // sessionStorage.setItem('changeBindingWechat', this.bindingInfo.uuid)
              sessionStorage.setItem('afterLoginRoute', window.location.href)
              window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
            }, 1000)
          } else {
            const err = error.response.data && error.response.data.err
            if (err === 3619 || err === 3621) {
              // 3619 微信公众号授权信息缓存失效
              // 3621 微信公众号授权code已被使用
              this.getWeChatCode()
            } else if (err === 3620) {
              this.modalTips.title = '绑定失败'
              this.modalTips.content = '请先关注公众号！'
              this.modalTips.show = true
            } else if(err === 3625) {
              MsgToast({
                msg: '绑定人数超过限制',
                type: 'warning'
              })
              this.getWechatBindingStatus() // 获取列表
            } else {
              MsgToast({
                msg: '绑定失败',
                type: 'warning'
              })
            }
          }
        } else {
          MsgToast({
            msg: '绑定失败',
            type: 'warning'
          })
          // this.$xiaoToast.warning('绑定失败')
        }
      })
    },
    // 获取微信公众号code
    getWeChatCode () {
      let fetchCodeCount = sessionStorage.getItem('fetchCodeCount')
      if (fetchCodeCount > 10) {
        sessionStorage.removeItem('fetchCodeCount')
        this.isLoading = false
        MsgToast({
          msg: '获取code次数过多，绑定失败~',
          type: 'warning'
        })
        return
      } else if (fetchCodeCount) {
        sessionStorage.setItem('fetchCodeCount', ++fetchCodeCount)
      } else {
        sessionStorage.setItem('fetchCodeCount', 1)
      }
      const REDIRECT_FILE_ADDRESS = `${__HOST__}/wechat/mp/`
      // 这里是path不是fullpath，不带参数
      const _next = `${getRedirectHost()}${this.$route.path}`
      const url = '/koala/api/v1.5/auth/wechat-mp-code'
      const redirect_uri = encodeURIComponent(`${REDIRECT_FILE_ADDRESS}?next=${encodeURIComponent(_next)}`)
      http.get(url, { params: { redirect_uri } })
        .then(res => {
          window.location.href = res.data.oauth
        })
        .catch(() => {
          MsgToast({
            msg: '获取授权失败',
            type: 'warning'
          })
        })
    },
    // 微信解绑学员账号
    unbindWechat() {
      if(this.isRequesting) {
        return
      }
      const params = {}
      const code = this.$route.query.code
      const wx_user_id = this.bindingInfo.wx_user_id
      if (wx_user_id) {
        params.wx_user_id = Number(wx_user_id)
      } else {
        params.code = code
        params.wx_user_id = Number(wx_user_id)
      }
      this.isLoading = true
      this.isRequesting = true
      http.post('/koala/api/v1.5/auth/unbind-wechat-mp', params).then(res => {
        this.isLoading = false
        this.getWechatBindingStatus()
        MsgToast({
          msg: '解绑成功！',
          type: 'success'
        })
        // 解绑后需要清除登录态，解决的问题是
        // 用户在解绑最后一个学员后返回页面会进入自动绑定的流程；
        // 用户在解绑后打开公众后其他页面会自动绑定上
        this.clearLoginStatus()
      }).catch(error => {
        this.isLoading = false
        if (error && error.response) {
          if (error.response.status === 401) {
            MsgToast({
              msg: '请先登录',
              type: 'warning'
            })
            setTimeout(() => {
              sessionStorage.setItem('afterLoginRoute', window.location.href)
              window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
            }, 1000)
          } else {
            const err = error.response.data && error.response.data.err
            if (err === 3619 || err === 3621) {
              // 3619 微信公众号授权信息缓存失效
              // 3621 微信公众号授权code已被使用
              this.getWeChatCode()
            } else {
              MsgToast({
                msg: '解绑失败',
                type: 'warning'
              })
              // 如果不清，再次点击相同账号的解绑就进不了解绑的流程啦
              this.clearLoginStatus()
            }
          }
        } else {
          MsgToast({
            msg: '解绑失败',
            type: 'warning'
          })
          // 如果不清，再次点击相同账号的解绑就进不了解绑的流程啦
          this.clearLoginStatus()
        }
      }).finally(() => {
        this.isRequesting = false
      })
    },
    // 微信换绑学员账号
    changeBindWechat() {
      const params = {
        unbind_student_id: sessionStorage.getItem('changeBindingWechatTarget')
      }
      const code = this.$route.query.code
      const wx_user_id = this.bindingInfo.wx_user_id
      if (wx_user_id) {
        params.wx_user_id = Number(wx_user_id)
      } else {
        params.code = code
        params.wx_user_id = Number(wx_user_id)
      }
      this.isLoading = true
      http.post('/koala/api/v1.5/auth/change-bind-wechat-mp', params).then(res => {
        this.isLoading = false
        this.getWechatBindingStatus()
        this.studentBinded.name = res.data.name
        this.studentBinded.phone = res.data.phone
        this.toggleShowRelationshipModal(true)
        MsgToast({
          msg: '换绑成功！',
          type: 'success'
        })
      }).catch(error => {
        this.isLoading = false
        if (error && error.response) {
          if (error.response.status === 401) {
            MsgToast({
              msg: '请先登录',
              type: 'warning'
            })
            setTimeout(() => {
              // 存一下uuid，换绑的时候需要
              // sessionStorage.setItem('changeBindingWechat', this.bindingInfo.uuid)
              sessionStorage.setItem('afterLoginRoute', window.location.href)
              window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(location.href)}`
            }, 1000)
          } else {
            const err = error.response.data && error.response.data.err
            if (err === 3619 || err === 3621) {
              // 3619 微信公众号授权信息缓存失效
              // 3621 微信公众号授权code已被使用
              this.getWeChatCode()
            } else if (err === 3620) {
              this.modalTips.title = '换绑失败'
              this.modalTips.content = '请先关注公众号！'
              this.modalTips.show = true
            } else if (err === 3625) {
              MsgToast({
                msg: '绑定人数超过限制',
                type: 'warning'
              })
            } else {
              MsgToast({
                msg: '换绑失败',
                type: 'warning'
              })
            }
          }
        } else {
          MsgToast({
            msg: '换绑失败',
            type: 'warning'
          })
        }
      })
    },
    toggleShowRelationshipModal(isShow) {
      this.showRelationshipModal = isShow
    },
    // 选择关系
    handleRelationshipSelect(relation) {
      if(this.isRequesting) {
        return
      }
      const params = {
        wx_uer_id: Number(this.bindingInfo.wx_user_id),
        relation: parseInt(relation)
      }
      this.isRequesting = true
      http.post('/koala/api/v1.5/auth/update-bind-relation', params).then(res => {
      }).catch(err => {
      }).finally(() => {
        this.isRequesting = false
        this.showRelationshipModal = false
      })
    },
    clearLoginStatus() {
      globalCookieHandler['token'] = null
      globalCookieHandler['user_id'] = null
      this.curStudentId = ''
      this.curStudentToken = ''
    }
  }
}
</script>
