export default <template>
  <div :class="$style.formWrapper">
    <div :class="[$style.formLabel]">姓名</div>
    <div :class="$style.nameInputWrapper">
      <input type="text"
        ref="nameInput"
        :value="userInfo.name"
        @input="handleNameChange"
        maxlength="5"
        placeholder="请填写真实的姓名，方便老师认识你"
        :class="[$style.input, isNameInvalid ? $style.needTip : '']">
      <div v-if="isNameInvalid"
        :class="$style.nameTip">*2-5个汉字，不支持特殊字符</div>
      <div v-show="userInfo.name"
        @click="handleClearName"
        :class="$style.closeIcon"
        class="xiaoicon">&#xe60d;</div>

    </div>
    <div :class="$style.formLabel">在读年级</div>
    <div :class="$style.selectWrapper"
      @click="openGradeModal">
      <div v-if="userInfo.grade"
        :class="$style.fillInContent">
        {{userInfo.grade | grade}}
      </div>
      <span v-else
        :class="$style.gradePlaceholder">请选择年级</span>
      <i class="xiaoicon"
        :class="$style.arrowIcon">&#xe659;</i>
    </div>

    <template v-if="hasCity">
      <div :class="$style.formLabel">生源地（如需修改请联系辅导老师）</div>
      <div :class="$style.userCenterCity">
        {{userInfo.distCode.province_name || '未填写'}}
        <span v-if="userInfo.distCode.city_name && (userInfo.distCode.province_name !== userInfo.distCode.city_name)">-{{userInfo.distCode.city_name}}</span>
        <span v-if="userInfo.distCode.district_name">-{{userInfo.distCode.district_name}}</span>
      </div>
    </template>
    <template v-else-if="canEdit">
      <div :class="$style.formLabel">生源地（高考所在城市）</div>
      <div :class="$style.selectWrapper"
        @click="openDistrictModal">
        <div v-if="userInfo.distCode.province"
          :class="$style.fillInContent">
          {{userInfo.distCode.province_name || '未填写'}}
          <span v-if="userInfo.distCode.city_name">-{{userInfo.distCode.city_name}}</span>
          <span v-if="userInfo.distCode.district_name">-{{userInfo.distCode.district_name}}</span>
        </div>
        <span v-else
          :class="$style.gradePlaceholder">省/直辖市-市-区/县</span>
        <i class="xiaoicon"
          :class="$style.arrowIcon">&#xe659;</i>
      </div>
    </template>
    <template v-else><div /></template>
    <div :class="$style.bottomWrapper">
      <button 
        type="button"
        :class="['btn', $style.btn, canSubmit ? '' : $style.disabled, confirmBtnStyle]"
        :disabled="!canSubmit"
        @click="handleSubmit">
        提&ensp;交
      </button>
    </div>

    <SelectStudentOriginModal 
      ref="district"
      @select="handleDistrictChange"
    />

    <SelectGradeModal 
      ref="SelectGradeModal"
      :titleVisible="false"
      :grade.sync="userInfo.grade"
    />

  </div>
</template>

<script>
import SelectGradeModal from 'components/SelectGradeModal/index.vue'
import { nameRegExp } from 'utils/regexp'
import { api_editStudentInfo, api_editStudentLocation } from 'api/account'
import axios from 'axios'
import globalCookieHandler from 'src/store/cookie'
import jump from 'utils/jump'
import { mapState, mapActions } from 'vuex'
import XiaoModal from 'components/xiao-modal/XiaoModal.vue'
import SelectStudentOriginModal from 'components/SelectStudentOriginModal/index.vue'

export default {
  name: 'UserInfoForm',
  components: { SelectGradeModal, XiaoModal, SelectStudentOriginModal },
  props: {
    confirmBtnStyle: String
  },
  data() {
    return {
      isLogin: !!globalCookieHandler.token,
      userInfo: {
        name: '',
        grade: '',
        distCode: {
          province: '',
          province_name: '',
          city: '',
          city_name: '',
          district: '',
          district_name: ''
        }
      },
      hasCity: false,
      isNameInvalid: false,
      Districtvisible: false
    }
  },
  computed: {
    canSubmit() {
      if (!this.canEdit) {
        return (
          this.userInfo.name &&
          this.userInfo.grade
        )
      }
      return (
        this.userInfo.name &&
        this.userInfo.grade &&
        (this.userInfo.distCode.district ||
          this.userInfo.distCode.city ||
          this.userInfo.distCode.province)
      )
    },
    isInviteActivityJoin() {
      return this.$route.query.is_invite_activity_join
    },
    ...mapState('auth', ['user', 'editLocationLimit']),
    canEdit() {
      return this.editLocationLimit.can_edit
    }
  },
  methods: {
    ...mapActions('auth', ['getUserExtraInfo']),
    handleNameChange($event) {
      this.userInfo.name = $event.target.value
      this.isNameInvalid = false
    },
    handleClearName() {
      this.userInfo.name = ''
      this.isNameInvalid = false
    },
    handleDistrictChange(distCode) {
      this.$refs.district.toggleVisible(false)
      this.userInfo.distCode = distCode
    },
    openDistrictModal() {
      this.$refs.district.toggleVisible(true)
      this.Districtvisible = true
    },
    openGradeModal() {
      this.$refs.SelectGradeModal.toggleVisible(true)
    },
    handleSubmit() {
      if (!nameRegExp.test(this.userInfo.name)) {
        this.isNameInvalid = true
        return
      }
      const baseInfor = {
        name: this.userInfo.name,
        grade: String(this.userInfo.grade)
      }
      if (this.hasCity || !this.canEdit) {
        api_editStudentInfo(baseInfor).then(() => {
          this.$emit('user-info-saved', baseInfor)
        })
      } else {
        axios
          .all([
            api_editStudentInfo(baseInfor),
            api_editStudentLocation({
              city_code:
                this.userInfo.distCode.district ||
                this.userInfo.distCode.city ||
                this.userInfo.distCode.province
            })
          ])
          .then(
            axios.spread(() => {
              this.$emit('user-info-saved', baseInfor)
            })
          ).catch(err => {
            if (err.response && err.response.data) {
              this.$xiaoToast.warning(err.response.data.msg || '修改失败')
            } else {
              this.$xiaoToast.warning('修改失败')
            }
          })
      }
    }
  },
  created() {
    if (this.isLogin) {
      let studentInfo = this.user || {}
      console.log('studentInfo: ', studentInfo)
      this.userInfo.name = studentInfo.name
      this.userInfo.grade = studentInfo.grade
      this.userInfo.distCode.province = studentInfo.province
      this.userInfo.distCode.province_name = studentInfo.province_name
      this.userInfo.distCode.city = studentInfo.city
      this.userInfo.distCode.city_name = studentInfo.city_name
      this.userInfo.distCode.district = studentInfo.district
      this.userInfo.distCode.district_name = studentInfo.district_name
      this.hasCity = !!studentInfo.province
      console.log('this.hasCity: ', this.hasCity)
      this.getUserExtraInfo()
    } else {
      jump.login()
    }
  }
}
</script>

<style lang="scss" module>
.formWrapper {
  padding: 0 .5333333333333333rem;
}

.formLabel {
  font-size: 0.373333rem /* 28/75 */;
  line-height: 1;
  color: #8a8a8a;
}

.nameInputWrapper {
  position: relative;
  margin-bottom: .5333333333333333rem;
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  &:active {
    color: #8a8a8a;
  }
}

.nameTip {
  position: absolute;
  right: 0.853333rem /* 64/75 */;
  top: 50%;
  transform: translateY(-50%);
  color: #fd7663;
  font-size: 0.346667rem /* 26/75 */;
  line-height: 1;
}

.input {
  height: 1.28rem;
  border: none;
  border-bottom: .0133333333333333rem solid #ccc;
  width: 100%;
  outline: none;
  font-size: 0.373333rem /* 28/75 */;
  line-height: 1;
  color: #464646;
  caret-color: #00c55d;
  background: none;
  &.needTip {
    border-color: #fd7663;
  }
  &:focus {
    border-color: #00c55d;
  }
  &::placeholder {
    font-size: 0.373333rem /* 28/75 */;
    color: #ccc;
    font-family: Arial;
    // iOS不用rem才能垂直居中，原因还不清楚
    // line-height: 48px;
  }
}

.selectWrapper {
  position: relative;
  display: flex;
  margin-bottom: .5333333333333333rem;
  height: 1.28rem /* 96/75 */;
  align-items: center;
  border-bottom: .0133333333333333rem solid #ccc;

}

.gradePlaceholder {
  color: #ccc;
  font-size: 0.373333rem /* 28/75 */;
  line-height: 1;
}

.arrowIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.426667rem /* 32/75 */;
  color: #ccc;
}
.fillInContent {
  font-size: 0.373333rem /* 28/75 */;
  line-height: 1;
  color: #464646;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: .4266666666666667rem;
}
.bottomWrapper {
  margin-bottom: 0.7466666666666667rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.btn {
  width: 6.133333333333334rem;
  margin-top: .2133333333333333rem;
  height: 1.28rem /* 96/75 */;
  border-radius: 0.64rem /* 48/75 */;
  box-shadow: 0 0.16rem /* 12/75 */ 0.32rem /* 24/75 */ 0 rgba(0, 197, 93, 0.24);
  background: linear-gradient(
    90deg,
    rgba(78, 220, 102, 1) 0%,
    rgba(0, 197, 93, 1) 100%
  );
  font-size: 0.426667rem /* 32/75 */;
  font-weight: 500;
  &.disabled {
    background: #e1e1e1;
    box-shadow: none;
  }
}
.userCenterCity {
  line-height: 1.28rem /* 96/75 */;
  font-size: 0.373333rem /* 28/75 */;
  color: #464646;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    .input,.selectWrapper { border-bottom: .0066666666666667rem solid #cccccc }
}
@media screen and (-webkit-min-device-pixel-ratio: 3) {
    .input,.selectWrapper { border-bottom: .00444444rem solid #cccccc }
}
</style>