import Cooker from 'xiao-utils/cooker'

/**
 * @example
 * import globalCookieHandler from 'src/store/cookie'
 * const token = globalCookieHandler.token // 获取
 * globalCookieHandler.token = 'setAuthValue' // 设置
 * globalCookieHandler.token = null // 删除
 * globalCookieHandler.token = void 0 // 删除
 */
const globalCookieHandler = {}

const inCookieValues = ['token', 'user_id']

inCookieValues.forEach(key => {
  Object.defineProperty(globalCookieHandler, key, {
    configurable: false,
    get() {
      return Cooker.get(key)
    },
    set(value) {
      if (value === null || typeof value === 'undefined') {
        Cooker.remove(key, '/')
      } else {
        Cooker.put(key, value, { vEnd: Infinity , sPath: '/'})
      }
    }
  })
})

export default globalCookieHandler
