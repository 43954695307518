/**
 * 课程详情相关的枚举
 */
const enumCurriculum = {
  /** 购买状态 */
  "serviceStatus": {
    /** 未购买: 0 */
    "NOT_BOUGHT": 0,
    /** 体验课: 1 */
    "TRIAL": 1,
    /** 已购买: 2 */
    "BOUGHT": 2,
    /** 已退课: 3 */
    "DROP": 3,
    /** 邀请解锁第一节: 4 */
    "UNLOCK": 4,
    /** 退课申请中 */
    "DROPING": 5,
    /** 换课锁定 */
    "CHANGELOCKING": 10,
  },
  /** 课程类型 */
  "genre": {
    /** 长期课（正价）: 1 */
    "LONG_TERM_NORMAL": 1,
    /** 长期课（低价）: 6 */
    "LONG_TERM_LOWER_PRICE": 6,
    /** 营销体验课 （原低价课） */
    "LONG_EXPRIENCE": 8,
    /** 免费公开课: 2 */
    "FREE_PUBLIC": 2,
    /** 短期课: 3 */
    "SHORT_TERM": 3,
    /** 1对1课程: 5 */
    "OTO": 5,
    /** 1对1免费公开课: 7 */
    "FREE_PUBLIC_OTO": 7,
    /** 短期课（正价）: 9 */
    "SHORT_TERM_NORMAL": 9,
  },
  /** [课时直播状态](https://gitlab.xinghuolive.com/appointment/Appointment/blob/master/cheatsheet.md#课时直播状态) */
  "broadcastStatus": {
    /** 未直播 */
    "INITIAL": 0,
    /** 正在直播 */
    "ONLIVE": 1,
    /** 已直播 */
    "END": 2
  },
  "LEVEL_MAP": {
    2: '鸿志班（3星）',
    1: '敏思班（4星）',
    3: '博学班（5星）',
    11: '素养班（5星）',
    12: '能力班（4星）',
    13: '清北班（5星）',
    14: '同步班（4星）',
    15: '实验班（4星）',
  },
}

export default enumCurriculum

export const Level2Stars = {
  "2": 3, // 鸿志班（3星）
  "1": 4, // 敏思班（4星）
  "3": 5, // 博学班（5星）
  "11": 5, // 素养班（5星）
  "12": 4, // 能力班（4星）
  "13": 5, // 清北班（5星）
  "14": 4, // 同步班（4星）
  "15": 4, // 实验班（4星）
}