export default <template>
<div :class="$style.wrapper">
  <label :class="$style.label" v-if="!!this.label" @click="inputFocus">{{this.label}}</label>

  <input :disabled="inputDisabled" type="tel" ref="input" :maxlength="lengthFormat" id="cleave" :placeholder="placeholder"
    :class="[$style.phoneInput, {[$style.needTip]: showErrorBorder, [$style.padding]:!!label}, this.$UA.isIos ? $style.iosInput : '']"/>
  <div :class="$style.tips" v-show="tipsVisible && showTip">*号码格式错误</div>
  <div v-show="selfPhone.length && !inputDisabled" @click="handleClear" :class="$style.closeIcon" class="xiaoicon">&#xe686;</div>
</div>
</template>

<style lang="scss" module src="./PhoneInput.module.scss"></style>


<script>
import { phoneRegExp } from "utils/regexp"
import Cleave from 'cleave.js'

export default {
  props: {
    phone: String,
    showTip: {
      default: true,
      type: Boolean,
    },
    label:{
      default: '',
      type: String,
    },
    // input下方border是否标红
    showErrorBorder:{
      default:false,
      type:Boolean,
    },
    placeholder: {
      type: String,
      default: '以1开头的11位纯数字'
    },
    needBlock: {
      type: Boolean,
      default: true
    },
    inputDisabled: {
      typeof: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selfPhone: '',
      // 是否1开头的11位数字
      isPhoneValid: true
    }
  },
  computed: {
    tipsVisible() {
      return !this.isPhoneValid && this.selfPhone.length === this.lengthFormat
    },
    lengthFormat() {
      return this.needBlock ? 13 : 11
    }
  },
  methods: {
    validatePhone() {
      if (!phoneRegExp.test(this.selfPhone.replace(/ /g, ""))) {
        this.isPhoneValid = false
      } else {
        this.isPhoneValid = true
      }
      this.$emit('syncPhoneValidation', this.isPhoneValid, this.tipsVisible)
    },
    handleClear() {
      this.$refs.input.value=''
      this.selfPhone=''
      this.isPhoneValid = true
      this.$emit('phoneChange', '')
      this.$refs.input.focus()
    },
    inputFocus(){
      this.$refs.input.focus()
    }
  },
  mounted() {
    if (this.phone) {
      this.selfPhone=this.phone
      this.$refs.input.value=this.phone
    }
    const blocks = this.needBlock ? [3,4,4] : [12]
    // blocks 传空 不触发 onvalueChanged  ？
    new Cleave('#cleave', {
      blocks: blocks,
      onValueChanged: e => {
        this.selfPhone = e.target.value
        this.validatePhone()
        this.$emit('phoneChange', this.selfPhone)
      }
    })
    
  }
}
</script>