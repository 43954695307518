export default [
  {
    path: '/fileDetail/index',
    name: 'file-detail-index',
    meta: {
      title: '星火网校'
    },
    component: (resolve) => {
      require(['src/views/file-detail/index.vue'], resolve)
    }
  },
  {
    path: '/fileDetail/noAuth',
    name: 'file-detail-404',
    meta: {
      title: '星火网校'
    },
    component: (resolve) => {
      require(['src/views/file-detail/no-auth-page.vue'], resolve)
    }
  }
]
