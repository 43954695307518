// Vue Global Filters

import formatTime from 'utils/time'
import period from 'utils/period'
import { subject,subjectZh_cn, OrderStatus, OTO_COURSE_TYPE } from 'utils/enum'
import dist from 'xiao-utils/district'
import { pad } from 'xiao-utils/lang'
import { ossAvatarFormat, ossRectFormat } from 'utils/ossImageFormatter'
import dateInfo from 'utils/dateInfo'
import { dateTimeFormat } from 'xiao-utils/time'
import enumGrades from 'src/enum/grades'
import {
  formatTimeRange1,
  formatTimeRange2,
  formatTimeRange3,
  formatTimeRange4,
  formatNextLiveTime,
  formatDateRange
} from 'utils/datetime'


/**
 * 直辖市，直辖市没有下属的城市，因此显示直辖市的地区的时候应该把直辖市本身也补充上
 * 如：北京市-市辖区/县
 * 110000: 北京
 * 120000: 天津
 * 310000: 上海
 * 500000: 重庆
 */
const ZHI_XIA_SHI = ['110000', '120000', '310000', '500000']

const isSameDay = (startDate, endDate) => startDate.getFullYear() === endDate.getFullYear() &&
  startDate.getMonth() === endDate.getMonth() &&
  startDate.getDate() === endDate.getDate()

function outputDate(date) {
  const isSameYear = date.getFullYear() === new Date().getFullYear()
  return isSameYear ? `${pad(date.getMonth() + 1)}月${pad(date.getDate())}日` : `${date.getFullYear()}年${pad(date.getMonth() + 1)}月${pad(date.getDate())}日`
}

function isValidNumber(value) {
  return (typeof value === 'number') && value === value && value !== Infinity && value !== -Infinity
}

function cityForZhiXiaShi (value) {
  let provinceCode = value.slice(0, 2) + '0000'
  if (ZHI_XIA_SHI.includes(provinceCode)) {
    return dist[provinceCode]
  } else {
    return dist[value]
  }
}

function cityNameWithZhiXiaShi (value) {
  let provinceCode = value.slice(0, 2) + '0000'
  if (ZHI_XIA_SHI.includes(provinceCode)) {
    return dist[provinceCode] + '-' + dist[value]
  } else {
    return dist[value]
  }
}
/**
 * 转换成折扣显示，截取到小数点后2位
 * 考虑以下特殊情况：
 * toDiscount(0.9999) => 9.99
 * toDiscount(0.0001) => 0
 * toDiscount(0.9299) => 9.29
 * toDiscount(0.29) => 2.9
 * @param {number} value
 * @return {number}
 */
export function toDiscount(value) {
  if (!isValidNumber(value)) {
    return value
  }
  return Math.floor(value * 1000) / 100
}

export default function install(Vue) {
  Vue.filter('toDiscount', toDiscount)
  Vue.filter('formatTimeRange1', formatTimeRange1)
  Vue.filter('formatTimeRange2', formatTimeRange2)
  Vue.filter('formatTimeRange3', formatTimeRange3)
  Vue.filter('formatTimeRange4', formatTimeRange4)
  Vue.filter('formatDateRange', formatDateRange)
  Vue.filter('formatNextLiveTime', formatNextLiveTime)

  Vue.filter('apply', function (value) {
    if (!value) return ''
    return value.split('\n').filter(item => item !== '')[0]
  })
  Vue.filter('dateArrange', function (start, end) {
    const start_date = new Date(start * 1000)
    const end_date = new Date(end * 1000)
    return `${start_date.getFullYear()}年${start_date.getMonth() + 1}月${start_date.getDate()}日-${end_date.getFullYear()}年${end_date.getMonth() + 1}月${end_date.getDate()}日`
  })
  Vue.filter('currentDate', function (stamp) {
    const date = new Date(stamp * 1000)
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  })
  Vue.filter('time', function (value, format = 'YYYY.MM.DD') {
    if (!value) return ''
    return formatTime(value, format)
  })

  Vue.filter('dateTime', function (ts, format = 'yyyy/MM/dd hh:mm') {
    if (typeof ts !== 'number' || ts !== ts || ts < 0) {
      return '--'
    }
    return dateTimeFormat(ts, format)
  })

  // 时间段
  Vue.filter('specifyTime', function (ts, format = 'hh:mm') {
    if (typeof ts !== 'number' || ts !== ts || ts <= 0) {
      return '--'
    }
    return dateTimeFormat(ts, format)
  })

  Vue.filter('price', function (value) {
    if (!value && value !== 0) return ''
    if (value === 0) return '免费'
    if (localStorage.getItem('isFromIosReview')) return '晓币' + value
    return '¥' + value
  })

  Vue.filter('period', function (value) {
    if (!value || !value[0] || !value[1]) return ''
    return period(value[0], value[1])
  })

  Vue.filter('countdown', function (value) {
    let temp = parseInt(value)
    if (!temp) return '00:00'
    return `${pad(parseInt(temp/60))}:${pad(temp % 60)}`
  })

  Vue.filter('leftCountdown', function (value) {
    let temp = parseInt(value)
    if (!temp) return '00:00:00'
    const SECS_PER_HOUR = 60 * 60
    const SECS_PER_DAY = 24 * SECS_PER_HOUR
    const SECS_PER_MINUTE = 60
    let countdown = temp
    let leftDaySecs = countdown % SECS_PER_DAY
    let leftHourSecs = leftDaySecs % SECS_PER_HOUR
    let days = Math.floor(countdown / SECS_PER_DAY)
    let hours = Math.floor(leftDaySecs / SECS_PER_HOUR)
    let minutes = Math.floor(leftHourSecs / SECS_PER_MINUTE)
    let seconds = leftDaySecs % SECS_PER_MINUTE
    return `${days ? days + '天' : ''}${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
  })

  Vue.filter('leftCountdown_ms', function (value) {
    let temp = parseInt(value/10)
    if(!temp) return '00:00:00.0'
    const SECS_PER_HOUR = 60 * 60
    const SECS_PER_DAY = 24 * SECS_PER_HOUR
    const SECS_PER_MINUTE = 60
    let countdown = temp
    let leftDaySecs = countdown % SECS_PER_DAY
    let leftHourSecs = leftDaySecs % SECS_PER_HOUR
    let hours = Math.floor(countdown / SECS_PER_HOUR)
    let minutes = Math.floor(leftHourSecs / SECS_PER_MINUTE)
    let seconds = leftDaySecs % SECS_PER_MINUTE
    let millisecond = parseInt(value) % 10
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${millisecond}`
  })
  Vue.filter('leftCountdown_hms', function (value) {
    let temp = parseInt(value)
    if(!temp) return '00:00:00'
    const SECS_PER_HOUR = 60 * 60
    const SECS_PER_DAY = 24 * SECS_PER_HOUR
    const SECS_PER_MINUTE = 60
    let countdown = temp
    let leftDaySecs = countdown % SECS_PER_DAY
    let leftHourSecs = leftDaySecs % SECS_PER_HOUR
    let hours = Math.floor(countdown / SECS_PER_HOUR)
    let minutes = Math.floor(leftHourSecs / SECS_PER_MINUTE)
    let seconds = leftDaySecs % SECS_PER_MINUTE
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
  })

  Vue.filter('percent', function (value) {
    if (!value) return '0%'
    return `${parseInt(value * 1000 / 10)}%`
  })

  Vue.filter('coupon_apply', function (value) {
    if (!value || !value.length) return ''
    if (value.length === 9) return '全部课程'
    return value.map(item => subjectZh_cn[item instanceof Object? item.code : item]).join('、') + '课'
  })

  Vue.filter('orderStatus', function (value) {
    return OrderStatus[value] || ''
  })

  Vue.filter('grade', function (value) {
    return value ? enumGrades.nameCN[value.toString()] : ''
  })
  
  Vue.filter('newGrades', function (value) {
    return value ? enumGrades.nameOrderCN[value.toString()] : ''
  })

  Vue.filter('subject', function (value) {
    return subject[value] || ''
  })

  Vue.filter('subjectCN', function (value) {
    return subjectZh_cn[value] || ''
  })

  Vue.filter('arrange', function (stamp) {
    let date = new Date(stamp * 1000)
    let currentDate = new Date()
    if (date.getFullYear() === currentDate.getFullYear()) {
      return `${date.getMonth() + 1}月${date.getDate()}日`
    } else {
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
  })

  Vue.filter('range', function (_start, _end) {
    let start = new Date(_start * 1000)
    let end = new Date(_end * 1000)
    let current = new Date()
    if (start.getFullYear() === current.getFullYear() && end.getFullYear() === current.getFullYear()) {
      return `${start.getMonth() + 1}月${start.getDate()}日－${end.getMonth() + 1}月${end.getDate()}日`
    } else {
      return `${start.getFullYear()}年${start.getMonth() + 1}月${start.getDate()}日－${end.getFullYear()}年${end.getMonth() + 1}月${end.getDate()}日`
    }
  })

  Vue.filter('float', function (value) {
    if (value % 1 === 0) return value
    return value.toFixed(2)
  })

  Vue.filter('money', function (value) {
    if (value % 1 === 0) {
      if (value === 0) return '免费'
      else return value
    }
    return value.toFixed(2)
  })

  Vue.filter('dist', function (value) {
    if (!value) return '未填写'
    return dist[value]
  })

  // 兼容直辖市城市的地区
  Vue.filter('cityForZhiXiaShi', cityForZhiXiaShi)

  // 区分直辖市的城市名。如“天津市-市辖区，天津市-县”
  Vue.filter('cityNameWithZhiXiaShi', cityNameWithZhiXiaShi)

  Vue.filter('quarter', function (value) {
    const map = {
      1: '春',
      2: '夏',
      3: '秋',
      4: '冬'
    }
    return map[value]
  })

  // 这个区别于 arrange ，和 PC 端的 arrange 保持一致
  Vue.filter('arrange2', function (start, end) {
    const startDate = new Date(start * 1000),
      endDate = new Date(end * 1000),
      now = new Date()
    if (isSameDay(startDate, endDate)) {
      return outputDate(startDate)
    } else {
      if (startDate.getTime() > now.getTime() || endDate.getTime() < now.getTime()) {
        return outputDate(startDate) + '-' + outputDate(endDate)
      } else {
        return '报班即学-' + outputDate(endDate)
      }
    }
  })

  // 头像的 OSS 图片处理（只适合转成正方形的头像）
  // 对应的 OSS 后缀为 ?x-oss-process=image/format,jpg/interlace,1/resize,m_mfit,w_$size,h_$size/crop,x_0,y_0,w_$size,h_$size,g_se
  // 其中 $size 就是头像的长和宽
  Vue.filter('ossAvatar', ossAvatarFormat)
  Vue.filter('ossImage', ossRectFormat)
  Vue.filter('time_range', dateInfo)
  Vue.filter('oto_course_type', type => {
    return OTO_COURSE_TYPE[type] || '--'
  })
  
  Vue.filter('phone_format', function(value) {
    const isValid = /^1[0-9]{10}$/.test(value)
    if (isValid) {
      return value.slice(0,3) + ' ' + value.slice(3, 7) + ' ' +value.slice(7, 11)
    } else {
      return value
    }
  })

  //营销分城展示
  Vue.filter('market_city_name', val => {
    if (!val || !val.length) {
      return '全部城市'
    }
    return val.map(item => item.city_name || item.province_name).join('、') 
    // return val.map(item => cityForZhiXiaShi(item.city)).join('、')    
  })

  Vue.filter('hidePhoneNumber', (val, defaultValue = '--') => {
    return val ? val.toString().replace(/(\d{3})(\d{4})(\d+)/, '$1****$3') : defaultValue
  })
  
  Vue.filter('arrangeOnce', (start, end, isFormat = false) => {
    if (isFormat) { //购物车格式
      const dS = new Date(start * 1000), dE = new Date(end * 1000), now = new Date()
      let leftPart = `${dS.getMonth()+1}/${dS.getDate()} ${pad(dS.getHours())}:${pad(dS.getMinutes())}`
        , rightPart = `${pad(dE.getHours())}:${pad(dE.getMinutes())}`
      if (now.getFullYear() !== dS.getFullYear()) {
        leftPart = `${dS.getFullYear()}/` + leftPart
      }
      if (dE.getDate() !== dS.getDate() || dE.getMonth() !== dS.getMonth() || dE.getFullYear() !== dS.getFullYear()) {
        rightPart = `${dE.getMonth() + 1}/${dE.getDate()} ` + rightPart
      }
      if (dE.getFullYear() !== dS.getFullYear()) {
        rightPart = `${dE.getFullYear()}/` + rightPart
      }
      return `${leftPart}-${rightPart}`
    } else {
      const dS = new Date(start * 1000), dE = new Date(end * 1000), now = new Date()
      let leftPart = `${dS.getMonth()+1}月${dS.getDate()}日 ${pad(dS.getHours())}:${pad(dS.getMinutes())}`
        , rightPart = `${pad(dE.getHours())}:${pad(dE.getMinutes())}`
      if (now.getFullYear() !== dS.getFullYear()) {
        leftPart = `${dS.getFullYear()}年` + leftPart
      }
      if (dE.getDate() !== dS.getDate() || dE.getMonth() !== dS.getMonth() || dE.getFullYear() !== dS.getFullYear()) {
        rightPart = `${dE.getMonth() + 1}月${dE.getDate()}日 ` + rightPart
      }
      if (dE.getFullYear() !== dS.getFullYear()) {
        rightPart = `${dE.getFullYear()}年` + rightPart
      }
      return `${leftPart}-${rightPart}`
    }
    
  })

  Vue.filter('multipleGroupbuyingArrange',  (start, end) => {
    const dS = new Date(start * 1000), dE = new Date(end * 1000)
    const monthS = dS.getMonth() +1, monthE = dE.getMonth()+1, dayS = dS.getDate(), dayE = dE.getDate()
    if (monthS === monthE && dayS === dayE) {
      return `${monthS}月${dayS}日`
    } else {
      return `${monthS}月${dayS}日-${monthE}月${dayE}日`
    }
  })

  Vue.filter('galleryUploadTime',  val => {
    if (!val) {
      return ''
    }
    const dS = new Date(val * 1000), now = new Date(),
      yesterday = new Date(Date.now() - 1*24*3600*1000)
    const dSy = dS.getFullYear(), dSm = dS.getMonth() + 1, dSd = dS.getDate()
    if (isSameDay(dS, now)) {
      return `今天${dateTimeFormat(val * 1000, 'hh:mm')}`
    }
    if (isSameDay(dS, yesterday)) {
      return `昨天${dateTimeFormat(val * 1000, 'hh:mm')}`
    }
    if (dSy === now.getFullYear()) {
      return dateTimeFormat(dS, 'MM/dd hh:mm')
    }
    return dateTimeFormat(dS, 'yyyy/MM/dd hh:mm')
  })

  Vue.filter('balanceFormat', balance => {
    if(typeof balance != 'number') return balance
    const fixedNum = balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return fixedNum
  })

  Vue.filter('classType', value => {
    if(!value) return '博学班/清北班'
    const map = {
      3: "鸿志班",
      4: "敏思班/能力班/同步班/实验班",
      5: "博学班/清北班/素养班"
    }
    return map[value]
  })

  Vue.filter('subject_tag_filter', val => {
    if(!val) return ''
    return val.length > 1 ? '多科' : val[0].zh_cn
  })

  Vue.filter('feedbackTime',  val => {
    if (!val) {
      return ''
    }
    const dS = new Date(val * 1000)
    return dateTimeFormat(dS, 'yyyy/MM/dd hh:mm:ss')
  })

  Vue.filter("scoreLevel", function scoreLevel(value) {
    if (typeof value !== "number") {
      return value
    }
    return {
      1: "一般",
      2: "良好",
      3: "优秀",
      4: "完美",
    }[value] || "一般"
  })
}

