export default function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?")
  if (urlparts.length >= 2) {
    const paramSet = new Set()
    if (Array.isArray(parameter)) {
      parameter.forEach((param) => paramSet.add(param))
    } else if (typeof parameter === "string") {
      paramSet.add(parameter)
    } else {
      return url
    }
    const str = urlparts[1]
    const arr = str.split("&")
    const queryArr = []
    for (let i = 0; i < arr.length; i++) {
      const [key, value] = arr[i].split("=")
      if (paramSet.has(key)) continue
      queryArr.push(arr[i])
    }
    url = urlparts[0] + (queryArr.length > 0 ? "?" + queryArr.join("&") : "?")
    return url
  } else {
    return url
  }
}
