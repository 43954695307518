export default function(to) {
  const paramReg = /:(\w+)/g
  let link = to.meta.pc
  const matchLink = link.match(paramReg)
  if(matchLink) {
    const paramName = matchLink.map(item => item.replace(':', '')) //提取出参数
    matchLink.forEach((item, idx) => {
      link = link.replace(':'+paramName[idx], to.params[paramName[idx]])
    })
  }
  return link
}
