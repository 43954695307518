export default <template>
  <XiaoModal :visible.sync="visible" :showCloseIcon="false">
    <div :class="$style.modal">
      <div :class="$style.student">
        <div :class="$style.tips">您已成功绑定账号</div>
        <div :class="$style.info">
          <span :class="$style.infoName">{{ student.name }}</span>
          <span :class="$style.infoPhone">{{ student.phone }}</span>
        </div>
      </div>
      <div :class="$style.select">
        <div :class="$style.title">请选择您与该学员的关系</div>
        <div :class="$style.radio">
          <label v-for="item in relationShip" :key="item.value" :class="$style.label">
            <input :class="$style.radioBtn" type="radio" name="relationship" :value="item.value" style="display: none;" @change="handleChange"/>
            <i class="xiaoicon" :class="$style.radioIcon"></i>
            <span :class="$style.labelText">{{item.label}}</span>
          </label>
        </div>
      </div>
      <div :class="$style.cancel" @click="cancel">取消</div>
    </div>
  </XiaoModal>
</template>
<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    student: {
      default() {
        return {
          name: "name",
          phone: "phone",
        }
      },
    },
  },
  data() {
    return {
      relationShip: [
        { label: '学员本人', value: 1 },
        { label: '妈妈', value: 3 },
        { label: '爸爸', value: 2 },
      ]
    }
  },
  methods: {
    handleChange(ev) {
      this.$emit('select', ev.target.value)
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  components: {
    XiaoModal: () => import("components/xiao-modal/XiaoModal.vue"),
  },
}
</script>

<style lang="scss" module>
.modal {
  width: 7.866667rem;
  padding: 0.266667rem 0.533333rem .533333rem 0.533333rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: .26666667rem;
  .student {
    padding-bottom: .266667rem;
    border-bottom: 1px solid #ebebeb;

    .tips {
      font-size: .373333rem;
      color: #5a5a5a;
    }
    .info {
      text-align: center;
    }
    .infoName {
      font-size: .32rem;
      color: #464646;
      font-size: .32rem;
    }
    .infoPhone {
      font-size: .373333rem;
      font-family: 'xiaonum';
      color: #00C55D;
      margin-left: .266667rem;
      font-size: .373333rem;
    }
  }

  .select {
    padding-bottom: .533333rem;
    text-align: left;
    .title {
      padding: .533333rem 0 .266667rem 0;
      font-size: .426667rem;
      color: #464646;
      text-align: left;
      font-weight: bold;
    }
    .radio {
      font-size: .426667rem;
      color: #464646;
      .label {
        display: block;
        height: .96rem;
        display: flex;
        align-items: center;
      }
      .labelText {
        margin-left: .21333333rem;
      }
      .radioBtn + .radioIcon::after {
        content: "\e762";
        color: #ccc;
        font-size: .53333333rem;
      }
      .radioBtn:checked + .radioIcon::after {
        content: "\e760";
        color: #00C55D;
      }
    }
  }
  .cancel {
    width: 6.533333rem;
    height: 1.173333rem;
    line-height: 1.173333rem;
    background: #FFFFFF;
    border-radius: .586667rem;
    border: 1px solid #B3B3B3;
    font-size: .426667rem;
    color: #8A8A8A;
    margin: 0 auto;
  }
}
</style>