import axios from 'axios'
import formatTime from 'utils/time'
import store from '../vuex/store.js'
import { isApp } from './version'

const STORES = {
  'demo': 'demo-magento-access-log2',
  'test': 'test-front-log',
  'prod': 'prod-front-log'
}

const PROJECT = {
  test: 'dev-xh-buried-point-data',
  prod: 'xh-buried-point-data'
}

const defaultParams = {
  content: {}
}
export default (params = {}) => {
  const host = 'cn-shenzhen.log.aliyuncs.com'
  const project = PROJECT[__TARGET__]
  const logstore = STORES[__TARGET__]
  if (!logstore || !project) return
  const user = store && store.state.auth ? store.state.auth.user : null
  const userId = user ? user.user_id : ''
  const { content, ...restParams } = params
  const contentParams = {
    "user_id": userId,
    "device_id": localStorage.getItem('cookie_id'),
    ...content
  }
  let finalParams = {
    "platform": isApp ? 'app' : "h5",
    "user-agent": navigator.userAgent,
    "timestamp": formatTime(new Date(), 'YYYY-MM-DD hh:mm:ss'),
  }
  if (restParams) {
    finalParams = {
      ...finalParams,
      ...restParams
    }
  }
  finalParams.content = contentParams
  
  const url = `//${project}.${host}/logstores/${logstore}/track?APIVersion=0.6.0`
  return axios.get(url, { 
    params: finalParams
  })
}