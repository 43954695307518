var isArray = Array.isArray;

var encode = encodeURIComponent, decode = decodeURIComponent;

export function parse(queryString) {
  if (typeof queryString !== 'string') {
    return {};
  }

  queryString = queryString.trim().replace(/^(\?|#)/, '');

  if (queryString === '') {
    return {};
  }

  var queryParts = queryString.split('&');

  var query = {};

  for (var i = 0; i < queryParts.length; ++i) {
    var parts = queryParts[i].replace(/\+/g, '%20').split('='); // 特殊字符`+`转换为空格
    var name = decode(parts[0]), value = parts[1] === undefined ? null : decode(parts[1]);

    if (!query.hasOwnProperty(name)) {
      query[name] = value;
    } else if (isArray(query[name])) {
      query[name].push(value);
    } else {
      query[name] = [query[name], value];
    }
  }
  return query;
}

export function stringify(obj, traditional) {
  if (!obj) {
    return '';
  }
  var appendString = traditional ? '' : '[]';
  var keysArray = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      keysArray.push(p);
    }
  }
  var names = keysArray.sort();

  var parts = [];
  for (var i = 0; i < names.length; ++i) {
    var name = names[i];
    var value = obj[name];

    if (isArray(value)) {
      value.sort();
      var _parts = [];
      for (var j = 0; j < value.length; ++j) {
        _parts.push(encode(name).replace(/%20/g, '+') + appendString + '=' + encode(value[j]).replace(/%20/g, '+'));
      }
      parts.push(_parts.join('&'));
      continue;
    }
    parts.push(encode(name).replace(/%20/g, '+') + '=' + encode(value).replace(/%20/g, '+'));
  }
  return parts.join('&');
}
