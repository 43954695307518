import http from "utils/http"

/**
 * [我的购买课程](http://dev-yapi.xhwx100.com/project/11/interface/api/3843)
 * @param {object} params
 * @param {number} params.sort_rule
 */
export function api_getCurricula(params) {
  const url = '/koala/api/v1.5/personal_center/paid_curricula'
  return http.post(url, params)
}

export function api_getAssistaneCategoryList(params) {
  const url = '/koala/api/v1.7/my/help/center/category/list'
  return http.get(url, {params})
}

export function api_getAssistaneCategoryQuestionList(params) {
  const url = '/koala/api/v1.7/my/help/center/question/list'
  return http.get(url, {params})
}

export function api_getAssistaneCategoryQuestionDetail(params) {
  const url = '/koala/api/v1.7/my/help/center/details'
  return http.get(url, {params}).then(res => {
    const { topic, url, id, image, useful } = res.data
    if(url){
      return http.get(url).then(res => {
        return Promise.resolve({ content: res.data, topic, id, image, useful })
      }).catch(() => {
        return Promise.reject()
      })
    }else{
      return Promise.reject()
    }
  })
}

export function api_getAssistaneCategoryQuestionDetailAssess(params) {
  const url = '/koala/api/v1.7/my/help/center/assess'
  return http.put(url, params)
}

/**
 * [获取收货地址列表](http://yapi.xhwx100.com/project/19/interface/api/11106)
 */
export function api_getConsigneeList() {
  const url = '/koala/api/v1.7/consignee/list'
  return http.get(url)
}


/**
 * [设置默认收货地址](http://yapi.xhwx100.com/project/19/interface/api/11097)
 */
export function api_setDefaultConsignee(params) {
  const url = '/koala/api/v1.7/consignee/setdefault'
  return http.put(url, params)
}

/**
 * [更新收货地址](http://yapi.xhwx100.com/project/19/interface/api/11070)
 */
export function api_updateConsignee(params) {
  const url = '/koala/api/v1.7/consignee/update'
  return http.put(url, params)
}


/**
 * [删除收货地址](http://yapi.xhwx100.com/project/19/interface/api/11061)
 */
export function api_removeConsignee(params) {
  const url = '/koala/api/v1.7/consignee/delete'
  return http.delete(url,{ data: params })
}

/**
 * [添加收货地址](http://yapi.xhwx100.com/project/19/interface/api/11043)
 */
export function api_addConsignee(params) {
  const url = '/koala/api/v1.7/consignee/add'
  return http.put(url, params)
}


/**
 * [添加收货地址](http://yapi.xhwx100.com/project/19/interface/api/11043)
 */
export function api_getConsigneeInfo(params) {
  const url = '/koala/api/v1.7/consignee/info'
  return http.post(url, params)
}

/*
 * [我的购买课程](http://yapi.xhwx100.com/project/85/interface/api/11052)
 * @param {object} params
 * @param {number} params.sort_rule
 */
export function api_getStudentCurricula(params) {
  const url = '/manta/api/v1.0/student/curriculum/list'
  return http.get(url, { params })
}

export function api_getStudentCurriculaNew(params) {
  const url = '/manta/api/v1.0/student/curriculum/v4/list'
  return http.get(url, { params })
}

/**
 * [确认地址展示](http://yapi.xhwx100.com/project/81/interface/api/27561)
 */
export function api_getAddressConfirmDetail(params) {
  const url = '/koala/api/v2.1/expansion/order/confirm-address/show'
  return http.post(url, params)
}

/**
 * [H5订单评价](http://yapi.xhwx100.com/project/81/interface/api/27556)
 */
export function api_sendOrderEvalute(params) {
  const url = '/koala/api/v2.1/expansion/order/confirm-address-and-evaluate'
  return http.post(url, params)
}