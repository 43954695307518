import Vue from 'vue'
import Vuex from 'vuex'

import detail from 'vux/modules/detail'
import auth from 'vux/modules/auth'
import report from 'vux/modules/report'
import order from 'vux/modules/order'
import address from 'vux/modules/address'
import course from 'vux/modules/course'
import home from 'vux/modules/home'
import trial from 'vux/modules/trial'
import wx from './modules/wx'
import publicLiveroom from './modules/public-liveroom'
import experienceCoursesLiveroom from './modules/experience-courses-liveroom'
import reportV2 from './modules/reportV2'
import experienceLesson from './modules/experience-lesson'
import shoppingCart from './modules/shopping-cart'
import logistics from './modules/logistics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    detail,
    auth,
    report,
    order,
    address,
    course,
    trial,
    wx,
    publicLiveroom,
    reportV2,
    experienceLesson,
    shoppingCart,
    logistics,
    experienceCoursesLiveroom
  }
})
