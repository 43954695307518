const filters = {
  install(Vue) {
    Vue.filter('semesterFormat', function(semesterId, semesterMap) {
      if (semesterMap.length === 0) {
        return
      }
      if (Array.isArray(semesterId)) {
        if (semesterId.length === 0) {
          return '--'
        } else {
          return semesterId
            .map((id) => {
              let matchItem = semesterMap.find((source_item) => {
                return source_item.id === id
              })
              return matchItem.zh_cn || ''
            })
            .join('；')
        }
      } else {
        let matchItem = semesterMap.find((source_item) => {
          return source_item.id === semesterId
        })
        if (matchItem) {
          return matchItem.zh_cn
        } else {
          return '--'
        }
      }
    })
  }
}

export default filters
