var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.modal_2vmh3{position:fixed;top:0;right:0;bottom:0;left:0;overflow-y:auto;z-index:1000\n}\n.modal_2vmh3.center_255v_{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center\n}\n.modal_2vmh3.center_255v_ .box_2XtZM{margin-top:-0.52rem\n}\n.dimmer_jFftI{position:fixed;top:0;right:0;bottom:0;left:0;background:rgba(0,0,0,0);transition:all .15s ease-out;z-index:1\n}\n.dimmerChange_3neDJ{background:rgba(0,0,0,.7)\n}\n.box_2XtZM{position:relative;margin:0 auto;z-index:2\n}\n.close_CvGqX{position:absolute;bottom:-1.04rem;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:.773333rem;height:.773333rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background:url(" + escape(require("./images/notes_close_nor@3x.png")) + ") 0 0 no-repeat;background-size:contain\n}\n.close_CvGqX:active{background-image:url(" + escape(require("./images/notes_close_pre@3x.png")) + ")\n}\n.redButton_AYUWv{box-shadow:0 0 #000 !important;background:linear-gradient(241deg, #fa5c48 0%, #f70632 100%) !important\n}", ""]);

// exports
exports.locals = {
	"modal": "modal_2vmh3",
	"center": "center_255v_",
	"box": "box_2XtZM",
	"dimmer": "dimmer_jFftI",
	"dimmerChange": "dimmerChange_3neDJ",
	"close": "close_CvGqX",
	"redButton": "redButton_AYUWv"
};