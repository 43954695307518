const state = {
  isKicked: false,
  isLogin: false,
  isBought: false,
  isNIMConnected: false,
  isChatroomConnected: false,
  isInWhiteList: false,
  isMute: false,
  isChatroomMute: false,
  liveroomId: '',
  user: {
    id: '',
    name: '',
    avatar: ''
  },
  yunxin: {
    chatroomId: '',
    userId: '',
    userToken: ''
  },
  curriculum: {
    curriculumId: '',
    curriculumName: '',
    curriculumGenre: -1,
    lessonTitle: '',
    lessonNum: 0,
    lessonStartTime: 0,
    classId: '',
    className: '',
    tutorId: '',
    tutorName: '',
    tutorAvatar: '',
    teacherId: '',
    teacherName: ''
  },

  // 直播间重构新加的
  videos: [],
  is_publishing: false,
  is_possessing: false,
  is_free_public: false,
  duration: 0,
  class_id: '',
  is_lesson_end: false,

  isAccessful: true,
  isPublishing: false,
}

const mutations = {
  setLogin(state, isLogin) {
    state.isLogin = !!isLogin
  },
  setBought(state, isBought) {
    state.isBought = !!isBought
  },
  setAccessful(state, isAccessful) {
    state.isAccessful = !!isAccessful
  },
  setPublishing(state, isPublishing) {
    state.isPublishing = isPublishing
  },
  setVideoAndStatus(state, { videos, is_publishing, is_possessing, is_free_public, duration, class_id }) {
    state.videos = videos
    state.is_publishing = is_publishing
    state.is_possessing = is_possessing
    state.is_free_public = is_free_public
    state.duration = duration
    state.class_id = class_id
  },
  setIsLessonEnd(state, { is_lesson_end }) {
    state.is_lesson_end = is_lesson_end
  },
  SET_TOKEN_REQUEST_ID(state, { id }) {
    state.tokenRequestId = id
  },
  SET_LIVEROOM_INFO(state, { liveroomInfo }) {
    state.isLogin = liveroomInfo.is_login
    state.isBought = liveroomInfo.is_bought
    state.isInWhiteList = !!liveroomInfo.in_whitelist
    state.liveroomId = liveroomInfo.liveroom_id
    state.user = {
      id: liveroomInfo.user_id,
      name: liveroomInfo.user_name,
      avatar: liveroomInfo.user_portrait
    }
    state.yunxin = {
      userId: liveroomInfo.yunxin_user_id,
      userToken: liveroomInfo.yunxin_token,
      chatroomId: liveroomInfo.chatroom_id
    }
    state.curriculum = {
      curriculumId: liveroomInfo.curriculum_id,
      curriculumName: liveroomInfo.curriculum_name,
      curriculumGenre: liveroomInfo.curriculum_genre,
      lessonTitle: liveroomInfo.course_name,
      lessonNum: liveroomInfo.lesson_num,
      lessonStartTime: liveroomInfo.lesson_start_time * 1000,
      classId: liveroomInfo.class_id,
      className: liveroomInfo.class_name,
      tutorId: liveroomInfo.class_teacher_id,
      tutorName: liveroomInfo.class_teacher_name,
      tutorAvatar: liveroomInfo.class_teacher_portrait,
      teacherId: liveroomInfo.teacher_id,
      teacherName: liveroomInfo.teacher_name
    }
  },
  KICK(state) {
    state.isKicked = true
  },
  SET_NIM_CONNECTED(state, { connected }) {
    state.isNIMConnected = connected
  },
  SET_CHATROOM_CONNECTED(state, { connected }) {
    state.isChatroomConnected = connected
  },
  SET_NIM_MUTE(state, { mute }) {
    state.isMute = mute
  },
  SET_CHATROOM_MUTE(state, { mute }) {
    state.isChatroomMute = mute
  },
  RESET_STATE(state) {
    state.isKicked = false
    state.isLogin = false
    state.isBought = false
    state.isNIMConnected = false
    state.isChatroomConnected = false
    state.isInWhiteList = false
    state.isMute = false
    state.isChatroomMute = false
    state.isPlaying = false
  },
  setSimpleCurriculumInfo(state, info) {
    state.curriculum.curriculumId = info.curriculum_id
    state.curriculum.curriculumGenre = info.curriculum_genre
    state.curriculum.lessonTitle = info.title
    state.curriculum.lessonStartTime = info.lesson_start_time * 1000
  },
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
