export default <template>
  <transition>
    <div v-show="visible" :class="[$style.modal]" @touchmove="onTouchMove">
      <div
        :class="[$style.dimmer, $style.dimmerChange]"
        @click="onClickDimmer"
      ></div>
      <div class="boxStyle box" ref="slotBox">
        <div class="desc">
          {{ text }}
        </div>
        <button
          type="button"
          class="btn model_btn"
          :class="[isRedButton ? $style.redButton : '']"
          @click="close"
        >
          {{ buttonText || '确定' }}
        </button>
        <!-- <div v-if="closeBtnVisible" :class="$style.close" @click="close"></div> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      closeBtnVisible: true,
      visible: false,
      isRedButton:false,
      text: "",
      buttonText: ""
    }
  },
  methods: {
    updateText(options) {
      const { buttonText, text ,isRedButton} = options
      this.isRedButton = !!isRedButton
      this.buttonText = buttonText
      this.text = text
    },
    close() {
      this.visible = false
      this.closeCallback && this.closeCallback()
    },
    onClickDimmer() {
      this.close()
    },
    onTouchMove(e) {
      // if (this.preventTouchMove) {
      //   e.preventDefault()
      // }
    }
  }
}
</script>

<style lang="scss" module src="./xiao-message.module.scss"></style>

<style lang="scss" scoped>
.boxStyle {
  top: 50%;
  transform: translateY(-50%);
  width: 7.733333rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.266667rem;
  padding: 0.8rem;
}
.desc {
  font-size: 0.426667rem;
  font-weight: 400;
  color: rgba(90, 90, 90, 1);
  line-height: 0.64rem;
  margin-bottom: 0.8rem;
}
.model_btn {
  background: rgba(0, 197, 93, 1);
  box-shadow: 0 0.213333rem 0.426667rem 0 rgba(0, 197, 93, 0.4);
  border-radius: 0.64rem;
  width: 100%;
  display: block;
  font-size: 0.426667rem;
  font-weight: 500;
  height: 1.28rem;
  color: rgba(255, 255, 255, 1);
  &:active {
    background: rgba(0, 171, 81, 1);
  }
}
.box {
  position: relative;
  margin: 0 auto;
  z-index: 2;
}
</style>
