import { render, staticRenderFns } from "./NoCaptcha.vue?vue&type=template&id=23d0be22&"
import script from "./NoCaptcha.vue?vue&type=script&lang=js&"
export * from "./NoCaptcha.vue?vue&type=script&lang=js&"
import style0 from "./NoCaptcha.module.scss?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./NoCaptcha.scss?vue&type=style&index=1&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

component.options.__file = "NoCaptcha.vue"
export default component.exports