import globalCookieHandler from 'src/store/cookie'
import { GIO_EVENT } from 'src/enum/gio'

const TRACK_ROUTES = [
  'experience-lesson/experience-courses', // 体验课
  'exprience-lesson/order-system', // 抢报系统首页
  'pintuan' // 拼团详情
]
const TRACK_ROUTES_ORIGINS = {
  'experience-lesson/experience-courses': 'experience-lesson',
  'exprience-lesson/order-system': 'ordersystem/index',
  'pintuan': 'ordersystem/pintuan-detail',
}

export default {
  methods: {
    async afterLogin(res, login_method="phone") {
      const { student, is_new_user } = res.data
      let afterLoginRoute = sessionStorage.getItem('afterLoginRoute') ? sessionStorage.getItem('afterLoginRoute') : '/my'
      if (res.data.name && res.data.city && (res.data.grade && res.data.grade.code !== 0)) {
        // 已经注册了
        // 有名字、 有城市、 有年级
        sessionStorage.removeItem('afterLoginRoute')
      }
      
      sessionStorage.removeItem('IS_FROM_PRO')
      globalCookieHandler.token = res.data.token
      res.data.student && (globalCookieHandler.user_id = res.data.student.id)
      this.$store.commit('auth/SET_AUTH_TOKEN', res.data.token)
      
      if (afterLoginRoute) {
        // 登录注册埋点
        for (let route of TRACK_ROUTES) {
          try {
            if (afterLoginRoute.indexOf(route) < 0) continue
            const event = !student || is_new_user ? 'register' : 'login'
            const id = student ? student.id : globalCookieHandler.user_id
            await this.sendLoginData(event, id, TRACK_ROUTES_ORIGINS[route], login_method)
            break
          } catch (error) {
            console.error(error)
          }
        }
      }

      window.location.href = afterLoginRoute
    },
    // 登录、注册成功进行埋点
    sendLoginData (event, user_id, entry, login_method) {
      if (window.gio) {
        const login_registration_method = login_method === 'wechat' ? '微信' : '手机'
        const login_registration_judgment = event === 'register' ? '注册成功' : '登录成功'
        window.gio('track', GIO_EVENT.LOGIN_REGISTRATION_EVENT, {
          login_registration_method,
          login_registration_judgment
        })
      }
      const activity_share_params = JSON.parse(sessionStorage.getItem('activity_share_params'))
      const share_id = activity_share_params ? activity_share_params.share_id : ''
      const activity_id = activity_share_params ? activity_share_params.activity_id : ''
      if (!activity_id) return
      const param = {
        "topic":"login",
        "event": event,
        "content":{
          "user_id": user_id,
          "var": {
            "share_id": share_id,
            "act_id": activity_id,
            "entry": entry,
            "login_method": login_method
          }
        }
      }
      return this.$logger(param)
    },
    // 将获取的学生信息格式化为系统适用的数据结构
    studentFormat(studentInfo) {
      return {
        user_id: studentInfo.id,
        phone: studentInfo.phone,
        name: studentInfo.name,
        school: studentInfo.school,
        source: studentInfo.reg_source,
        reg_time_in_secs: studentInfo.reg_time_sec,
        is_bind_wx_mp: studentInfo.is_bind_wx_mp,
        is_follow_wechat_account: studentInfo.is_follow_wechat_account,
        portrait_url: studentInfo.profile_photo,
        portrait: studentInfo.profile_photo,
        gender: studentInfo.gender.id,
        grade: Number(studentInfo.grade.id),
        province: studentInfo.province_code,
        province_name: studentInfo.province_name,
        city: studentInfo.city_code || studentInfo.province_code,
        city_name: studentInfo.city_name || studentInfo.province_name,
        // 地址只会到城市，为了保证兼容，ding
        district: studentInfo.district_code,
        district_name: studentInfo.district_name,
        remark_name: studentInfo.remark_name,
      }
    },
  }
}