exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.box_DzZcg{overflow:hidden;position:absolute;top:0;width:100%;font-size:.426667rem;color:#5a5a5a;background:#fff\n}\n.header_Qdzzw{margin-bottom:.266667rem;padding:.533333rem 0;background:#fafafa\n}\n.currentStatus_3WpWf{padding:0 .96rem\n}\n.currentStatus_3WpWf .highlight_1iPGu,.currentStatus_3WpWf .normal_3gYkL{position:relative;line-height:1;margin-bottom:.533333rem\n}\n.currentStatus_3WpWf .highlight_1iPGu:before,.currentStatus_3WpWf .normal_3gYkL:before{content:\"\";position:absolute;left:-0.426667rem;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);border-radius:50%;width:.16rem;height:.16rem;background-color:#ccc\n}\n.currentStatus_3WpWf .highlight_1iPGu{margin-bottom:0;color:#00c55d\n}\n.currentStatus_3WpWf .highlight_1iPGu:before{background-color:#00c55d\n}\n.linkLine_134vT{position:absolute;left:-0.36rem;bottom:.426667rem;width:.026667rem;height:.586667rem;background-color:#ebebeb\n}\n.main_pWy6S{max-height:9.146667rem;overflow:scroll\n}\n.item_1QVMo{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-content:center;align-content:center;padding:0 .533333rem 0 .653333rem;line-height:1.28rem;background:#fff\n}\n.item_1QVMo .value_1AYV3{font-size:.426667rem;color:#5a5a5a\n}\n.item_1QVMo .active_j44T8{color:#00c55d\n}\n.item_1QVMo .arrow_2G_8c{font-size:.4rem;color:#ccc\n}\n.button_2GAKj{height:1.306667rem;line-height:1.306667rem;color:#fff;background:#00c55d;text-align:center\n}", ""]);

// exports
exports.locals = {
	"box": "box_DzZcg",
	"header": "header_Qdzzw",
	"currentStatus": "currentStatus_3WpWf",
	"highlight": "highlight_1iPGu",
	"normal": "normal_3gYkL",
	"linkLine": "linkLine_134vT",
	"main": "main_pWy6S",
	"item": "item_1QVMo",
	"value": "value_1AYV3",
	"active": "active_j44T8",
	"arrow": "arrow_2G_8c",
	"button": "button_2GAKj"
};