export default function(start, end) {
	let startDate = new Date(start * 1000)
	let endDate = new Date(end * 1000)
	let data = {
		month: startDate.getMonth() + 1,
		date: startDate.getDate() < 10? '0' + startDate.getDate(): startDate.getDate(),
		day: ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'][startDate.getDay()],
		sHour: startDate.getHours() < 10? '0' + startDate.getHours() : startDate.getHours(),
		sMinute: startDate.getMinutes() < 10? '0'+ startDate.getMinutes(): startDate.getMinutes(),
		eHour: endDate.getHours() < 10? '0' + endDate.getHours() : endDate.getHours(),
		eMinute: endDate.getMinutes() < 10? '0' + endDate.getMinutes() : endDate.getMinutes()
	}
	return `${data.month}月${data.date}日 ${data.day} ${data.sHour}:${data.sMinute}-${data.eHour}:${data.eMinute}`
}
