import $http from 'utils/http'
import { stringify } from 'common/qs'
import { api_getOrderDetail, api_mergeOrderInfo, api_getOrderConsignee } from 'src/api/order'

// 获取收货地址
export async function getOrderConsignee({ commit }) {
  return api_getOrderConsignee().then(res => {
    commit('SET_ORDER_CONSIGNEE', { consignee: res.data.consignee })
    return res
  })
}

export const getConfirmInfo = ({commit}, params) => {
  const url = '/koala/api/v1.5/curriculum/confirm-order'
  return new Promise((resolve, reject) => {
    $http.get(url,{params}).then(res => {
      const consignee = {
        name: res.data.consignee_name,
        phone: res.data.consignee_phone,
        address: res.data.consignee_address,
        province: res.data.consignee_province,
        city: res.data.consignee_city,
        district: res.data.consignee_district,
      }
      commit('SET_CONFIRM_INFO', res.data)
      commit('SET_CONSIGNEE', consignee)
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const placeOrder = ({commit}, params) => {
  const url = '/koala/api/v1.5/curriculum/place-order'
  return new Promise((resolve, reject) => {
    $http.post(url, params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取适用于当前订单的coupon
export const getCouponList = ({commit}, param) => {
  const url = '/koala/api/v1.5/coupon/query'
  return new Promise((resolve, reject) => {
    $http.post(url, param).then(res => {
      commit('SET_COUPON_LIST', res.data)
      resolve(res.data)
    }).catch(err => {
      console.log(err)
    })
  })
}

// 获取所有未过期的coupon
export const getAllValidCoupon = ({commit}) => {
  const url = '/koala/api/v1.5/coupon/list'
  const param = {
    start: 0,
    length: 100,
    status_list: [0]
  }
  return new Promise((resolve, reject) => {
    $http.post(url, param).then(res => {
      commit('SET_VALID_COUPON', res.data)
      resolve(res.data)
    }).catch(err => {
      console.log(err)
    })
  })
}

// 选择coupon
export const chooseCoupon = ({commit}, coupon) => {
  return new Promise(resolve => {
    commit('CHOOSE_COUPON', coupon)
    resolve(true)
  })
}

export const asyncChangeTutor = ({commit}, params) => {
  const url = '/koala/api/v1.5/curriculum/change_class'
  let [curriculum_id, tutor] = params
  const param = {
    class_id: tutor.class_id,
    curriculum_id
  }
  return new Promise((resolve, reject) => {
    $http.put(url, stringify(param)).then(res => {
      resolve('success')
    })
  })
}

export const changeTutor = ({commit}, params) => {
  return new Promise((resolve, reject) => {
    commit('SET_CHOSEN_TUTOR', params)
    resolve('success')
  })
}

// 订单信息
export function getOrderDetail({ commit }, params) {
  return api_getOrderDetail(params).then(resp => {
    commit("SET_ORDER_DETAIL", resp.data)
    return resp.data
  })
}

// 订单信息
export function getMergeOrderInfo({ commit }, params) {
  return api_mergeOrderInfo(params).then(resp => {
    commit("SET_ORDER_DETAIL", resp.data)
    return resp.data
  })
}
