export default (start, end) => {
  const currentYear = new Date().getFullYear()
  let _startdate = new Date(start*1000)
  let _enddate = new Date(end*1000)
  let year = _startdate.getFullYear()
  let month = _startdate.getMonth() + 1 < 10? '0' + (_startdate.getMonth() + 1) : _startdate.getMonth() + 1
  let date = _startdate.getDate() < 10? '0' + _startdate.getDate() : _startdate.getDate()
  let day = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'][_startdate.getDay()]
  let sHour = _startdate.getHours() < 10? '0' + _startdate.getHours() : _startdate.getHours()
  let sMinute = _startdate.getMinutes() < 10? '0'+ _startdate.getMinutes(): _startdate.getMinutes()
  let eHour = _enddate.getHours() < 10? '0' + _enddate.getHours() : _enddate.getHours()
  let eMinute = _enddate.getMinutes() < 10? '0' + _enddate.getMinutes() : _enddate.getMinutes()
  return `${year === currentYear? '':year + '年'}${month}月${date}日 ${day} ${sHour}:${sMinute}-${eHour}:${eMinute}`
}
