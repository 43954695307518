exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.boxStyle_2dMCk{position:absolute;width:100%;bottom:0;background:#fff;border-radius:.32rem .32rem 0px 0px;overflow:hidden\n}\n.modalHeader_38iXQ{height:1.28rem;position:relative;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-bottom:.5333333333rem;text-align:center;background:#fafafa;font-size:.4266666667rem;font-weight:500;color:#464646\n}\n.modalHeader_38iXQ .iconClose_ZlbKs{position:absolute;top:50%;right:.5866666667rem;-webkit-transform:translateY(-50%);transform:translateY(-50%);font-size:.4266666667rem;color:#b3b3b3\n}", ""]);

// exports
exports.locals = {
	"boxStyle": "boxStyle_2dMCk",
	"modalHeader": "modalHeader_38iXQ",
	"iconClose": "iconClose_ZlbKs"
};