import http from 'utils/http'

export function api_teacherEvaluations(params) {
  const url = '/koala/api/v1.5/teacher/selected/evaluation/list'
  return http.get(url, { params })
}

/** 老师详情 */
export function api_getTeacherInfo(params) {
  const url = '/koala/api/v1.5/teacher/info'
  return http.get(url, { params })
}

/**
 * [获取学员班主任信息](http://yapi.xhwx100.com/project/11/interface/api/10908)
 * @param student_id  学生id
 */
export function api_getClassTeacherInfo (params){
  const url = '/koala/api/v1.5/class/teacher/info'
  return http.get(url, {params})
}
/**
 * [获取学员班主任信息](http://yapi.xhwx100.com/project/11/interface/api/10908)
 * @param 课程id数组 (支付详情)
 */
export function api_getClassTeacherInfoPay (params){
  let url = '/koala/api/v1.5/class/teacher/info'
  if (params) {
    url = `/koala/api/v1.5/class/teacher/info?${params}`
  }
  return http.get(url)
}
/**
 * [学生分配班主任状态] (http://yapi.xhwx100.com/project/11/interface/api/11682)
 * @param student_id 学生id
 * @returns assign_type 分配状态. 【1、已分配  2、分配中  3、已释放 】
 */
export function api_getTeacherAssignStatus (params) {
  const url = '/koala/api/v1.5/class/teacher/assign/status'
  return http.get(url, {params})
}

/**
 * [获取学生的辅导老师信息](http://yapi.xhwx100.com/project/11/interface/api/22437)
 * @param {string[]} tutor_id_list  辅导老师id数组	
 * @param {string} student_id - 有token的情况不需要传
 */
export function api_getStudentTutorInfo (params) {
  let url = '/koala/api/v1.5/tutor/add_wx/info'
  return http.post(url, params)
}

/**
 * [获取学生的辅导老师及其分配状态](http://yapi.xhwx100.com/project/11/interface/api/22590)
 * @param {string} student_id 
 */
export function api_getTutorInfoAllOrder (params) {
  let url = '/koala/api/v1.5/tutor/add_wx/status'
  return http.get(url, {params})
}