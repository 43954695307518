/** growingio 相关变量名 */

/** 页面级变量（详细定义请看growingio->事件和变量->页面级变量） */
export const GIO_PAGE_SET = {
  /** 使用浏览器种类 */
  "BROWSER_TYPE": 'browser_type',
  /** 课程ID */
  "COURSE_ID": 'course_id',
  /** 课程名称 */
  "COURSE_NAME": 'course_name',
  /** 页面类型 */
  "PAGE_TYPE": 'page_type',
  /** 产品类型 */
  "PRODUCT_TYPE": 'product_type',
  /** 课程类型 */
  "COURSE_GENRE": 'course_genre',
  /** 活动类型 */
  "ACTIVITY_TYPE": 'activity_type',
  /** 课程学科 */
  "COURSE_SUBJECT": 'course_subject',
  /** 课程年级 */
  "COURSE_GRADE": 'course_grade',
  /** 优惠券名称 */
  "COUPON_NAME": 'coupon_name',
  /** 辅导老师展示情况 */
  "SHOW_TUTOR": 'show_tutor',
  /** 辅导老师二维码类型 */
  "QRCODETYPE": 'qrcode_type',  // 0-无  1-长期  2-活动课
  /** 页面引导类型 */
  "GUIDE_TYPE": 'guide_type', //支付/拼团详情、课程/订单详情、APP：领取讲义、激活课程、上课提醒、获取福利、学习奖励、无
  /** 体验课活动名称 */
  "EXPERIENCE_COURSE_NAME": 'experience_course_name', 
  /** 体验课科目 */
  "EXPERIENCE_COURSE_SUBJECT": 'experience_course_subject',
  /** 登录注册页入口 */
  "LOGIN_REGISTRATION_ENTRANCE": "login_registration_entrance",
  /** 精品推荐课id */
  "BOUTIQUE_CURRICULUM_ID": "boutique_curriculum_id",
  /** 精品推荐课活动id */
  "BOUTIQUE_ALBUM_ID": "boutique_album_id",
}

/** 登录用户变量（详细定义请看growingio->事件和变量->登录用户变量） */
export const GIO_PEOPLE_SET = {
  /** 姓名 */
  "NAME": 'name',
  /** 年级 */
  "GRADE": 'grade',
  /** 注册渠道 */
  "SOURCE": 'source',
  /** 班课报课状态 */
  "CLASS_STATUS": 'class_status',
  /** 一对一报课状态 */
  "OTO_STATUS": 'oto_status',
  /** 是否付费学员 */
  "IS_PAID": 'is_paid',
  /** 购物车课程数量 */
  "SHOPPINGCART_NUM": 'shoppingcart_num',
}


/** 事件（详细定义请看growingio->事件和变量->事件变量、埋点事件） */
export const GIO_EVENT = {
  // 登录相关
  "LOGIN_REGISTRATION_EVENT": "login_registration_event", // 体验课时页引流登录注册事件

  "QRCODE_PRESS_COURSEDETAIL":'qrcode_press_detail', //长按二维码情况－课程详情
  "QRCODE_PRESS_ORDERCOMPLETED":'qrcode_press_ordercompleted', //长按二维码情况－支付详情
  "QRCODE_PRESS_ORDERDETAIL":'qrcode_press_orderdetail',  //长按二维码情况-订单详情
  "QRCODE_PRESS_PINTUAN": 'qrcode_press_pintuan', //长按二维码情况-拼团成功弹窗
  "QRCODE_PRESS_ORDERPOPUP": 'qrcode_press_orderpopup', //长按二维码情况-支付详情弹窗
  "QRCODE_PRESS_ADDTEACHER":'qrcode_press_addteacher',  //统计加微信页面页面长按二维码次数


  "BUTTON_CLICK_COURSEDETAIL":'button_click_coursedetail',  //点击复制按钮-课程详情
  "BUTTON_CLICK_ORDERCOMPLETED":'button_click_ordercompleted',  //点击复制按钮-支付详情页
  "BUTTON_CLICK_ORDERDETAIL":'button_click_orderdetail',  //点击复制按钮-订单详情
  "BUTTON_CLICK_PINTUAN":'button_click_pintuan',   //点击复制按钮-拼团成功弹窗
  "BUTTON_CLICK_ORDERPOPUP": 'button_click_orderpopup',  //点击复制按钮-支付详情弹窗

  "POPUP_COURSEDETAIL":'popup_coursedetail',  //课程详情弹窗调用情况
  "POPUP_ORDERCOMPLETED": 'popup_ordercompleted', //支付详情弹窗调用情况
  "POPUP_ORDERDETAIL": 'popup_orderdetail',   //订单详情弹窗调用情况
  "POPUP_PINTUAN":'popup_pintuan_success',  //拼团成功页弹窗调用情况

  "QRCODE_PRESS_ADDWECHAT":'qrcode_press_addwechat',  //长按二维码情况-加微信
  "ADDWECHAT_VIEW":'addwechat_view',  //加辅导微信页面浏览量
  "WECHAT_LOGIN_CLICK":'wechat_login_click', //微信登录按钮点击情况,

  "ADDTEACHER_VIEW":'addteacher_view',  //加微信-页面浏览量
  "TEACHER_SHOW": "teacher_show", // 加微信页面老师分配情况
  "ADDWECHAT_TYPE": "addwechat_type", // 班主任微信类型 描述班主任的微信类型：个人微信、企业微信、无

  // 专题投放
  "TOUTIAO_TABLE_APPLY_HOVER": "toutiao_table_clickapply_hover", //今日头条投放页_表单_点击悬浮报名
  "TOUTIAO_TABLE_CLICKNAME": "toutiao_table_clickname", //今日头条投放页_表单_点击请输入姓名
  "TOUTIAO_TABLE_CLICKPHONE": "toutiao_table_clickphone", //今日头条投放页_表单_点击请输入手机号
  "TOUTIAO_TABLE_CLICKVERIFY": "toutiao_table_clickverify", //今日头条投放页_表单_点击获取验证码
  "TOUTIAO_TABLE_CLICKGRADE": "toutiao_table_clickgrade", //今日头条投放页_表单_点击年级
  "TOUTIAO_TABLE_CLICKCITY": "toutiao_table_clickcity", //今日头条投放页_表单_点击所在城市
  "TOUTIAO_TABLE_CLICKAPPLY": "toutiao_table_clickapply", //今日头条投放页_表单_点击报名
  "TOUTIAO_PAGE_SHOW": "toutiao_page_show", //今日头条投放页_页面曝光
  
  // 体验课时埋点
  "STUDENT_SHARE_NUM": "student_share_num" , // 体验课时分享次数
  "LOGIN_SHARE_NUM": "login_share_num", // 体验课时人数
  "SHOW_SHARE_BUTTON": "show_share_button", // 体验课活动_出现分享按钮
  "CLICK_RECEIVE_BUTTON": "click_receive_button", // 体验课活动_点击领取按钮

  //体验活动课
  "SAVE_OFFICIALACCOUNT_CODE": "save_officialaccount_code", //公众号长按二维码
  "SAVE_WECHAT_PICTURE": "save_wechat_picture", // 添加图片 长按图片
  "CLICK_CROSS_CLOSE": "click_cross_close",  // 关闭添加微信叉号
  "CLICK_BLANK_CLOSE": "click_blank_close", // 空白蒙版关闭
  "LOGIN_QRCODE_SHOW":"login_qrcode_show", //登录后自动弹出二维码
  "LOGIN_QRCODE_HOLD_CLICK":"login_qrcode_hold_click",//登录后自动弹出二维码_长按图片
  "LOGIN_QRCODE_CLS_CLICK":"login_qrcode_cls_click",//登录后自动弹出二维码_关闭弹窗

  // 微信投放页
  "TTWX_TABLE_APPLY_HOVER": "ttwx_table_clickapply_hover", //今日头条投放页_表单_点击悬浮报名
  "TTWX_TABLE_CLICKNAME": "ttwx_table_clickname", //今日头条投放页_表单_点击请输入姓名
  "TTWX_TABLE_CLICKPHONE": "ttwx_table_clickphone", //今日头条投放页_表单_点击请输入手机号
  "TTWX_TABLE_CLICKVERIFY": "ttwx_table_clickverify", //今日头条投放页_表单_点击获取验证码
  "TTWX_TABLE_CLICKGRADE": "ttwx_table_clickgrade", //今日头条投放页_表单_点击年级
  "TTWX_TABLE_CLICKCITY": "ttwx_table_clickcity", //今日头条投放页_表单_点击所在城市
  "TTWX_TABLE_CLICKAPPLY": "ttwx_table_clickapply", //今日头条投放页_表单_点击报名
  "TTWX_PAGE_SHOW": "ttwx_page_show", //今日头条投放页_页面曝光

  // 4年级含以上的
  "TF4_TABLE_APPLY_HOVER": "tf4_table_clickapply_hover", //投放页_表单_点击悬浮报名
  "TF4_TABLE_CLICKNAME": "tf4_table_clickname", //投放页_表单_点击请输入姓名
  "TF4_TABLE_CLICKPHONE": "tf4_table_clickphone", //投放页_表单_点击请输入手机号
  "TF4_TABLE_CLICKVERIFY": "tf4_table_clickverify", //投放页_表单_点击获取验证码
  "TF4_TABLE_CLICKGRADE": "tf4_table_clickgrade", //投放页_表单_点击年级
  "TF4_TABLE_CLICKCITY": "tf4_table_clickcity", //投放页_表单_点击所在城市
  "TF4_TABLE_CLICKAPPLY": "tf4_table_clickapply", //投放页_表单_点击报名
  "TF4_PAGE_SHOW": "tf4_page_show", //投放页_页面曝光

  // 初一/高一
  "TF1_TABLE_APPLY_HOVER": "tf1_table_clickapply_hover", //投放页_表单_点击悬浮报名
  "TF1_TABLE_CLICKNAME": "tf1_table_clickname", //投放页_表单_点击请输入姓名
  "TF1_TABLE_CLICKPHONE": "tf1_table_clickphone", //投放页_表单_点击请输入手机号
  "TF1_TABLE_CLICKVERIFY": "tf1_table_clickverify", //投放页_表单_点击获取验证码
  "TF1_TABLE_CLICKGRADE": "tf1_table_clickgrade", //投放页_表单_点击年级
  "TF1_TABLE_CLICKCITY": "tf1_table_clickcity", //投放页_表单_点击所在城市
  "TF1_TABLE_CLICKAPPLY": "tf1_table_clickapply", //投放页_表单_点击报名
  "TF1_PAGE_SHOW": "tf1_page_show", //投放页_页面曝光

  // 抢报系统活动
  "PRESS_SAVE_PICTURE": "press_save_picture", // 抢报活动_长按保存图片
  "GET_ADDRESS": "get_address", // 抢报活动_成功获得收货地址
  // 直播销售-直播间销售链接页面曝光
  "LIVE_SHOW_LINKPAGE": "live_show_linkpage"
}

/** 直播间购物（详细定义请看growingio->变量对应事件变量、事件对应埋点事件 */
export const GIO_EVENT_SALE = {
  SHOW_LIVEROOM: 'show_liveroom', // 直播间曝光  
  LIVE_SHOW_SHOPPINGBAG: 'live_show_shoppingbag', //直播间_购物袋曝光  
  LIVE_CLICK_SHOPPINGBAG: 'live_click_shoppingbag', // 直播间_点击购物袋  
  LIVE_SHOW_PRODUCT: 'live_show_product', //直播间_产品弹窗曝光  
  LIVE_CLICK_PRODUCT: 'live_click_product', // 直播间_点击产品弹窗
}
/**切片课直播间买课 */
export const GIO_EVENT_EXPERIENCE_COURSES_SALE = {
  TRIAL_SHOW_LIVEROOM: 'trial_show_liveroom', // 切片课直播间曝光
  TRIAL_LIVE_CLICK_PRODUCT: 'trial_live_click_product', // 切片课直播间_点击产品弹窗
  TRIAL_LIVE_CLICK_SHOPPINGBAG: 'trial_live_click_shoppingbag', // 切片课直播间_点击购物袋
  TRIAL_LIVE_SHOW_SHOPPINGBAG: 'trial_live_show_shoppingbag', // 切片课直播间_购物袋曝光
  TRIAL_LIVE_SHOW_PRODUCT: 'trial_live_show_product', // 切片课直播间_产品弹窗曝光
  TRIAL_LIVE_SHOW_LINKPAGE: 'trial_live_show_linkpage', // 切片课直播间_链接页面曝光
}