import Vue from 'vue'
import XiaoConfirmVue from './XiaoConfirm.vue'
import BindWechatXiaoConfirmVue from './BindWechatXiaoConfirm.vue'

const XiaoConfirm = Vue.extend(XiaoConfirmVue)
const BindWechatXiaoConfirm = Vue.extend(BindWechatXiaoConfirmVue)

function compFactory (options, type = '') {
  switch (type) {
  case 'bindWechat': return new BindWechatXiaoConfirm(options)
  default: return XiaoConfirm(options)
  }
}

function xiaoConfirm(options) {
  const data = {
    okLabel: '确定',
    cancelLabel: '取消',
    title: '',
    content: ''
  }
  let type = ''
  if (typeof options === 'string') {
    data.title = options
  } else if (typeof options === 'object') {
    Object.assign(data, options)
    type = data.type
  } else {
    throw new Error(`Expect argument to be a String or an Object, but got "${typeof message}"`)
  }
  return new Promise((resolve) => {
    const vm = compFactory({ data }, type)
    vm.$once('confirm', ({ result }) => {
      resolve(result)
    })
    vm.$mount()
    document.body.appendChild(vm.$el)
  })
}

export default xiaoConfirm