exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.box_oFm0o{position:absolute !important;bottom:0;width:100%;background:#fff;border-radius:.266667rem .266667rem 0 0\n}\n.gradeItemWrapper_3wJKn{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-justify-content:space-between;justify-content:space-between\n}\n.gradeItem_1zaBD{width:2.56rem;height:1.066667rem;background:#fafafa;border-radius:.533333rem;text-align:center;line-height:1.066667rem;font-size:.373333rem;color:#464646;margin-bottom:.32rem\n}\n.gradeItem_1zaBD.isActive_1Hx7l{background-color:#00c55d;color:#fff\n}\n.body_3Xu8C{margin:.96rem .84rem .64rem\n}\n.gradeSectionTitle_24Eb9{display:-webkit-flex;display:flex;font-size:.32rem;line-height:1;color:#8a8a8a;margin-bottom:.32rem\n}\n.gradeSectionDot_3I7Ue{width:.213333rem;height:.213333rem;background-color:#e1e1e1;margin-right:.213333rem;border-radius:50%\n}\n.header_3WnIu{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;padding:0 .533333rem;height:1.493333rem;background-color:#fafafa;border-radius:.533333rem .533333rem 0 0\n}\n.title_2Zzmy{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;font-size:.426667rem;color:#00c55d\n}\n.iconClose_2gw1K{color:#b3b3b3;font-size:.4266666667rem\n}\n.titleDot_1-Wvs{width:.16rem;height:.16rem;background-color:#00c55d;margin-right:.32rem;border-radius:50%\n}", ""]);

// exports
exports.locals = {
	"box": "box_oFm0o",
	"gradeItemWrapper": "gradeItemWrapper_3wJKn",
	"gradeItem": "gradeItem_1zaBD",
	"isActive": "isActive_1Hx7l",
	"body": "body_3Xu8C",
	"gradeSectionTitle": "gradeSectionTitle_24Eb9",
	"gradeSectionDot": "gradeSectionDot_3I7Ue",
	"header": "header_3WnIu",
	"title": "title_2Zzmy",
	"iconClose": "iconClose_2gw1K",
	"titleDot": "titleDot_1-Wvs"
};