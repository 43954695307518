import logger from './aliLogger'

let eventTrackFrom = ""
let eventPath = ''

export default class eventTrackingSDK {
  constructor() {
    // 为保持每次的操作behavior_id是一致的，使用单例模式创造对象
    if (!eventTrackingSDK.instance) {
      this.activity_id = ""
      this.domBox = document.body
      // 此次活动的一系列事件总id，方便数据帅选
      this.behavior_id = generateUUID()
      this.onClickTrack()
      this.initLog()
      eventTrackingSDK.instance = this
    }
    return eventTrackingSDK.instance
  }
  getInstance(){
    return eventTrackingSDK.instance
  }
  /* 获取基础埋点参数 */
  getBaseOptions() {
    let event_id = generateUUID()
    let from = eventTrackFrom
    eventTrackFrom = event_id
    return {
      event_id,
      behavior_id: this.behavior_id,
      from_event_id: from,
    }
  }
  /** 设置eventPath */
  setEventPath(specific_event_key, reset = false){
    if(reset){
      return eventPath = specific_event_key + '>'
    }
    eventPath = eventPath + specific_event_key + '>'
  }
  /* 重设 behavior_id */
  resetBehaviorId(){
    this.behavior_id = generateUUID()
  }
  /* 获取 activityId */
  getActivityId(){
    return this.activity_id
  }
  /* 设置 activityId */
  setActivityId(activityId=''){
    this.activity_id = activityId
  }
  /* 委托的方式 把带有 track 属性的dom 做点击埋点 */
  onClickTrack() {
    this.domBox.addEventListener("click", this.clickTrack.bind(this))
  }
  clickTrack(e) {
    const target = (e && e.target) || null
    if (target && this.domBox.contains(target)) {
      let specific_event_key = target.getAttribute('track')
      let currentScene = target.getAttribute('track-scene') || ''
      let disable = Boolean(target.getAttribute('track-disable')) || false
      let delay = target.getAttribute('track-delay')
      if(typeof delay !== 'object') delay = parseInt(delay) || 100
      if (specific_event_key && !disable) {
        this.setEventPath(specific_event_key)
        const fn = () => {
          try {
            logger(
              Object.assign(
                {},
                this.getBaseOptions(),
                {
                  event_code: 'click',
                  current_scene: currentScene,
                  extra_field: {
                    specific_event_key,
                    activity_id: this.activity_id,
                    url: window.location.href || "",
                    eventPath: eventPath,
                  }
                }
              )
            )
          } catch(err) {
            console.log(err)
          }
        }
        delay ? setTimeout(fn, delay) : fn()
      }
    }
  }
  /* 初始化sdk 上报 */
  initLog(){
    this.gio('init_event', 'init_SDK')
  }
  /* 手动调用上报方法*/
  gio(event_code, specific_event_key, extra_field={}, current_scene = '',platform) {
    if (!event_code || !specific_event_key) return
    this.setEventPath(specific_event_key)
    try {
      logger(
        Object.assign({}, this.getBaseOptions(), {
          platform,
          event_code,
          current_scene,
          extra_field: Object.assign(
            {}, 
            {
              specific_event_key,
              activity_id: this.activity_id,
              url: window.location.href || "",
              eventPath: eventPath,
            }, 
            extra_field
          )
        })
      )
    } catch(err) {
      console.log(err)
    }
  }
  /* 取消绑定事件 */
  offTrack() {
    this.domBox.removeEventListener('click', this.clickTrack)
    eventTrackFrom = ''
    eventPath = ''
    eventTrackingSDK.instance = null
  }
}

function generateUUID() {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function(c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )
  return uuid
}