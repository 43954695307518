import http from 'utils/http'

export function api_alipayCheckout(params) {
  const url = '/koala/api/v1.5/pay/alipay/checkout'
  return http.get(url, { params })
}

export function api_wechatCheckout(params) {
  const url = '/koala/api/v1.5/pay/wxpay/unified-checkout'
  return http.post(url, params)
}


// 轮询是否刷单
export function api_pollBrushOrder(params) {
  const url = `/koala/api/v1.5/pay/polling`
  return http.get(url, { params })
}

// 获取AuthCode（用于H5登录验证）
export function api_authCode() {
  // const url = `${__DORADO_PROXY__}/dorado/api/v1.0/authcode`
  const url = `/dorado/api/v1.0/authcode`
  return http.get(url)
}

