import * as actions from 'vux/actions/report'

const state = {
	report: {},
	detail: {},
	list: []
}

const mutations = {
	SET_REPORT_DETAIL(state, detail) {
		state.report = detail.report
	},

	SET_REPORT_INFO(state, detail) {
		state.detail = detail
	},

	SET_TIKU_LIST(state, list) {
		state.list = state.list.concat(list)
	},

	RESET_TIKU_TITLE(state) {
		state.list = []
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
