/** Vue.prototype.$UA */

/** 判断是否QQ内置浏览器（注意不包括QQ浏览器） */
const isQQWebView = (() => {
  const ua = navigator.userAgent.toLowerCase()
  const isAndroid = /Android/i.test(ua)
  const isiOS = /i(Phone|Pad)/i.test(ua)
  if (isAndroid) {
    return /Mobile MQQBrowser/i.test(ua) || /QQ\//i.test(ua)
  } else if (isiOS) {
    return /QQ/i.test(ua) && !(/MQQBrowser/i.test(ua))
  } else {
    return false
  }
})()

export default {
  install(Vue) {
    const userAgent = navigator.userAgent.toLowerCase()
    Vue.prototype.$UA = {
      mobile: /mobi/.test(userAgent), // @todo 去掉这个判断
      qq: isQQWebView,
      pc: !/mobi|webview/.test(userAgent),
      weixin: /micromessenger/.test(userAgent) && !/windows/.test(userAgent),
      wxwork: /wxwork/.test(userAgent) && !/windows/.test(userAgent),
      iosWebview: /xiaoioswebview/.test(userAgent),
      androidWebview: /xiaoandroidwebview/.test(userAgent),
      webview: /xiao(ios|android)webview/.test(userAgent),
      isAndroid : userAgent.indexOf('android') > -1 || userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1,
      isIos : /iphone/.test(userAgent),
      webviewversion: /xiao(ios|android)webview,version=(\d+\.\d+.\d+)/.test(userAgent)? RegExp.$2 : "0.0.0",
    }
  }
}
