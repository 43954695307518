import * as actions from '../actions/course'

const state = {
  myCourseList: [],
  courseList: [],
  myEndedCourseList: [],
  myOnlineCourseList: [],
  hadEndedCourses: false,
  subjectList: [],
  isAllLoaded: false,
  banner: [],
  paramSubjectCode: -1,
  paramGenreCode: -1,
  // new
  albumList: [],
  genreList: [],
  city: ''
}

const mutations = {
  SET_MY_ENDED_COURSE_LIST(state, list) {
    state.myEndedCourseList = list
  },

  SET_MY_ONLINE_COURSE_LIST(state, data) {
    state.myOnlineCourseList = data.curriculum_list || []
    state.hadEndedCourses = data.had_close_curriculum
  },

  SET_COURSE_LIST(state, list) {
    state.courseList = list.curricula
    state.isAllLoaded = list.total === state.courseList.length
  },

  SET_ALBUM_LIST(state, list) {
    state.albumList = list
  },

  SET_MORE_COURSE_LIST(state, list) {
    state.courseList = state.courseList.concat(list.curricula)
    state.isAllLoaded = list.total <= state.courseList.length
  },

  SET_SUBJECT_LIST(state, list) {
    state.subjectList = list
  },

  SET_GRADE_BANNER(state, banner) {
    state.banner = banner || []
  },

  setParamSubject(state, {code}) {
    state.paramSubjectCode = code
  },

  setParamGenre(state, {code}) {
    state.paramGenreCode = code
  },

  SET_GENRE_LIST(state, list) {
    state.genreList = list
  },

  SET_CITY(state, code) {
    // if (!/^\d+$/.test(code)) return
    if (state.city === code) return
    state.city = code
    sessionStorage.setItem('city', state.city)
  },

  CLEAR_CITY(state) {
    state.city = ''
    sessionStorage.setItem('city', '')
    localStorage.setItem('syncCityFromLocalStorage', '')
    localStorage.removeItem('syncCityFromLocalStorage')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
