/**
 * 获取一个对象的深度属性值
 * @param {object} obj
 * @param {string[]} props
 * @return {object}
 */
export function deepGet(obj, props) {
  if (obj === null || typeof obj === 'undefined') {
    return obj
  }
  if (props.length === 0) {
    return obj
  }
  let objValue = obj
  for (let i = 0; i < props.length; ++i) {
    objValue = objValue[props[i]]
    if (objValue === null || typeof objValue === 'undefined') {
      return void 0
    }
  }
  return objValue
}