import Vue from 'vue'

export default {
  login() {
    const ua = Vue.prototype.$UA
    // 去掉hash再跳转
    // 为什么？
    // 因为从晓服务登录完成后会在url后面带上`?authCode=xxx`，路由回调会从`query`中取到这个`authCode`并登录到星火网校
    // 如果有hash，那么就不能统一从`query`中取到`authCode`，而要同时判断`hash`和`query`
    // 这样会很麻烦，而且同时判断可能会冲突
    const hashIndex = location.href.indexOf('#')
    const redirectUrl = hashIndex === -1 ? location.href : location.href.slice(0, hashIndex)
    if (ua.webview) {
      if (ua.androidWebview) {
        Callback.redirect('Login', [redirectUrl])
      } else if(ua.iosWebview) {
        window.webkit.messageHandlers.redirectLogin.postMessage({body: redirectUrl})
      }
      return
    }
    if(redirectUrl) {
      sessionStorage.setItem('afterLoginRoute', redirectUrl)
    }
    if(ua.weixin && !ua.wxwork){
      return window.location.href = `/wechatLoginSelect?redirect_uri=${encodeURIComponent(redirectUrl)}`
    }
    window.location.href = `/CaptchaLogin/phone?redirect_uri=${encodeURIComponent(redirectUrl)}`
  },
  // 从pc端同步登录状态
  syncAuth() {
    // 去掉hash再跳转
    // 为什么？
    // 因为从晓服务登录完成后会在url后面带上`?authCode=xxx`，路由回调会从`query`中取到这个`authCode`并登录到星火网校
    // 如果有hash，那么就不能统一从`query`中取到`authCode`，而要同时判断`hash`和`query`
    // 这样会很麻烦，而且同时判断可能会冲突
    const hashIndex = location.href.indexOf('#')
    const redirectUrl = hashIndex === -1 ? location.href : location.href.slice(0, hashIndex)
    window.location.href = `${__PC__DOMAIN__}/codeLogin?redirect_uri=${encodeURIComponent(redirectUrl)}`
  }
}
