export default <template>
<XiaoModal :visible.sync="visible" 
  :box-style="$style.box" 
  :showCloseIcon="false"
  :transformUpDown="true"
  :transformDefault="false">
  <div ref="header" :class="$style.header">
    <div :class="$style.title">
      <div :class="$style.titleDot"></div>
      请选择在读年级
    </div>
    <span :class="$style.iconClose" class="xiaoicon" @click="visible=false">&#xe759;</span>
  </div>

  <div :class="$style.body">
    <div v-for="gradeSection in GRADE_LIST" :key="gradeSection.name">
      <div :class="$style.gradeSectionTitle">
        <div :class="$style.gradeSectionDot"></div>
        {{gradeSection.name}}
      </div>
      <div :class="$style.gradeItemWrapper">
        <div v-for="grade in gradeSection.grade_list" :key="grade.code"
          :class="[$style.gradeItem, isCurrentGrade(grade) ? $style.isActive : '']"
          @click="selectGrade(grade)"  >
          {{grade.name}}
        </div>
      </div>
      
    </div>
  </div>
  


</XiaoModal>
</template>

<style lang="scss" module src="./index.module.scss"></style>

<script>
import XiaoModal from 'components/xiao-modal/XiaoModal.vue'


export default {
  props: ['grade'],
  components: { XiaoModal },
  data() {
    return {
      visible: false,
      GRADE_LIST: [{
        name: '小学',
        grade_list: [{
          name: '一年级',
          code: 1
        },{
          name: '二年级',
          code: 2
        },{
          name: '三年级',
          code: 3
        },{
          name: '四年级',
          code: 4
        },{
          name: '五年级',
          code: 5
        },{
          name: '六年级',
          code: 6
        }]
      }, {
        name: '初中',
        grade_list: [{
          name: '初一',
          code: 7
        },{
          name: '初二',
          code: 8
        },{
          name: '初三',
          code: 9
        }]
      }, {
        name: '高中',
        grade_list: [{
          name: '高一',
          code: 10
        },{
          name: '高二',
          code: 11
        },{
          name: '高三',
          code: 12
        }]
      }],
    }
  },
  methods: { 
    isCurrentGrade(grade) {
      return this.grade === grade.code
    },
    toggleVisible(val) {
      this.visible = val
    },
    selectGrade(grade) {
      this.$emit('update:grade', grade.code)
      this.visible = false
    }
  }
}
</script>
