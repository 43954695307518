import * as actions  from 'vux/actions/detail'
import enumCurriculum from 'src/enum/curriculum'

/**
 * @todo
 *
 * 事实证明，将 actions 放在另一个目录是不科学的做法
 *
 * 正确的目录结构应该是：
 *
 * - modules
 *   - detail
 *     - index.js
 *     - mutations.js
 *     - actions.js
 */

const state = {
  detail: {
    activity: {},
    dist: {},
    group_buying: {},
    tie: {},
    is_in_shopping_cart: false
  },
  fromReportingActivity: false, // 是否为抢报系统活动课程
  reportingActivityInfo: { // 抢报系统课程所属活动信息
    activity_id: '',
    grade: ''
  },
  price_break: {}, //满减优惠
  // combDiscounts: {},
  report: [],
  schedule: [],
  teacher: {},
  showDiscountsActionsheet: false,
  //显示拼团选择形式
  showPintuanActionsheet:false,
  /** 开售倒计时 */
  preHitCountdown: 0,
  /** 停售倒计时 */
  preOffCountdown: 0,
  /** 拼团活动距离开团结束倒计时 */
  pintuanCountdownSeconds: 0,
  /** 解锁结束倒计时 */
  tieCountdown: 0,
  /** 限时优惠倒计时 */
  discountCountdown: 0,
  /** 满减优惠倒计时 */
  priceBreakCountdownSeconds: 0,
  /** 联报优惠倒计时 */
  jointCountdownSeconds:0,
  showCoupon: false, //显示报课返券模态框

}

const getters = {
  mainLecturer(state) {
    const lecturers = state.detail.lecturers
    return lecturers && lecturers.length > 0 && lecturers[0] || {}
  },
  // 存在可用的限时优惠（可能未开始）
  limitedTimeOfferAvailable(state) {
    const activity = state.detail.activity
    return !!(activity.id && activity.price >= 0 && (activity.status === 0 || activity.status === 1) && activity.left_num > 0)
  },
  isGenre(state) {
    const isGenre = {
      longTerm: false, // 是否长期课 （包括正价课低价课）
      longTermLower: false, // 是否长期低价课
      freePublic: false,
      shortTerm: false
    }
    const genreList = state.detail.genre_list
    if (!genreList) {
      return isGenre
    }
    for (let i = 0; i < genreList.length; ++i) {
      const genreCode = genreList[i].code
      if (genreCode === enumCurriculum.genre.LONG_TERM_NORMAL || genreCode === enumCurriculum.genre.LONG_TERM_LOWER_PRICE || genreCode === enumCurriculum.genre.SHORT_TERM_NORMAL) {
        isGenre.longTerm = true
        if (genreCode === enumCurriculum.genre.LONG_TERM_LOWER_PRICE) {
          isGenre.longTermLower = true
        }
      } else if (genreCode === enumCurriculum.genre.FREE_PUBLIC) {
        isGenre.freePublic = true
      } else if (genreCode === enumCurriculum.genre.SHORT_TERM) {
        isGenre.shortTerm = true
      }
    }
    return isGenre
  },
  currentSubject(state) {
    const { subject_list } = state.detail
    if (!Array.isArray(subject_list)) return ''
    if (subject_list.length > 1) {
      return '多科'
    } else {
      const subject = subject_list[0]
      return subject ? subject.zh_cn : ''
    }
  },
}

const mutations = {
  GET_COURSE_DETAIL(state, detail) {
    detail.activity = detail.activity || {}
    detail.dist = detail.dist || {}
    // 拆出新的接口
    // detail.price_break = {} 
    detail.group_buying = detail.group_buying || {}
    detail.isExperienceLesson = detail.genre_list[0].code == 8
    state.detail = detail
    
  },

  GET_COURSE_SCHEDULE(state, schedule) {
    state.schedule = schedule
  },

  SET_CLASS_REPORT(state, report) {
    state.report = report.lesson_reports
  },

  // SET_DISCOUNT_LIST(state, discount) {
  //   state.combDiscounts = discount
  // },
  SET_PRICE_BREAK(state, price_break) {
    state.price_break = price_break
  },  
  SET_COURSE_TEACHER(state, teacher) {
    state.teacher = teacher
  },

  SET_CURRICULUM_ID(state, id){
    state.detail.curriculum_id = id
  },

  RESET_COURSE_INFO(state) {
    state.detail = {
      activity: {},
      dist: {},
      group_buying: {}
    }
    // state.combDiscounts= {}
    state.report = []
    state.schedule = []
    state.teacher = {}
    state.pintuanCountdownSeconds = 0
    state.showDiscountsActionsheet = false
    state.showPintuanActionsheet = false
  },

  SHOW_DISCOUNTS(state, show) {
    state.showDiscountsActionsheet = show
  },
  SHOW_PINTUAN(state, show){
    state.showPintuanActionsheet = show
  },
  setActivity(state, activity) {
    state.detail.activity = Object.assign({}, state.detail.activity, activity)
  },

  PINTUAN_COUNTDOWN_SET(state, { seconds }) {
    state.pintuanCountdownSeconds = seconds
  },
  PINTUAN_COUNTDOWN_TICK(state) {
    state.pintuanCountdownSeconds -= 1
  },

  setPreHitCountdown(state, { seconds }) {
    state.preHitCountdown = seconds
  },
  setPreOffCountdown(state, { seconds }) {
    state.preOffCountdown = seconds
  },
  TIE_COUNTDOWN_SET(state, { seconds }) {
    state.tieCountdown = seconds
  },
  TIE_COUNTDOWN_TICK(state) {
    state.tieCountdown -= 1
  },
  DISCOUNT_COUNTDOWN_SET(state, { seconds }) {
    state.discountCountdown = seconds
  },
  DISCOUNT_COUNTDOWN_TICK(state) {
    state.discountCountdown -= 1
  },
  COUPON_SET(state, { status }) {
    state.showCoupon = status
  },
  PRICE_BREAK_COUNTDOWN_SET(state, { seconds }) {
    state.priceBreakCountdownSeconds = seconds
  },
  PRICE_BREAK_COUNTDOWN_TICK(state) {
    state.priceBreakCountdownSeconds -= 1
  },
  JOINT_COUNTDOWN_SET(state, { seconds }) {
    state.jointCountdownSeconds = seconds
  },
  JOINT_COUNTDOWN_TICK(state) {
    state.jointCountdownSeconds -= 1
  },
  SET_FROM_REPORTING_ACTIVITY (state, val) {
    state.fromReportingActivity = val
  },
  SET_REPORTING_ACTIVITY_INFO (state, info) {
    state.reportingActivityInfo = info
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
