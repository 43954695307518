import http from "utils/http"

/**
 * [获取专题课列表] (http://yapi.xhwx100.com/project/11/interface/api/10215)
 */
export function api_getExperienceLessonList(params) {
  const url = "/koala/api/v1.7/special-curriculum/curriculum-list"
  return http.get(url, { params })
}

/**
 * [获取年级学科编码](http://yapi.xhwx100.com/project/11/interface/api/10224)
 */
export function api_getExperienceLessonCategory(params) {
  const url = "/koala/api/v1.7/special-curriculum/category"
  return http.get(url, { params })
}

/**
 * [获取体验课信息](http://yapi.xhwx100.com/project/11/interface/api/10125)
 */
export function api_getExperienceActivityInfo(params) {
  const url = "/koala/api/v1.7/special-curriculum/activity"
  return http.get(url, { params })
}

/**
 * [获取课程对应的课时信息] (http://yapi.xhwx100.com/project/11/interface/api/10233)
 */
export function api_getCurriculumLessonTime(params) {
  const url = "/koala/api/v1.7/special-curriculum/curriculum-lesson-time"
  return http.post(url, params)
}

/**
 * [专题课埋点接口]()
 */
export function api_experienceUV(params) {
  const url = "/koala/api/v1.7/special-curriculum/uv"
  return http.post(url, params)
}

/**
 * [多科拼团课程列表](http://yapi.xhwx100.com/project/11/interface/api/12555)
 * @author [lvchaoyang](http://yapi.xhwx100.com/user/profile/32)
 * @param {object} params - 请求参数
 * @param {number} params.group_buying_id - 拼团活动id
 * @param {string} params.level_id - 拼团阶id
 * @param { import('axios').AxiosRequestConfig } options - 其它axios配置，例如`timeout`、`cancelToken`等
 * @return { import('axios').AxiosPromise<{ subject_list: array,curriculum_list: array }> }
 */
export function api_groupbuyingCurriculumList(params, options) {
  return http.post("/koala/api/v1.5/group-buying/curriculum/list", params, {
    options,
  })
}

/**
 * [多科拼团获取价格](http://yapi.xhwx100.com/project/11/interface/api/13320)
 */
export function api_groupbuyingPriceInfo(params, options) {
  return http.post("/koala/api/v1.5/group-buying/multi/price-info", params, {
    options,
  })
}

/**
 * [抢报活动页面](http://yapi.xhwx100.com/project/11/interface/api/14238)
 */
export function api_getOrderSystemDetail(params, options) {
  return http.get(
    "/koala/api/v1.7/reporting-activity/detail",
    { params },
    { options }
  )
}

/**
 * [代理商活动页](http://yapi.xhwx100.com/project/11/interface/api/18189)
 */
export function api_getOrderSystemAgentActivityDetail(params) {
  const url = "/koala/api/v1.7/agent-activity/detail"
  return http.get(url, { params })
}

/**
 * [订单绑定活动](http://yapi.xhwx100.com/project/11/interface/api/18189)
 */
export function api_bindAgentActivityBindOrder(params) {
  const url = "/koala/api/v1.7/agent-activity/bind-order"
  return http.post(url, params)
}

/**
 * [推广h5课程信息](http://yapi.xhwx100.com/project/11/interface/api/18891)
 */
export function api_getCurriculumDetails(params, options) {
  return http.post("/koala/api/v1.5/curriculum/base-details", params, {
    options,
  })
}
/**
 * [朋友圈广告-已支付课时回放](http://yapi.xhwx100.com/project/11/interface/api/18900)
 */
export function api_getVideoList(params) {
  return http.get("/koala/api/v1.5/storage/curriculum/video-list", { params })
}

/**
 * [朋友圈广告-查看用户数是否购买该课程](http://yapi.xhwx100.com/project/11/interface/api/19161)
 */
export function api_getCurriculumIsBuy(params) {
  return http.get("/koala/api/v1.5/curriculum/is_buy", { params })
}

/**
 * [模拟验证]
 */
export function api_fakeLoginByCaptcha(params) {
  return http.get("/dorado/api/v1.0/captcha/test", { params })
}

/**
 * [朋友圈下单] (http://yapi.xhwx100.com/project/11/interface/api/18918)
 */
export function api_friendSubmitOrder(params) {
  return http.post("/koala/api/v1.5/curriculum/ad-pay/place-order", params)
}

/**
 * [获取拼团的多年级信息](http://yapi.xhwx100.com/project/11/interface/api/20385)
 */
export function api_getCurriculumAvaliableGrade(params) {
  const url = "/koala/api/v1.5/group-buying/curriculum/available-grade"
  return http.post(url, params)
}

/**
 * [获取某个课程信息](http://yapi.xhwx100.com/project/27/interface/api/21716)
 */
export function api_getLessonInfo(params) {
  const url = "/koala/api/v1.7/activity-trial/lesson"
  return http.get(url, { params })
}

/**
 * [记录链接分享成功](http://yapi.xhwx100.com/project/27/interface/api/21372)
 */
export function api_sharedLink(params) {
  const url = "/koala/api/v1.7/activity-trial/share"
  return http.post(url, params)
}

/**
 * [学规师微信二维码](http://yapi.xhwx100.com/project/27/interface/api/21388)
 */
export function api_getWechatQrcode(params) {
  const url = "/koala/api/v1.7/activity-trial/wechat-qrcode"
  return http.get(url, { params })
}

/**
 * [学生绑定学规师](http://yapi.xhwx100.com/project/27/interface/api/21356)
 */
export function api_bindTeacher(params) {
  const url = "/koala/api/v1.7/activity-trial/bind"
  return http.post(url, params)
}

/**
 * [获取推流链接](http://yapi.xhwx100.com/project/11/interface/api/21764)
 */
export function api_getVideoLink(params) {
  const url = "/koala/api/v1.7/activity-trial/pull"
  return http.get(url, { params })
}
/**
 * [心跳](http://yapi.xhwx100.com/project/11/interface/api/21772)
 */
export function api_heartbeat(params, options) {
  const url = "/koala/api/v1.7/activity-trial/heart-beat"
  return http.post(url, params, options)
}
/**
 * [获取学生是否分享了课时](http://yapi.xhwx100.com/project/11/interface/api/21820)
 */
export function api_isSharedLesson(params) {
  const url = "/koala/api/v1.7/activity-trial/is-share"
  return http.get(url, { params })
}
/**
 * [回看链接](http://yapi.xhwx100.com/project/11/interface/api/21844)
 */
export function api_getPlaybackUrl(params) {
  const url = "/koala/api/v1.7/activity-trial/playback"
  return http.get(url, { params })
}

/**
 * [回看链接](http://yapi.xhwx100.com/project/11/interface/api/21957)
 */
export function api_updateVideoStatus(params) {
  const url = "/koala/api/v1.7/activity-trial/trial-status-change"
  return http.post(url, params)
}

/**
 * [抢报系统-获取cityCode](http://yapi.xhwx100.com/project/11/interface/api/23435)
 */
export function api_getCityCode(params) {
  const url = "/koala/api/v1.7/reporting-activity/city/code"
  return http.get(url, { params })
}

/**
 * [抢报系统-获取可下单科目](http://yapi.xhwx100.com/project/11/interface/api/23435)
 */
export function api_getOrderSubjects(params) {
  const url = "/koala/api/v1.7/reporting-activity/curriculum/subject"
  return http.post(url, params)
}

/**
 * [抢报系统-获取学员是否有资格参加活动](http://yapi.xhwx100.com/project/11/interface/api/31176)
 */
export function api_getIsQualified(params) {
  const url = "/koala/api/v2.1/reporting/activity/student-type"
  return http.post(url, params)
}

/**
 * [抢报系统-获取可下单年级](http://yapi.xhwx100.com/project/11/interface/api/23435)
 */
export function api_getOrderGrades(params) {
  const url = "/koala/api/v1.7/reporting-activity/activity/grade"
  return http.get(url, { params })
}

/**
 * [抢报系统-获取推荐课程](http://yapi.xhwx100.com/project/11/interface/api/23666)
 */
export function api_getGroupbuyingOrderCurriculum(params) {
  const url = "/koala/api/v1.7/reporting-activity/curriculum/list"
  return http.post(url, params)
}

/**
 * [抢报系统-下单](http://yapi.xhwx100.com/project/11/interface/api/24016)
 */
export function api_getGroupbuyingPlaceOrder(params) {
  const url = "/koala/api/v1.7/reporting-activity/place-order"
  return http.post(url, params)
}

/**
 * [抢报系统2021暑假-课程组合](http://yapi.xhwx100.com/project/11/interface/api/24016)
 */
export function api_getReportingChooseClass(params) {
  const url = "/koala/api/v2.1/reporting/activity/product"
  return http.post(url, params)
}
/**
 * [抢报系统2021暑假-价格信息]
 */
export function api_getTransactionPriceInfo(params) {
  const url = "/koala/api/v2.1/transaction/price-info"
  return http.post(url, params)
}
/**
 * [抢报系统2021暑假-下单]
 */
export function api_getTransactionPlaceOrder(params) {
  const url = "/koala/api/v2.1/transaction/place-order"
  return http.post(url, params)
}
// /api/v2.1/transaction/place-order

/**
 * [抢报系统618奖励页面](http://yapi.xhwx100.com/project/11/interface/api/32268)
 */
export function api_getOrderSystemAwardDetail () {
  const url = "/koala/api/v2.1/reporting/activity/award/code"
  return http.get(url)
}

/**
 * [抢报系统页面获取省份城市接口](http://yapi.xhwx100.com/project/11/interface/api/32268)
 */
export function api_getOrderSystemSortLocation (params) {
  const url = "/koala/api/v2.1/reporting/activity/lower-list-sort"
  return http.get(url, { params })
}

/**
 * [获取订单地址](http://yapi.xhwx100.com/project/11/interface/api/24135)
 */
export function api_getConsigneeByOrdernum(params) {
  const url = "/koala/api/v1.7/reporting-activity/order/consignee"
  return http.get(url, { params })
}

/**
 * [保存订单地址](http://yapi.xhwx100.com/project/11/interface/api/23862)
 */
export function api_setConsigneeApply(params) {
  const url = "/koala/api/v1.7/reporting-activity/consignee/apply"
  return http.post(url, params)
}

/**
 * [抢报系统-换课](http://yapi.xhwx100.com/project/11/interface/api/23939)
 */
export function api_changeCurriculum(params) {
  const url = "/koala/api/v1.7/reporting-activity/change-curriculum"
  return http.post(url, params)
}

// 续费h5专题页
/**
 * [续费-获取活动详情](http://yapi.xhwx100.com/project/11/interface/api/27366)
 */
export function api_getRenewalActivityDeatil(params) {
  const url = "/koala/api/v1.7/renew-activity/detail"
  return http.get(url, { params })
}

/**
 * [续费-获取科目](http://yapi.xhwx100.com/project/11/interface/api/27121)
 */
export function api_getRenewalActivitySubjects(params) {
  const url = "/koala/api/v1.7/renew-activity/subject-list"
  return http.get(url, { params })
}

/**
 * [续费-获取学生默认年级](http://yapi.xhwx100.com/project/11/interface/api/27306)
 */
export function api_getRenewalActivityDefaultGrade(params) {
  const url = "/koala/api/v1.7/renew-activity/default-grade"
  return http.get(url, params)
}

/**
 * [续费-首页选择科目价格预览](http://yapi.xhwx100.com/project/11/interface/api/27076)
 */
export function api_getRenewalActivityPreviewPrice(params) {
  const url = "/koala/api/v1.7/renew-activity/preview/price-info"
  return http.post(url, params)
}

/**
 * [续费-下单页价格信息](http://yapi.xhwx100.com/project/11/interface/api/27081)
 * @param {*} params
 */
export function api_getRenewalPriceInfo(params) {
  const url = "/koala/api/v1.7/renew-activity/order/price-info"
  return http.post(url, params)
}

/**
 * [合并订单列表](http://yapi.xhwx100.com/project/11/interface/api/27116)
 * @param {*} params
 */
export function api_getRenewalAutoJointList(params) {
  const url = "/koala/api/v1.7/renew-activity/order/auto-joint-list"
  return http.post(url, params)
}

/**
 * [续费-获取可选课程](http://yapi.xhwx100.com/project/11/interface/api/27061)
 */
export function api_getRenewSelectaleCurriculum(params) {
  const url = '/koala/api/v1.7/renew-activity/select-curriculum'
  return http.post(url, params)
}

/**
 * [续费优惠券列表](http://yapi.xhwx100.com/project/11/interface/api/27196)
 * @param {*} params
 */
export function api_getRenewalCoupon(params){
  const url = '/koala/api/v1.7/renew-activity/coupons'
  return http.post(url, params)
}
/**
 * [续费下单](http://yapi.xhwx100.com/project/11/interface/api/27231)
 * @param {*} params
 */
export function api_getRenewalPostOrder(params) {
  const url = "/koala/api/v1.7/renew-activity/place-order"
  return http.post(url, params)
}

/*
 * [续费-获取上一季度报课记录](http://yapi.xhwx100.com/project/11/interface/api/27171)
 */
export function api_getLastQuarterPaidInfo(params) {
  const url = '/koala/api/v1.7/renew-activity/paid-info-by-quarter'
  return http.post(url, params)
}
/**
 * [检查有未支付订单](http://yapi.xhwx100.com/project/11/interface/api/27101)
 */
export function api_getRenewalExistUndo(params) {
  const url = '/koala/api/v1.7/renew-activity/exist-undo'
  return http.get(url, { params })
}
/**
 * [续费-检查课程剩余名额]
 */
export function api_checkCurrsLeftNum(params) {
  const url = "/koala/api/v1.5/curriculum/sale-out-list"
  return http.post(url, params)
}

/**
 * [体验课检验用户是否有播放权限]
 */
export function api_checkFreeTrial(params) {
  const url = "/koala/api/v1.5/curriculum/free-trial/play"
  return http.post(url, params)
}

/**
 * [体验课检验用户-心跳记录体验时长]
 */
export function api_TrialHeartbeat(params) {
  const url = "/koala/api/v1.5/curriculum/free-trial/experience-time"
  return http.post(url, params)
}
/**
 * [用户通过体验课时链接注册后生成更新已注册数据]
 */
export function api_TrialAfterRegister(params) {
  const url = "/koala/api/v1.5/curriculum/free-trial/init-trial-after-register"
  return http.post(url, params)
}

/**
 * [我的试听课][http://yapi.xhwx100.com/project/11/interface/api/28848]
 */
export function api_getTrialClassList(params) {
  const url = "/koala/api/v1.5/personal_center/free-trial/lesson-list"
  return http.get(url, { params })
}

/**
 * [我的试听课获取学规师微信二维码](http://yapi.xhwx100.com/project/11/interface/api/28968)
 */
export function api_getTrialWechatQrcode(params) {
  const url = "/koala/api/v1.5/teacher/wechat-qrcode"
  return http.get(url, { params })
}

/**
 * [新抢报-抢报活动详情](http://yapi.xhwx100.com/project/27/interface/api/29453)
 */
export function api_getNewOrderSystemActivityDetail(params) {
  const url = "/koala/api/v2.1/reporting/activity/detail"
  return http.get(url, { params })
}

/**
 * [新抢报-抢报活动获取相应科目组合](http://yapi.xhwx100.com/project/11/interface/api/29463)
 */
export function api_getNewOrderSystemSubjects(params) {
  const url = "/koala/api/v2.1/reporting/activity/subject"
  return http.post(url, params)
}
/**
 * [新抢报-微信授权获取微信头像昵称]
 */
export function api_getWechatUserInfo(params,opt) {
  const url = "/koala/api/v1.5/wechat/userinfo"
  return http.put(url, params,opt)
}
// /api/v1.5/wechat/userinfo headers:{'Content-Type':'application/x-www-form-urlencoded'}

/**
 * [新抢报-分销活动被邀请者上报]
 */
export function api_fissionEnterIndexReport (params) {
  const url = "/koala/api/v2.1/fission/activity/open/link"
  return http.post(url, params)
}

/**
 * [裂变活动列表](http://yapi.xhwx100.com/project/11/interface/api/29708)
 */
export function api_getFissionList(params) {
  const url = '/koala/api/v2.1/fission/activity/list'
  return http.get(url, { params })
}
/**
 * [更改裂变参与活动](http://yapi.xhwx100.com/project/11/interface/api/30168)
 */
export function api_fissionChangeWechat(params) {
  const url = '/koala/api/v2.1/fission/activity/change/wechat'
  return http.post(url, params)
}

/**
 * [抢报切片课特殊配置](http://yapi.xhwx100.com/project/11/interface/api/31536)
 */
export function api_getSpecialExperienceCourse(params) {
  const url = '/koala/api/v2.1/course/handle-CourseId'
  return http.post(url, params)
}