export default <template>
  <transition
    :enter-class="$style.enter"
    :enter-active-class="$style.enterActive"
    :leave-to-class="$style.leaveTo"
    :leave-active-class="$style.leaveActive"
    @after-leave="destroyElement"
  >
    <div
      v-if="visible"
      :class="[
        $style.toast,
        $style[type],
        {
          [$style.bottom]: bottom,
          [$style.middle]: middle,
          [$style.middleAnimate]: middleAnimate
        }
      ]"
    >
      <div ref="content" :class="$style.text" :style="{textAlign: textAlign,whiteSpace: whiteSpace}">{{ message }}</div>
    </div>
  </transition>
</template>

<style lang="scss" module>
.toast {
  position: fixed;
  left: 0;
  right: 0;
  top: 0.266667rem;
  font-size: 0.37333333rem;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  z-index: 1001;
  opacity: 0.96;
}

.text {
  display: inline-block;
  padding: 0.32rem 0.42666667rem;
  line-height: 0.56rem;
  border-radius: 0.32rem;
  margin: 0 0.64rem;
}

.toast.middle {
  top: 50%;
  transform: translateY(-50%);
}

.toast.middleAnimate {
  top: 50%;
  transform: translateY(-50%);
  animation: changeSize cubic-bezier(0.01, 1.25, 1, 1) 500ms forwards;
}
.toast.bottom {
  bottom: 0.666667rem;
  top: auto;
}

.success .text {
  background: #5a5a5a;
  opacity: 0.96;
  &::before {
    content: '';
    display: inline-block;
    width: 0.53333333rem;
    height: 0.53333333rem;
    background: url('./images/toast_hint.svg') no-repeat;
    background-size: cover;
    vertical-align: top;
    margin-right: 0.16rem;
  }
}
.warning .text {
  background: #5a5a5a;
  &::before {
    content: '';
    display: inline-block;
    width: 0.53333333rem;
    height: 0.53333333rem;
    background: url('./images/toast_warning.svg') no-repeat;
    background-size: cover;
    vertical-align: top;
    margin-right: 0.16rem;
  }
}
.info .text {
  background: #5A5A5A;
}

.newInfo .text {
  background: rgba(90, 90, 90, 0.9);
}

.success_1v1 .text {
  background: #5f74ff;
}
.warning_1v1 .text {
  background: #e0163f;
}

.warning_black .text {
  background: #464646;
}

.enter {
  opacity: 0;
  transform: translateY(-0.906667rem);
  &.bottom {
    transform: translateY(0.906667rem);
  }
}

.enterActive {
  transition: all 0.2s;
}

.leaveTo {
  opacity: 0;
}

.leaveActive {
  transition: all 0.75s;
}

@keyframes changeSize {
  10% {
    transform: scale(0) translateY(-50%);
  }
  50% {
    transform: scale(1.05) translateY(-50%);
  }
  100% {
    transform: scale(0.95) translateY(-50%);
  }
}
</style>

<script>
export default {
  data() {
    return {
      message: '',
      type: '',
      bottom: false,
      middle: false,
      visible: false,
      middleAnimate: false,
      whiteSpace:'normal',
      timeout: 2000,
      textAlign: 'center'
    }
  },
  methods: {
    startTimer() {
      this.timer_ = setTimeout(() => {
        this.close()
      }, this.timeout)
    },
    close() {
      if (!this.visible) {
        return false
      }
      this.visible = false
      if (this.timer_) {
        clearTimeout(this.timer_)
      }
    },
    destroyElement() {
      const $el = this.$el
      this.$destroy()
      if ($el.parentNode) {
        $el.parentNode.removeChild($el)
      }
    },
    setTextAlign() {
      this.$nextTick(() => {
        const ele = this.$refs.content
        if (!ele) {
          return
        }
        const styles = window.getComputedStyle(ele, null)
        // 超过一行
        if (styles.getPropertyValue("height") > styles.getPropertyValue("line-height")) {
          this.textAlign = 'left'
        }
      })
    }
  },
  mounted() {
    this.visible = true
    this.setTextAlign()
    this.startTimer()
  }
}
</script>
