exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.box_3dYwH{top:6.16rem;width:8.4rem;background:#fff;border-radius:.266667rem\n}\n.title_2w8zv{font-size:.64rem;line-height:1;font-weight:500;color:#5a5a5a;padding:.96rem 0 .32rem;text-align:center;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none\n}\n.desc_Dwnzf{font-size:.373333rem;line-height:1;color:#8a8a8a;margin-bottom:1.28rem;text-align:center;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none\n}\n.noCaptchaWrapper_19jna{padding-bottom:.746667rem\n}", ""]);

// exports
exports.locals = {
	"box": "box_3dYwH",
	"title": "title_2w8zv",
	"desc": "desc_Dwnzf",
	"noCaptchaWrapper": "noCaptchaWrapper_19jna"
};