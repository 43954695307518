/*
  获取版本号
 */

const getAgentInfo = (agent, param) => {
  const list = agent.split(',')
  return +(list.filter(item => item.indexOf(param) > -1)
    .map(item => item.split('='))
    .map(item => item[1])[0] || 0)
}

const userAgent = navigator.userAgent.toLowerCase()

const isAndroidApp = /xiaoandroidwebview/.test(userAgent)

const isIosApp = /xiaoioswebview/.test(userAgent)

const isApp = isAndroidApp || isIosApp

const version = isApp && getAgentInfo(userAgent, 'version') || 0

const IOS_BUILD_API_PLAYBACK = 100 // 回放API临界版本号

const ANDROID_KF_VERSION = 9 // 安卓客服临界版本号

const ANDROID_VIDEO_VERSION = 13 // 安卓显示老师介绍视频版本号
export {
  isAndroidApp,
  isIosApp,
  isApp,
  version,
  IOS_BUILD_API_PLAYBACK,
  ANDROID_KF_VERSION,
  ANDROID_VIDEO_VERSION
}