import * as actions from 'vux/actions/trial'

const state = {
  trialList: [],
  total: 0,
  overview: {}
}

const mutations = {
  SET_TRIAL_LIST(state, list) {
    state.trialList = list
  },
  SET_TRIAL_TOTAL(state, total) {
    state.total = total
  },
  SET_TRIAL_OVERVIEW(state, overview) {
    state.overview = overview
  },
  SET_LESSON_GETTED(state, id) {
    const lesson = state.trialList.find(item => item.curriculum.id === id)
    const idx = state.trialList.indexOf(lesson)
    const temp = {...lesson, status: 3}
    state.trialList.splice(idx, 1, temp)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
