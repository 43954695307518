import fixIphoneX from './fixIphoneX'

export default function install(Vue) {
  /**
   * 兼容iphoneX系列以上的iphone手机底部黑条
   * 以下是元素为绝对定位时可选参数
   * @param {Element} paddingDom 需要增加底部padding的dom,默认为父元素
   * 以下是元素为非绝对定位时可选参数
   * @param {String} type 采用兼容方式，可为padding、margin、bottom，默认为padding
   */
  Vue.directive('fixIphoneX', fixIphoneX(Vue))
}
