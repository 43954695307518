import $http from 'utils/http'
import { setAuth, setUserId } from 'utils/local'
import { stringify } from 'common/qs'

export const getTeacherCube = ({commit}, params) => {
	const url = '/koala/api/v1.5/home/teacher-cube/list'
	$http.get(url)
		.then(res => {
			commit('SET_TEACHER_CUBE', res.data)
		})
}

export const getPlaybackList = ({commit}, params) => {
	const url ='/koala/api/v1.5/home/wonderful/playback/list'
	$http.get(url)
		.then(res => {
			commit('SET_PLAYBACK_LIST', res.data)
		})
}

export const getParentsPraise = ({commit}, params) => {
	const url = '/koala/api/v1.5/home/parents/praise/selected-list?start=0&length=100'
	$http.get(url)
		.then(ress => {
			commit('SET_PARENTS_PRAISE', ress.data)
		})
}
