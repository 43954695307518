import cooker from 'xiao-utils/cooker'

function localGet(name) {
  return cooker.get(name)
}

function localSet(name, value) {
  localStorage.setItem(name, value)
  cooker.put(name, value, { vEnd: Infinity , sPath: '/'})
}

function localGetter(name) {
  return function () {
    return localGet(name)
  }
}

function localSetter(name) {
  return function (value) {
    localSet(name, value)
  }
}

export const auth = localGetter('token')
export const userId = localGetter('user_id')
export const setAuth = localSetter('token')
export const setUserId = localSetter('user_id')