/* 各种枚举 */

const subject = {
  '20': 'math',
  '21': 'chinese',
  '22': 'english',
  '23': 'geography',
  '24': 'biology',
  '25': 'chemistry',
  '26': 'physics',
  '27': 'history',
  '28': 'politics',
  '29': 'wz', //文综
  '30': 'lz', //理综
  '31': 'science', // 科学
  '32': 'olympic' // 奥数
}

const subjectZh_cn = {
  '20': '数学',
  '21': '语文',
  '22': '英语',
  '23': '地理',
  '24': '生物',
  '25': '化学',
  '26': '物理',
  '27': '历史',
  '28': '政治',
  '29': '文综',
  '30': '理综',
  '31': '科学',
  '32': '奥数',
  '33': '社会',
  '34': '通用技术',
  '35': '信息技术'
}

// 只返回以下三种
const OrderStatus = {
  '1': '待支付',
  '2': '已支付',
  '7': '已取消'
}

const grades = {
  '1': '一年级',
  '2': '二年级',
  '3': '三年级',
  '4': '四年级',
  '5': '五年级',
  '6': '六年级',
  '7': '七年级',
  '8': '八年级',
  '9': '九年级',
  '10': '高一',
  '11': '高二',
  '12': '高三'
}

const OTO_COURSE_TYPE = {
  0: '班课',
  1: '1对1'
}

export {
  subject,
  OrderStatus,
  grades,
  subjectZh_cn,
  OTO_COURSE_TYPE
}
