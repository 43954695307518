export default <template>
  <xiao-modal 
    :modalStyle="{height: '100%'}"
    :visible.sync="innerVisible"
    :showCloseIcon="showCloseIcon"
    :preventTouchMove="true"
    :tranformDefault="false"
    :boxStyle="`${$style.boxStyle} ${verticalPosition === 'center' ? $style.boxStyle_center:''}`"
    :closeOnClickDimmer="closeOnClickDimmer"
    :opacityAnimate="true"
	>
    <div :class="[$style.headerFilter, {
      [$style.headerFilterRight]: way === 2
    }]">
      <div :class="$style.hiddenLeft" @click="goCaptchaLogin"></div>
      <div :class="$style.hiddenRight" @click="goPasswordLogin"></div>
    </div>
    <div :class="$style.body">
      <div :class="$style.desc">
        <template v-if="way === 1">未注册用户登录后会自动创建账号。为了给您提供更好的服务，请先登录。</template>
        <template v-else>密码登录适用于已设置密码的注册用户。为了给您提供更好的服务，请先登录。</template>
      </div>
      <div :class="$style.inputWrapper">
        <PhoneInput :inputDisabled="isInputDisabled" :needBlock="false" :label="'手机号'" :placeholder="'请输入以1开头的11位数字'" :phone="phone"
          @syncPhoneValidation="syncPhoneValidation" @phoneChange="handlePhoneChange" 
          :showErrorBorder="(way === 2 && (phoneStatus === 2 || phoneStatus === 3 )|| phoneValidVisible)" 
          :showTip="false"></PhoneInput>
        <div v-show="phoneValidVisible" :class="$style.tip">*号码格式错误</div>
        <div v-show="phoneStatus === 2 && way === 2" :class="$style.tip">
          *此账号未设置密码，请使用 <div :class="$style.captchaLoginRoute" @click="goCaptchaLogin">验证码登录</div> 
        </div>
        <div v-show="phoneStatus === 3 && way === 2" :class="$style.tip">
          *此号码未注册，请使用<div :class="$style.captchaLoginRoute" @click="goCaptchaLogin">验证码登录</div> 
        </div>
      </div>
       <div :class="$style.inputWrapper">
        <template v-if="way === 1">
          <FetchCaptcha :isNewStyle="!!origin || colorStyle === 'orange'" :label="'验证码'" :phone="phone" :isWrongCaptcha="isWrongCaptcha" :shouldFetchImmediate="false" @captchaChange="handleCaptchaChange" 
          :showTip="false" :showVoice="false" @touchShowVoice="showVoiceTips"></FetchCaptcha>
          <div v-show="isWrongCaptcha" :class="$style.tip">*验证码不匹配</div>
          <div style="position:relative;">
            <div :class="$style.phone__tips">
              <template v-if="showVoiceBt">
                <template v-if="showResult">
                  <i class="xiaoicon">&#xe760;</i>
                  <span style="margin-left:0.05rem;">语音验证码已发送，请注意接听电话</span>
                </template>
                <template v-else>
                  <span>收不到验证码？试试</span>
                  <a @click.stop="toShowVoice">语音验证码</a>
                </template>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <PasswordInput :label="'密码'" :isWrongPassword="isWrongPassword" @passwordChange="handlePasswordChange"></PasswordInput>
          <div :class="$style.boxPwdTip" v-if="showForGotPwd">
            <div :class="$style.forgetPasswordRoute" @click="goForgetPassword">忘记密码</div>
          </div>
          <div v-show="isWrongPassword" :class="$style.tip">{{pwdErrorTip}}</div>       
        </template> 
        
      </div>
      <button type="button" 
        :class="['btn', $style.btn, canLogin ? '' : $style.disabled, origin ? $style.newStyle : '', colorStyle && $style[`${colorStyle}BtnStyle`]]"
        :disabled="!canLogin" @click="login">
        <span v-if="isLogining">登录中<span :class="$style.ani_dot">...</span></span>
        <span v-else>登&nbsp;录</span>
      </button>
      <div :class="[$style.agreementLine, $style.inBottom]">
        <span :class="$style.agreementDesc">
          登录即代表同意星火网校<a :class="$style.anchor" href="/useragreement">《用户协议》</a>和<a :class="$style.anchor" href="/userprivacy">《隐私协议》</a>
        </span>
      </div>
    </div>
    <NoCaptcha ref="NoCaptcha" :scene="noCaptchaScene" @pass="handlePassNoCatcha"></NoCaptcha>
  </xiao-modal>
</template>
<style lang="scss" module src="./login.module.scss"></style>
<script>
import XiaoModal from 'components/xiao-modal/XiaoModal.vue'
import PhoneInput from 'src/views/account/components/PhoneInput.vue'
import PasswordInput from 'src/views/account/components/PasswordInput.vue'
import FetchCaptcha from 'src/views/account/components/FetchCaptcha.vue'
import { api_passwordLogin, api_getPhoneStatus, api_captchaLogin, api_getVoiceCode, api_editStudentLocation} from 'api/account'
import NoCaptcha from 'src/views/account/components/NoCaptcha.vue'
import globalCookieHandler from 'src/store/cookie'
import afterLoginMixin from "src/vue-utils/mixins/afterLogin.js"
import {
  api_getCityCode,
} from 'src/api/experience-lesson'
export default {
  mixins: [afterLoginMixin],
  components: {
    XiaoModal,
    PhoneInput,
    PasswordInput,
    FetchCaptcha,
    NoCaptcha
  },
  props: {
    link_city_code:{
      type: String,
      default: ''
    },
    link_share_id:{
      type: String,
      default: ''
    },
    origin: {
      type: String,
      default: ''
    },
    visible: {
      typeof: Boolean,
      default: false
    },
    student_phone: {
      typeof: String,
      default: ''
    },
    showCloseIcon: {
      typeof: Boolean,
      default: true
    },
    closeOnClickDimmer: {
      typeof: Boolean,
      default: true
    },
    customize: {
      typeof: Boolean,
      default: false
    },
    showForGotPwd: {
      typeof: Boolean,
      default: false
    },
    // 验证码登录后未设置密码必须设置密码
    loginNeedSetPwd: {
      type: Boolean,
      default: true
    },
    colorStyle: {
      type: String,
    },
    verticalPosition: {
      default: '',
      type: String
    },
    redirectUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 从外部传入一个写死的手机号
    isInputDisabled() {
      return !!this.student_phone
    },
    phoneHasPassword() {
      return this.phoneStatus === 1
    },
    canLogin() { 
      // 密码登录 
      return this.way === 2 ? this.isPhoneValid && this.phoneHasPassword && this.password : this.isPhoneValid && this.isCaptchaValid 
    },
    isCaptchaValid() {
      return this.captcha.length === 4
    },
    innerVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    // 密码状态下
    phoneStatusParams() {
      const ret = {
        phone: this.phone
      }
      if (this.noCaptchaScene === 'other' && this.noCaptchaData.afs_token) {
        ret.afs_token = this.noCaptchaData.afs_token
        ret.afs_sig = this.noCaptchaData.afs_sig
        ret.afs_session_id = this.noCaptchaData.afs_session_id
        ret.afs_scene = this.noCaptchaData.afs_scene
      }
      return ret
    },
    params() {
      const ret = {
        phone: this.phone
      }
      if (this.way === 2) {
        ret.password = this.password
      } else {
        ret.captcha = this.captcha
      }
      // 密码登录下的验证
      // 验证码登录
      if (this.noCaptchaScene === 'login' && this.noCaptchaData.afs_token) {
        ret.afs_token = this.noCaptchaData.afs_token
        ret.afs_sig = this.noCaptchaData.afs_sig
        ret.afs_session_id = this.noCaptchaData.afs_session_id
        ret.afs_scene = this.noCaptchaData.afs_scene
      }
      return ret 
    },
    pwdErrorTip () {
      const { pwdValidTimes } = this
      if (pwdValidTimes <= 0) return ''
      switch (Number.parseInt((pwdValidTimes - 1) / 2)) {
      case 0: return '* 账号或密码错误'
      case 1: return '* 账号或密码错误，请用验证码登录'
      case 2: return '* 账号或密码错误，请找回密码'
      default: return '* 账号或密码错误，请找回密码'
      }
    }
  },  
  data() {
    return {
      way: 1, // 1-验证码 2-密码
      phone: '',
      password: '',
      isLogining: false,
      // 密码登录
      /**
       * 1: 已注册有密码 2: 已注册没密码 3: 没注册（没密码）
       */
      phoneStatus: 1,
      isPhoneValid: false, // 输入格式正确
      phoneValidVisible: false, // 长度正确 但是格式不正确
      // 人机认证
      noCaptchaScene: 'other',
      noCaptchaData: {
        afs_token: '',
        afs_sig: '',
        afs_session_id: '',
        afs_scene: ''
      },
      //密码
      isWrongPassword: false,
      pwdValidTimes: 0,
      // 验证码
      captcha: '',
      isWrongCaptcha: false,
      showVoiceBt: false, // 出现语音验证
      showResult: false, // 语音验证码
      autoVoiceSend: false, //点击语音验证码 然后触发人机验证
    }
  },
  methods: {
    goForgetPassword() {
      let router = {
        name: 'ForgetPassword-phone',
        query: {}
      }
      if (this.phone) {
        router.query.phone = this.phone
      }
      this.$router.push(router)
    },
    toShowVoice() {
      if (!this.isPhoneValid || this.phone.length !== 11) {
        // this.$xiaoToast.warning('请输入正确的手机号')
        this.$xiaoToast.middle['warning']('请输入正确的手机号')
        return null
      }
      api_getVoiceCode(this.phoneStatusParams)
        .then(() => {
          console.log('语言验证码发送成功')
          // this.noCaptchaData.afs_token = '' // 置空可以让滑动验证码重新获取 和登录的不要混淆
          this.showResult = true
        })
        .catch(err => {
          if (err.response && err.response.data.err === 40105) {
            this.autoVoiceSend = true
            this.$refs.NoCaptcha && this.$refs.NoCaptcha.toggleVisible(true)            
          } else if (err.response && err.response.data.err === 40104) {
            if (err.response.data.data.limited_by === 'min') {
              // this.$xiaoToast.warning('')
              this.$xiaoToast.middle['warning']('发送过于频繁，请稍后再试')
            } else if (err.response.data.data.limited_by === 'hour') {
              // this.$xiaoToast.warning('')
              this.$xiaoToast.middle['warning']('发送过于频繁，请在1小时后重试')
            } else if (err.response.data.data.limited_by === 'day') {
              // this.$xiaoToast.warning('')
              this.$xiaoToast.middle['warning']('发送过于频繁，请明天再试')
            }
          } else {
            // this.$xiaoToast.warning('')
            this.$xiaoToast.middle['warning']('未知异常')
          }
        })
    },
    showVoiceTips() {
      // 出现了语音提示
      console.log('触发显示语音验证码')
      if (this.showResult) {
        return null
      }
      if (this.isWrongCaptcha) {
        this.isWrongCaptcha = false
      }
      this.showVoiceBt = true
    },
    handleCaptchaChange(val) {
      this.captcha = val
      this.isWrongCaptcha = false
    },
    // 将获取的学生信息格式化为系统适用的数据结构
    studentFormat(studentInfo) {
      return {
        user_id: studentInfo.id,
        phone: studentInfo.phone,
        name: studentInfo.name,
        school: studentInfo.school,
        source: studentInfo.reg_source,
        reg_time_in_secs: studentInfo.reg_time_sec,
        is_bind_wx_mp: studentInfo.is_bind_wx_mp,
        is_follow_wechat_account: studentInfo.is_follow_wechat_account,
        portrait_url: studentInfo.profile_photo,
        portrait: studentInfo.profile_photo,
        gender: studentInfo.gender.id,
        grade: Number(studentInfo.grade.id),
        province: studentInfo.province_code,
        province_name: studentInfo.province_name,
        city: studentInfo.city_code || studentInfo.province_code,
        city_name: studentInfo.city_name || studentInfo.province_name,
        // 地址只会到城市，为了保证兼容，ding
        district: studentInfo.district_code,
        district_name: studentInfo.district_name,
        remark_name: studentInfo.remark_name,
      }
    },
    afterLoginEditCity(student){
      const info = this.studentFormat(student)
      this.$store.commit('auth/SET_USER_INFO', info)
    },
    // 验证码登录
    captchaLogin() {
      const { loginNeedSetPwd } = this
      this.isLogining = true
      // 添加新的header头 兼容以前逻辑
      const xhcode = sessionStorage.getItem('XHCODE')
      api_captchaLogin(this.params, this.origin, xhcode)
        .then(res => {
          this.isLogining = false
          // student_info_lack 判断资料是否完整，暂时没用上
          const { has_password, student_info_lack, is_new_user, student } = res.data || {}
          this.$globalEventbus.$emit('login:success', is_new_user ? 'captcha-register' : 'captcha-login')
          //抢报绑定城市
          if(is_new_user && this.link_share_id){
            api_getCityCode({
              share_id: this.link_share_id
            }).then(resCity=>{
              api_editStudentLocation({
                city_code: resCity.data.city_code || '440100'
              }).then(res1=>{
                this.afterLoginEditCity(res1.data.student)
              })
            })
          }
          // 是否已有密码或者外部设置不需强制设置密码
          if (has_password || !loginNeedSetPwd) {
            this.customize ? this.$emit('afterLogin', res) : this.afterLogin(res, 'captcha')
          } else {
            globalCookieHandler.token = res.data.token
            globalCookieHandler.user_id = res.data.student.id
            this.$store.commit('auth/SET_AUTH_TOKEN', res.data.token)
            this.$router.push({
              name: 'setting-password',
              query: { student_info_lack, phone: this.phone }
            })
          }
        })
        .catch(err => {
          this.isLogining = false
          if (err.response && err.response.data.err === 40102) {
            console.log('显示验证码错误信息')
            this.isWrongCaptcha = true
            this.showVoiceBt = false
          } else if (err.response && err.response.data.err === 40103) {
            // this.$xiaoToast.warning('')
            this.$xiaoToast.middle['warning']('验证码已失效，请重新获取')
          } else if (err.response && err.response.data.err === 40105) {
            this.noCaptchaScene = 'login'
            this.$refs.NoCaptcha && this.$refs.NoCaptcha.toggleVisible(true)
          }
        })
    },
    // 密码登录
    passwordLogin() {
      this.isLogining = true
      api_passwordLogin(this.params).then(res => {
        this.isLogining = false
        this.$globalEventbus.$emit('login:success', 'password')
        // this.afterLogin(res)
        this.customize ? this.$emit('afterLogin', res) : this.afterLogin(res, 'password')
      }).catch(err => {
        this.isLogining = false
        this.pwdValidTimes++
        if (err.response && err.response.data.err === 40101) {
          this.isWrongPassword = true
          this.$xiaoToast.middle['warning']('账号或密码错误')
        } else if (err.response && err.response.data.err === 40105) {
          this.noCaptchaScene = 'login'
          this.$refs.NoCaptcha && this.$refs.NoCaptcha.toggleVisible(true)
        }
      })
    },
    login() {
      if (!this.isPhoneValid || this.phone.length !== 11) {
        // this.$xiaoToast.warning('')
        this.$xiaoToast.middle['warning']('请输入正确的手机号')
        return 
      }
      if (!this.canLogin) return 
      if (this.way === 1) {
        // 验证码登录
        this.captchaLogin()
      } else if (this.way === 2) {
        //密码登录
        this.passwordLogin()
      }
    },
    goPasswordLogin() {
      // 校验输入
      this.noCaptchaScene = 'other'
      this.autoVoiceSend = false
      this.way = 2
      this.handlePhoneChange(this.phone)
    },
    goCaptchaLogin() {
      //初始化
      this.phoneStatus = 1
      this.autoVoiceSend = false
      this.noCaptchaScene = 'other'
      this.way = 1
    },
    syncPhoneValidation(isPhoneValid, phoneValidVisible) {
      this.isPhoneValid = isPhoneValid
      this.phoneValidVisible = phoneValidVisible
    },
    handlePhoneChange(phone) {
      this.phone = phone
      if (this.way === 2) {
        // console.log(this.isPhoneValid)
        if (this.isPhoneValid && this.phone.length === 11) {
          this.checkPhoneStatus()
        } else {
          this.phoneStatus = 1
        }
      }
    },
    checkPhoneStatus() {
      api_getPhoneStatus(this.phoneStatusParams).then(res => {
        if(!res.data.is_new_user && res.data.has_password) {
          this.phoneStatus = 1
        } else if (!res.data.is_new_user && !res.data.has_password) {
          this.phoneStatus = 2
        } else {
          this.phoneStatus = 3
        }
      }).catch(err => {
        if (err.response && err.response.data.err === 40105) {
          this.noCaptchaScene = 'other'
          this.$refs.NoCaptcha && this.$refs.NoCaptcha.toggleVisible(true)
        }
      })
    },
    // 密码
    handlePasswordChange(val) {
      this.password = val
      this.isWrongPassword = false
    },
    handlePassNoCatcha(data) {
      this.noCaptchaData = data
      if (this.noCaptchaScene === 'login') { // 登录时候 不管是1/2
        this.login()
      } else if (this.way === 2) { //密码登录下 
        this.checkPhoneStatus() //查询手机号状态
      } else if (this.autoVoiceSend) { // 验证码登录下
        this.autoVoiceSend = false
        this.toShowVoice() // 滑动完自动发送
      }
      
    }
  },
  watch: {
    student_phone: {
      handler(val) {  
        this.phone = val || ''
      },
      immediate: true
    },
    visible(val) {
      if (val) {
        const hashIndex = location.href.indexOf('#')
        const redirectUrl = this.redirectUrl || (hashIndex === -1 ? location.href : location.href.slice(0, hashIndex))
        if(redirectUrl) {
          sessionStorage.setItem('afterLoginRoute', redirectUrl)
        }
      }
    }
  }

}
</script>