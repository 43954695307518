import { GIO_PEOPLE_SET } from 'src/enum/gio'
import { api_getCartCount, api_getJointRelated } from 'src/api/shopping-cart'
const state = {
  checked_course_ids: [],
  section_type: '',
  count: 0,
  in_cart_course_ids: [],
  joint: {
    id: '',
    curr_course: '',
    discount_level: [],
    curriculum_data_list: []
  }
}

const mutations = {
  SET_CHECKED_COURSES(state, {courseIds, section_type}) {
    state.checked_course_ids = courseIds
    state.section_type = section_type
  },
  SET_SHOPPINGCART_COUNT(state, { count, curriculum_list}) {
    state.count = count
    state.in_cart_course_ids = curriculum_list || []
  },
  SET_JOINT(state, joint) {
    state.joint = joint
  },
}
const actions = {
  // 设置购物车数量
  getShoppingCartCount: ({ commit }) => {
    return api_getCartCount().then(res => {
      const count = (res && res.data && res.data.count) || 0
      commit('SET_SHOPPINGCART_COUNT', res.data)
      // 埋点
      try {
        if (window.gio) {
          gio('page.set', GIO_PEOPLE_SET.SHOPPINGCART_NUM, count)
        }
      } catch (err) {
        // do nothing
      }
      return count
    })
  },
  // 获取联报关联课程列表
  getJointRelatedCourses: ({ commit }, params) => {
    return api_getJointRelated(params).then(resp => {
      const curriculum_data_list = resp.data.curriculum_data_list || []
      const respData = Object.assign({}, resp.data, {
        curriculum_data_list: curriculum_data_list.map(item => {
          item.checked = false
          return item
        })
      })
      commit('SET_JOINT', respData)
      return respData
    })
  }
}
const getters = {  
  joint_max_discount(state) {
    const levels = state.joint.discount_level
    return (levels && levels.length) ? levels.reduce((pre, next) => {
      return pre.level > next.level ? pre : next
    }).discount : 0
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
