import globalCookieHandler from 'src/store/cookie'
import { api_authLogin, api_getMyInfo } from 'src/api/auth'
import { api_getStudentInfo } from 'api/account'
import { GIO_PEOPLE_SET } from 'src/enum/gio'
import store from 'src/vuex/store'
import { checkCity } from 'components/xiao-city-picker'
import * as Sentry from '@sentry/browser'

const peopleSetInfoSources = {
  '1': '用户注册',
  '2': '运营系统注册',
  '3': 'boss系统注册'
}
const peopleSetInfoClassStatus = {
  '1': '班课注册学员',
  '2': '活动课学员',
  '3': '正价课在读/待读',
  '4': '正价课已结课'
}
const peopleSetInfoOtoStatus = {
  '1': '一对一已缴费在读',
  '2': '一对一已上试听课',
  '3': '一对一已排试听课',
  '4': '一对一已申请试听',
  '5': '一对一注册学员'
}

export const codeLogin = ({ commit }, params) => {
  return api_authLogin(params).then((res) => {
    checkCity(true)
    globalCookieHandler.token = res.data.token
    globalCookieHandler.user_id = res.data.student.id
    commit('SET_AUTH_TOKEN', res.data.token)
    return res.data
  })
}

/**
 * 为了改新接口，将新接口的字段映射到旧接口
 */
function studentFormat(studentInfo) {
  // 能用studentInfo就不用myInfo
  // myInfo退化成不包含基本信息的功能接口
  let ret = {
    // reg_time_in_secs: myInfo.reg_time_in_secs,
    // class_status: myInfo.class_status,
    // is_paid: myInfo.is_paid,
    // unpaid_multi_list: myInfo.unpaid_multi_list,

    user_id: studentInfo.id,
    phone: studentInfo.phone,
    name: studentInfo.name,
    school: studentInfo.school,
    source: studentInfo.reg_source,
    reg_time_in_secs: studentInfo.reg_time_sec,

    is_bind_wx_mp: studentInfo.is_bind_wx_mp,
    is_follow_wechat_account: studentInfo.is_follow_wechat_account,
    city_location_is_municipality: studentInfo.city_location_is_municipality,
    portrait_url: studentInfo.profile_photo,
    portrait: studentInfo.profile_photo,
    gender: studentInfo.gender.id,
    grade: Number(studentInfo.grade.id),
    province: studentInfo.province_code,
    province_name: studentInfo.province_name,
    city: studentInfo.city_code || studentInfo.province_code,
    city_name: studentInfo.city_name || studentInfo.province_name,
    // 地址只会到城市，为了保证兼容，ding
    district: studentInfo.district_code,
    district_name: studentInfo.district_name,
    remark_name: studentInfo.remark_name
  }
  // if (studentInfo.location.id && studentInfo.location.id.slice(4, 6) !== '00') {
  //   ret.district = studentInfo.location.id
  // } else {
  //   ret.district = ''
  // }
  return ret
}

export const getUserBasicInfo = ({ commit }) => {
  return api_getStudentInfo()
    .then((res) => {
      const userInfo = studentFormat(res.data.student)
      try {
        if (window.gio && globalCookieHandler.user_id) {
          gio('setUserId', globalCookieHandler.user_id)
          const peopleSetInfo = {}
          if (userInfo.name) {
            peopleSetInfo[GIO_PEOPLE_SET.NAME] = userInfo.name
          }
          if (userInfo.grade) {
            peopleSetInfo[GIO_PEOPLE_SET.GRADE] = res.data.student.grade.zh_cn
          }
          if (userInfo.source) {
            peopleSetInfo[GIO_PEOPLE_SET.SOURCE] =
              peopleSetInfoSources[userInfo.source]
          }
          gio('people.set', peopleSetInfo)
        }
        if (__USE_SENTRY_ALERT__ && globalCookieHandler.user_id) {
          Sentry.setUser({
            id: globalCookieHandler.user_id,
            username: userInfo.name
          })
        }
      } catch (err) {
        // do nothing
        if (__USE_SENTRY_ALERT__ && err) {
          Sentry.captureException(err)
        }
      }
      commit('SET_USER_INFO', userInfo)
      store.dispatch('course/getCity')
      return userInfo
    })
    .catch((err) => {
      if (err.response && err.response.status === 429) {
        throw err
      } else {
        commit('SET_AUTH_TOKEN', null)
        commit('SET_USER_INFO', {})
        throw err
      }
    })
}

export const getUserExtraInfo = () => {
  return api_getMyInfo().then((res) => {
    let myInfo = res.data
    try {
      if (window.gio && globalCookieHandler.user_id) {
        gio('setUserId', globalCookieHandler.user_id)
        const peopleSetInfo = {}
        if (myInfo.class_status) {
          peopleSetInfo[GIO_PEOPLE_SET.CLASS_STATUS] =
            peopleSetInfoClassStatus[myInfo.class_status]
        }
        peopleSetInfo[GIO_PEOPLE_SET.IS_PAID] = myInfo.is_paid
          ? '付费学员'
          : '非付费学员'
        gio('people.set', peopleSetInfo)
      }
    } catch (err) {
      // do nothing
    }
    /**
     * myinfo 只返回以下4个字段:
     *
     * class_status
     * is_paid
     * unpaid_multi_list
     * student_num
     *
     * 其中
     * 1. class_status 和 is_paid只在gio统计里用
     * 2. unpaid_multi_list 和 student_num放到vuex里
     *
     */

    const hasOrder =
      myInfo.unpaid_multi_list && myInfo.unpaid_multi_list.length > 0
    store.commit('auth/SET_UNPAID_MULTI_LIST', myInfo.unpaid_multi_list || [])
    store.commit('order/SET_IS_HAS_UNPAID_ORDER', hasOrder)
    store.commit('auth/SET_STUDENT_NUM', myInfo.student_num)
    store.commit(
      'auth/SET_EDIT_LOCATION_LIMIT',
      myInfo.edit_location_limit || {}
    )
  })
}

export const getUserInfo = ({ commit }) => {
  return Promise.all([getUserExtraInfo(), getUserBasicInfo({ commit })])
}

export const setInitUserInfoStatus = ({ commit }, param) => {
  commit('SET_INIT_USERINFO_STATUS', param)
}
