import * as actions from 'vux/actions/auth'
import { auth } from 'utils/local'

const state = {
  user: {},
  authToken: auth(),
  hasInitUserInfo: false,
  //姓名、年级、城市是不是完整，不是就要去完善资料
  infoCompleted: true,
  unPaidMultiList: [],
  studentNum: 1,
  // 编辑生源地信息
  editLocationLimit: {}
}

const mutations = {
  SET_USER_INFO(state, user) {
    state.user = user
    if (user.name && user.grade && user.province) {
      state.infoCompleted = true
    } else {
      state.infoCompleted = false
    }
  },
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token
  },
  SET_INIT_USERINFO_STATUS(state, status) {
    state.hasInitUserInfo = status
  },
  SET_STUDENT_NUM(state, num) {
    state.studentNum = num
  },
  SET_UNPAID_MULTI_LIST(state, list) {
    state.unPaidMultiList = list
  },
  SET_IS_FOLLOW_WECHAT_ACCOUNT(state, bind) {
    state.user.is_follow_wechat_account = bind
  },
  SET_EDIT_LOCATION_LIMIT(state, limit) {
    state.editLocationLimit = limit
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
