// 埋点相关的方法
const randomMeta = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
  "U", "V", "W", "X", "Y", "Z",
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j",
  "k", "l", "m", "n", "o", "p", "q", "r", "s", "t",
  "u", "v", "w", "x", "y", "z"]

export const createRandomStr = length => {
  let randomString = ''
  for (let i = 0; i < length; ++i) {
    const idx = Math.floor(62 * Math.random())
    randomString += randomMeta[idx]
  }
  return randomString
}