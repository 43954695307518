// 这是基于 xhwx-app-sdk 的封装，你可以直接使用 xhwx-app-sdk 但是这个封装方法可以让你在 Vue 组件内通过 this.$app 调用，而且调用参数更直接
import sdk from 'xhwx-app-sdk'

export default {
  install(Vue) {
    Vue.prototype.$app = {
      /**
       * 打开一个新的页面
       * @param {string} url 跳转页面地址
       */
      open(url) {
        if (!/^https?:\/\//.test(url)) {
          url = `${location.protocol}//${location.host}${url}`
        }
        sdk.dispatch(sdk.ACTIONS.OPEN, { url })
      },
      /**
       * 配置分享
       * @param {{
       * title: string,
       * desc: string,
       * imgUrl: string,
       * shareUrl: string
       * }} shareInfo 
       */
      configShare(shareInfo) {
        sdk.dispatch(sdk.ACTIONS.CONFIG_SHARE, shareInfo)
      },
      /**
       * 配置分享
       * @param {{
       * title: string,
       * desc: string,
       * imgUrl: string,
       * shareUrl: string
       * }} shareInfo 
       */
      configShareGroup(shareInfo) {
        sdk.dispatch(sdk.ACTIONS.CONFIG_SHARE_GROUP, shareInfo)
      },
      /**
       * 跳转到选课中心
       */
      navToChooseCourse() {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_CHOOSE_COURSE)
      },
      /**
       * 更新购物车数字提醒
       * @param {number} count 
       */
      updateShoppingCartCount(count) {
        sdk.dispatch(sdk.ACTIONS.UPDATE_SHOPPING_CART_COUNT, { count })
      },
      /**
       * 登录
       * @param {string} redirect_url 回跳页面
       */
      login(redirect_url) {
        sdk.dispatch(sdk.ACTIONS.LOGIN, { redirect_url })
      },
      /**
       * 滑块验证结果传给APP
       * @param {*} data 
       */
      getSlideData(result) {
        sdk.dispatch(sdk.ACTIONS.GET_SLIDE_DATA, result)
      },
      /**
       * 分享图片
       * @param {{
       * title: string,
       * desc: string,
       * imageData: string
       * }} shareInfo 
       */
      shareImage(shareInfo) {
        sdk.dispatch(sdk.ACTIONS.SHARE_IMAGE, shareInfo)
      },
      /**
       * 新页面打开课程详情
       * @param {*} detail 
       */
      navToCourseDetail(detail) {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_COURSE_DETAIL, detail)
      },
      /**
       * 跳转回看页面
       * @param {string} lesson_id 课次ID
       */
      navToPlayback(lesson_id) {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_PLAYBACK, { lesson_id })
      },
      /**
       * 购物车显示/隐藏
       * @param {boolean} visible 显示/隐藏
       */
      shoppingCartVisible(visible) {
        sdk.dispatch(sdk.ACTIONS.SHOPPING_CART_VISIBLE, { visible })
      },
      /**
       * 跳转苹果支付
       * @param {boolean} isPriceEnough ？？？这个参数有待考究
       */
      appPay(isPriceEnough) {
        sdk.dispatch(sdk.ACTIONS.APPLE_PAY, { isPriceEnough })
      },
      /**
       * 跳到原生的“我的”
       */
      navToMyCenter() {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_MY_CENTER)
      },
      /**
       * 打电话
       * @param {string} phone 电话号码
       */
      phoneCall(phone) {
        sdk.dispatch(sdk.ACTIONS.PHONE_CALL, { phone })
      },
      /**
       * 跳转原生的直播间
       * @param {string} lesson_id 课次ID
       */
      navToLiveroom(lesson_id) {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_LIVEROOM, { lesson_id })
      },
      /**
       * 跳转到我的课程
       */
      navToMyCurriculum() {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_MY_CURRICULUM)
      },
      /**
       * 支付成功
       */
      navToPaySuccess() {
        sdk.dispatch(sdk.ACTIONS.NAV_TO_PAY_SUCCESS)
      },
      /**
       * 打开微信
       */
      gotoWechat() {
        sdk.dispatch(sdk.ACTIONS.GOTO_WECHAT)
      }
    }
  }
}
