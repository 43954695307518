exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.toastContainer_2wkbG[data-v-86b6ccf4]{background:#5a5a5a;border-radius:.32rem;padding:.32rem .42666667rem;position:fixed;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);top:20px;z-index:1001\n}\n.toastContainer_2wkbG .icon-success_2lPqR[data-v-86b6ccf4]{color:#00c55d\n}\n.toastContainer_2wkbG .icon-success_2lPqR[data-v-86b6ccf4]::after{content:\"\\E670\"\n}\n.toastContainer_2wkbG .icon-warning_UfSA8[data-v-86b6ccf4]{color:#fd7663\n}\n.toastContainer_2wkbG .icon-warning_UfSA8[data-v-86b6ccf4]::after{content:\"\\E672\"\n}\n.toastContainer_2wkbG .msg_y-hFe[data-v-86b6ccf4]{color:#fff\n}", ""]);

// exports
exports.locals = {
	"toastContainer": "toastContainer_2wkbG",
	"icon-success": "icon-success_2lPqR",
	"icon-warning": "icon-warning_UfSA8",
	"msg": "msg_y-hFe"
};