import axios from 'axios'

const templateBaseURL = __TARGET__ === 'prod' ? 'https://resource-teaching.xiaojiaoyu100.com' : 'https://test-resource-teaching.xiaojiaoyu100.com'

export default () => {
  const http = axios.create({
    baseURL: templateBaseURL
  })
  const params = {
    _: Date.now()
  }
  return http.get('/que_template.json', { params })
    .then(resp => {
      const template  = resp.data.template_default
      loadFile([template.answer, template.option, template.stem])
    })
}

const loadFile = (template) => {
  const patt = /<link rel="stylesheet" href=["']([^"]*)["']([^>]*)>/g
  let temp, result = []

  template.forEach(item => {
    while( (temp= patt.exec(item)) != null ) {
      result.push(temp[1])
    }
  })

  result = [...new Set(result)] // 去重

  result.forEach(item => {
    const link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('href', item)
    document.head.appendChild(link)
  })
}
