export default [
  {
    path: '/experience-lesson/:plan_id',
    name: 'experience-lesson',
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/experience-lesson/detail/:id',
    name: 'experience-lesson-detail',
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/newDetail/ExperienceLessonDetail.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/group-buying/:group_buying_id/:level_id',
    name: 'experience-lesson-group-buying',
    meta: {
      title: '选择拼团课程'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/groupbuying/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/order-system/:activity_id',
    name: 'experience-lesson-order-system',
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/ordersystem/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/new-order-system/:activity_id',
    name: 'experience-lesson-new-order-system',
    meta:{
      title:'来星火网校，暑假7天一起学！'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/ordersystem/new/index/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/order-system-award',
    name: 'order-system-award',
    meta:{
      title:'来星火网校，暑假7天一起学！'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/ordersystem/new/award/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/order-confirm',
    name: 'experience-lesson-order-confirm',
    meta:{
      title:'星火网校-确认订单信息'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(require('src/views/experience-lesson/ordersystem/orderconfirm/index.vue'))
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/friends-generalize/:curriculum_id',
    name: 'experience-lesson-friends-generalize',
    meta: {
      title: '星火网校'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/friendsgeneralize/index.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/checkout/friends-generalize-order-:curriculum_id',
    name: 'experience-lesson-friends-generalize-order',
    meta: {
      title: '星火网校-确认订单信息'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/friendsgeneralize/order.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/friends-generalize/detail/:curriculum_id',
    name: 'experience-lesson-friends-generalize-detail',
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/friendsgeneralize/detail.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/exprience-lesson/order-system-activity/:agent_activity_id',
    name: 'experience-lesson-order-system-activity',
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/ordersystem/activity.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/experience-lesson/experience-courses/:activity_id/:lesson_id',
    name: 'experience-lesson-experience-courses',
    meta: {
      title: '星火网校'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/experiencecourses/index.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
  {
    path: '/experience-lesson/fund/invite',
    name: 'experience-lesson-invitefund',
    meta: {
      title: '邀好友 返学费'
    },
    component: (resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(
            require('src/views/experience-lesson/inviteFund/inviteFund.vue')
          )
        },
        'experience-lesson'
      )
    }
  },
]
