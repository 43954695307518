export default <template>
<div :class="$style.wrapper">
  <label :class="$style.label" v-if="!!this.label" @click="inputFocus">{{this.label}}</label>
  <input 
    type="password" 
    ref="input" 
    :value="password"
    @input="handlePasswordChange" 
    maxlength="16" 
    :placeholder="placeholder" 
    :class="[$style.passwordInput, isWrongPassword || isPasswordInValid ? $style.needTip : '', !!this.label ? $style.padding : '', this.$UA.isIos ? $style.iosInput : '']"
    >

  <div :class="$style.right">
    <div v-if="hidePassword" :class="$style.hidePasswordIcon" @click="toggleHidePassword(false)" class="xiaoicon">&#xe73a;</div>
    <div v-else :class="$style.hidePasswordIcon" class="xiaoicon" @click="toggleHidePassword(true)">&#xe742;</div>
    <div v-show="password.length" @click="handleClear" :class="$style.closeIcon" class="xiaoicon">&#xe686;</div>
  </div>
  <div :class="$style.bottom" v-if="isPasswordInValid">
    *密码为8-16位，至少含数字/字母/字符中的2种
  </div>
</div>

</template>

<style lang="scss" module src="./PasswordInput.module.scss"></style>

<script>

export default {
  props: {
    placeholder: {
      type: [String,Boolean],
      default: '请输入登录密码'
    },
    isWrongPassword: {
      type: Boolean,
      default: false
    },
    isPasswordInValid: {
      type: Boolean,
      default: false
    },
    label:{
      default: '',
      type: String,
    }
  },
  data() {
    return {
      password: '',
      isPasswordCorrect: true,
      hidePassword: true
    }
  },
  methods: {
    handlePasswordChange($event) {
      this.password = $event.target.value
      this.$emit('passwordChange', this.password)
    },                  
    handleClear() {
      this.password = ''
      this.$emit('passwordChange', this.password)
      this.isPasswordCorrect = true
      this.$refs.input.focus()
    },
    toggleHidePassword(val) {
      this.hidePassword = val
      if (this.hidePassword) {
        this.$refs.input.type = 'password'
      } else {
        this.$refs.input.type = 'text'
      }
    },
    inputFocus(){
      this.$refs.input.focus()
    }
  }
}
</script>