const state = {
  isKicked: false,
  isBought: false,
  isNIMConnected: false,
  isChatroomConnected: false,
  isMute: false,
  isChatroomMute: false,
  liveroomId: '',
  yunxin: {
    chatroomId: '',
    userId: '',
    userToken: ''
  },
}

const mutations = {
  setIsLessonEnd(state, { is_lesson_end }) {
    state.is_lesson_end = is_lesson_end
  },
  SET_TOKEN_REQUEST_ID(state, { id }) {
    state.tokenRequestId = id
  },
  SET_LIVEROOM_INFO(state, { liveroomInfo }) {
    state.yunxin = {
      userId: liveroomInfo.yunxin_user_id,
      userToken: liveroomInfo.yunxin_token,
      chatroomId: liveroomInfo.chatroom_id
    }
    state.liveroomId = liveroomInfo.liveroom_id
  },
  KICK(state) {
    state.isKicked = true
  },
  SET_NIM_CONNECTED(state, { connected }) {
    state.isNIMConnected = connected
  },
  SET_CHATROOM_CONNECTED(state, { connected }) {
    state.isChatroomConnected = connected
  },
  SET_NIM_MUTE(state, { mute }) {
    state.isMute = mute
  },
  SET_CHATROOM_MUTE(state, { mute }) {
    state.isChatroomMute = mute
  },
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
