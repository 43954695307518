import Vue from "vue"
import msgboxVue from "./main.vue"

let instance
const MessageBoxConstructor = Vue.extend(msgboxVue)

const initInstance = () => {
  instance = new MessageBoxConstructor({
    el: document.createElement("div")
  })
}

const initBoxData = options => {
  const { onClose } = options
  instance.closeCallback = onClose
  instance.updateText(options)
}

const showMsg = () => {
  if (!instance) {
    initInstance()
  }
  document.body.appendChild(instance.$el)
  instance.visible = true
}
const MessageBox = function(options, callback) {
  showMsg()
  initBoxData(options)
}

export default MessageBox
export { MessageBox }
