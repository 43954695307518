import http from 'utils/http'
import { uuidv4 } from 'utils/fastuuid'
import { signatureGenerator } from 'utils/index'
import { activityShareSession } from 'src/store/session'
/**
 * [获取secret/key]()
 *
 * @param {phone}
 */
export function api_getSecretKey(params) {
  const url = 'dorado/api/v1.1/api/access/secret/key'
  return http.get(url, { params })
}

// 签名算法测试接口
export async function api_signatureTestEndpointer(params) {
  const timestamp = Math.round(new Date().getTime() / 1000)
  const nonce = uuidv4()
  const secretKeyRes = await api_getSecretKey()
  const secret_key = secretKeyRes.data.secret_key

  const signature = signatureGenerator({
    secret_key,
    nonce,
    timestamp,
    ...params
  })
  const req = {
    nonce,
    timestamp,
    signature
  }
  const url = '/turbot/api/debug/check/api/access'
  return http.get(url, { params: { ...params, ...req } })
}

/**
 * [获取短信验证码](http://yapi.xhwx100.com/project/74/interface/api/7522)
 *
 * @param {phone}
 */
export async function api_getCaptcha(params) {
  const timestamp = Math.round(new Date().getTime() / 1000)
  const nonce = uuidv4()
  const secretKeyRes = await api_getSecretKey()
  const secret_key = secretKeyRes.data.secret_key

  const signature = signatureGenerator({
    secret_key,
    nonce,
    timestamp,
    ...params
  })
  const req = {
    nonce,
    timestamp,
    signature
  }
  const url = '/dorado/api/v1.1/captcha'
  return http.get(url, { params: { ...params, ...req } })
}

/**
 * [密码登录&验证码登录](http://yapi.xhwx100.com/project/74/interface/api/7502)
 *
 * @param {phone}
 * @param {password}
 * @param {captcha}
 */
export function api_passwordLogin(params) {
  const url = '/dorado/api/v1.0/login/password'
  return http.post(url, params)
}

/**
 * [抢报密码登录](http://yapi.xhwx100.com/project/74/interface/api/7502)
 *
 * @param {phone}
 * @param {password}
 * @param {captcha}
 */
export function api_ordersystem_passwordLogin(params, origin = '', xhcode = 0) {
  const from_code = sessionStorage.getItem('register_from')
  const url = '/dorado/api/v1.0/login/password'
  const headers = {}
  if (origin) {
    headers['Xiao-From-Advert'] = origin
  }
  if (xhcode) {
    headers['Xiao-Reg-Src'] = Number(xhcode)
  }
  if (Number(from_code) === 2) {
    headers['Xiao-From-Channel'] = 'grouping_for_student'
  }
  const headersKey = Object.keys(headers)
  return headersKey.length
    ? http.post(url, params, { headers })
    : http.post(url, params)
}

/**
 * [验证码登录](http://yapi.xhwx100.com/project/74/interface/api/8279)
 *
 * @param {phone}
 * @param {password}
 * @param {captcha}
 */
export function api_captchaLogin(params, origin = '', xhcode = 0) {
  const from_code = sessionStorage.getItem('register_from')
  const fromSession = activityShareSession.getActivityShareParams()
  const url = '/dorado/api/v1.0/login/captcha'
  const headers = {}
  if (origin) {
    headers['Xiao-From-Advert'] = origin
  }
  if (xhcode) {
    headers['Xiao-Reg-Src'] = Number(xhcode)
  }
  if (fromSession && fromSession.share_id) {
    headers['XHWX-Share-ID'] = fromSession.share_id
  }

  if (Number(from_code) === 2) {
    headers['Xiao-From-Channel'] = 'grouping_for_student'
  }
  const headersKey = Object.keys(headers)
  return headersKey.length
    ? http.post(url, params, { headers })
    : http.post(url, params)
}

/**
 * [获取手机注册/密码状态](http://yapi.xhwx100.com/project/74/interface/api/7727)
 *
 * @param {phone}
 */
export function api_getPhoneStatus(params) {
  const url = '/dorado/api/v1.0/phone/status'
  return http.get(url, { params })
}

/**
 * [忘记密码/设置密码并登录](http://yapi.xhwx100.com/project/74/interface/api/7512)
 *
 * @param {phone}
 * @param {password}
 * @param {captcha}
 */
export function api_setPassword(params) {
  const url = '/dorado/api/v1.0/forget/password'
  return http.post(url, params)
}

/**
 * 设置密码
 * @param {} params
 */
export function setPassword(params) {
  const url = `/turbot/api/v1.0/user/password`
  return http.post(url, params)
}

/**
 * [微信授权登录](http://yapi.xhwx100.com/project/74/interface/api/7512)
 *
 * @param {phone}
 * @param {password}
 * @param {captcha}
 */
export function api_wechatOauthLogin(params) {
  const url = '/dorado/api/v1.0/login/wechat/oauth'
  return http.post(url, params)
}

/**
 * [获取学生列表](http://yapi.xhwx100.com/project/74/interface/api/7537)
 *
 */
export function api_getUserStudentList() {
  const url = '/turbot/api/v1.0/user/student/list'
  return http.post(url)
}

/**
 * [切换当前学生](http://yapi.xhwx100.com/project/74/interface/api/7672)
 *
 *  @param {new_student_id}
 */
export function api_switchStudent(params) {
  const url = '/dorado/api/v1.0/user/current/student'
  return http.post(url, params)
}

/**
 * [修改学生信息](http://yapi.xhwx100.com/project/74/interface/api/7597)
 *
 *  @param {name}
 *  @param {grade}
 */
export function api_editStudentInfo(params) {
  const url = '/turbot/api/v1.0/student/info'
  return http.post(url, params)
}

/**
 * [修改学生所在城市](http://yapi.xhwx100.com/project/74/interface/api/7642)
 *
 *  @param {location_id}
 */
export function api_editStudentCity(params) {
  const url = '/turbot/api/v1.0/student/location'
  return http.post(url, params)
}

/**
 * [修改学生生源地](http://yapi.xhwx100.com/project/11/interface/api/28708)
 *
 *  @param {city_location_id}
 */
export function api_editStudentLocation(params) {
  const url = '/koala/api/v1.5/personal_center/set-info'
  return http.post(url, params)
}

/**
 * [微信号绑定手机号](http://yapi.xhwx100.com/project/74/interface/api/7607)
 *
 *  @param {union_id}
 *  @param {open_id}
 *  @param {force_bind}
 *  @param {phone}
 *  @param {captcha}
 */
export function api_wechatBindPhone(params, xhcode = 0) {
  const headers = {}
  const fromSession = activityShareSession.getActivityShareParams()
  if (xhcode) {
    headers['Xiao-Reg-Src'] = Number(xhcode)
  }
  if (fromSession && fromSession.share_id) {
    headers['XHWX-Share-ID'] = fromSession.share_id
  }
  const url = '/dorado/api/v1.0/login/wechat/bind/phone'
  const headersKey = Object.keys(headers)
  return headersKey.length
    ? http.post(url, params, { headers })
    : http.post(url, params)
}

/**
 * [微信号强制绑定手机号](http://yapi.xhwx100.com/project/74/interface/api/8881)
 *
 *  @param {union_id}
 *  @param {open_id}
 *  @param {force_bind}
 *  @param {phone}
 *  @param {captcha}
 */
export function api_wechatForceBindPhone(params) {
  const url = '/dorado/api/v1.0/login/wechat/force/bind/phone'
  const fromSession = activityShareSession.getActivityShareParams()
  const headers = {}
  if (fromSession && fromSession.share_id) {
    headers['XHWX-Share-ID'] = fromSession.share_id
  }
  return http.post(url, params, { headers })
}

/**
 * [AuthCode登录](http://yapi.xhwx100.com/project/74/interface/api/7862)
 *
 *  @param {auth_code}
 */
export function api_authCodeLogin(params) {
  const url = '/dorado/api/v1.0/login/authcode'
  return http.post(url, params)
}

/**
 * [学生基本信息](http://yapi.xhwx100.com/project/74/interface/api/7592)
 *
 */
export function api_getStudentInfo() {
  const url = '/turbot/api/v1.0/student/info'
  return http.get(url)
}

// [个人资料获取学员信息][http://yapi.xhwx100.com/project/74/interface/api/7592]
export function api_getCurrStudentInfo() {
  const url = '/turbot/api/v1.0/student/info'
  return http.get(url)
}

/**
 * [语言验证码 http://yapi.xhwx100.com/mock/74/dorado/api/v1.0/voice/captcha)
 * @param  phone 必须
 */
export async function api_getVoiceCode(params) {
  const timestamp = Math.round(new Date().getTime() / 1000)
  const nonce = uuidv4()
  const secretKeyRes = await api_getSecretKey()
  const secret_key = secretKeyRes.data.secret_key

  const signature = signatureGenerator({
    secret_key,
    nonce,
    timestamp,
    ...params
  })
  const req = {
    nonce,
    timestamp,
    signature
  }

  const url = '/dorado/api/v1.1/voice/captcha'
  return http.get(url, { params: { ...params, ...req } })
}

/**
 * [获取用户微信号](http://yapi.xhwx100.com/project/74/interface/api/8062)
 */
export function api_getWxUnionId() {
  const url = '/turbot/api/v1.0/user/wxunionid'
  return http.get(url)
}

/**
 * [提现实名认证](http://yapi.xhwx100.com/project/11/interface/api/22107)
 */
export function api_getCertificate(params) {
  const url = '/koala/api/v1.5/account/withdraw/cert'
  return http.get(url, { params })
}

/**
 * [微信游客登录](http://yapi.xhwx100.com/project/74/interface/api/23393)
 * @param {String} code 微信授权code
 */
export function api_wechatTouristLogin(params, origin = '', xhcode = 0) {
  const url = '/dorado/api/v1.0/anonymous/login/wechat/oauth'
  const from_code = sessionStorage.getItem('register_from')
  const headers = {}
  if (origin) {
    headers['Xiao-From-Advert'] = origin
  }
  if (xhcode) {
    headers['Xiao-Reg-Src'] = Number(xhcode)
  }
  if (Number(from_code) === 2) {
    headers['Xiao-From-Channel'] = 'grouping_for_student'
  }
  const headersKey = Object.keys(headers)
  return headersKey.length
    ? http.post(url, params, { headers })
    : http.post(url, params)
}

/**
 * [微信游客登录绑定手机]
 */
export function api_bindAnonymousPhone(params) {
  const url = '/dorado/api/v1.0/anonymous/wechat/bind/phone'
  const fromSession = activityShareSession.getActivityShareParams()
  const headers = {}
  if (fromSession && fromSession.share_id) {
    headers['XHWX-Share-ID'] = fromSession.share_id
  }
  return http.post(url, params, { headers })
}

/**
 * [账号是否被禁止提现](http://yapi.xhwx100.com/project/11/interface/api/31112)
 */
export function api_isBannedAccount() {
  const url = '/koala/api/v1.5/account/is/banned'
  return http.get(url)
}