import * as actions from 'vux/actions/order'

const state = {
  confirmInfo: {},
  couponInfo: {},
  valid: {},
  chosen: {},
  order: {},
  tutor: [],

  // 收货地址（下单前填写的收货地址，与具体订单无关）
  consignee: {
    phone: '',
    name: '',
    province: '',
    city: '',
    district: '',
    address: ''
  },
  // 是否显示收货地址
  showConsignee: false,
  isHasUnpayOrder: false,
  consignee_list: []
}

const mutations = {

  // 设置收货地址
  SET_ORDER_CONSIGNEE(state, { consignee }) {
    state.consignee = consignee
  },
  SET_CONFIRM_INFO(state, info) {
    state.confirmInfo = info
  },

  SET_COUPON_LIST(state, coupon) {
    const { coupon_list } = coupon
    state.couponInfo = {
      total: coupon_list.length,
      coupon_list
    }
  },

  SET_VALID_COUPON(state, valid) {
    state.valid = valid
  },

  CHOOSE_COUPON(state, coupon) {
    state.chosen = coupon
  },

  SET_ORDER_DETAIL(state, order) {
    state.order = order
  },

  SET_CONSIGNEE(state, consignee) {
    state.consignee = consignee
  },
	
  SET_CHOSEN_TUTOR(state, data) {

    let [id, tutor] = data

    let _curriculum_to_change_tutor = state.confirmInfo.curricula.filter(item => item.curriculum_id === id)[0]
		
    Object.assign(_curriculum_to_change_tutor, {
      class_id: tutor.class_id,
      class_alias: tutor.class_alias,
      tutor_id: tutor.tutor_id,
      tutor_name: tutor.name
    })
  },
  SET_IS_HAS_UNPAID_ORDER (state, status) {
    state.isHasUnpayOrder = status
  },
  // 显示收货地址信息
  SET_ORDER_SHOWCONSIGNEE(state, showConsignee) {
    state.showConsignee = showConsignee
  },

  SET_USER_CONSIGNEE_LIST(state, data) {
    state.consignee_list = data
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
