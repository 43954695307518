// 支持重试的异步
import { deepGet } from './lang'

const defaults = {
  delay: 3000,
  max: Infinity
}

export function delayPromise(delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

function getRandomBetween(start, end) {
  const dist = end - start
  return start + Math.random() * dist
}

export function retryPromise(originPromise, options) {
  options = Object.assign({}, defaults, options)
  let retryCount = 0
  function retryPromise() {
    return originPromise().catch(error => {
      retryCount++
      if (retryCount >= options.max) {
        throw error
      } else {
        if (options.onReject) {
          options.onReject(error)
        }
        let delay = options.delay
        if (Array.isArray(delay)) {
          delay = getRandomBetween(...delay)
        }
        return delayPromise(options.delay).then(retryPromise)
      }
    })
  }
  return retryPromise()
}

class RetryPromiseException {
  constructor({message='', code=1}) {
    this._code = code
    this._message = message
  }

  get name() {
    return 'RetryPromiseException'
  }

  get code() {
    return this._code
  }

  get message() {
    return this._message
  }

  toString() {
    return `RetryPromiseException: ${this._message}`
  }
}

export function retryPromiseControl(runOnce) {
  let tryCount = 0
  function tryAgain({ delay, max }) {
    tryCount += 1
    max = max || Infinity
    if (tryCount >= max) {
      throw new RetryPromiseException({
        message: 'retry too many times',
        code: 1,
      })
    }
    let delayMS = Array.isArray(delay) ? getRandomBetween(...delay) : delay
    console.log(`下次重试时间: ${delayMS}`)
    return delayPromise(delayMS).then(() => {
      return runOnce(tryAgain)
    })
  }
  return runOnce(tryAgain)
}

/**
 * 针对429重试的http请求包装器
 * @param {Promise} httpRequestPromise
 * @param {{delay: number, max: number}} retryOptions
 */
export function retryHttp429(httpRequestPromise, retryOptions) {
  return function retryHttpRequest(params, options) {
    return retryPromiseControl(tryAgain => {
      return httpRequestPromise(params, options).catch(error => {
        if (deepGet(error, ['response', 'status']) === 429) {
          return tryAgain(retryOptions)
        } else {
          throw error
        }
      })
    })
  }
}