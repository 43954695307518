// 下单相关
import http from 'utils/http'
import { stringify } from 'xiao-utils/qs'
import { retryHttp429 } from 'utils/promisesRetry'

// 获取课程（和班级）信息
export function api_getOrderCurricula(params) {
  const url = '/koala/api/v1.5/curriculum/choose-class'
  // 429 重试： 后台自动增班逻辑
  return retryHttp429(http.post, { delay: [500, 4] })(url, params)
}

// 获取收货地址
export function api_getOrderConsignee() {
  const url = '/koala/api/v1.5/curriculum/consignee-query'
  return http.get(url)
}

// 获取优惠券
export function api_getOrderCoupon(params) {
  const url = '/koala/api/v1.5/coupon/query'
  return http.post(url, params)
}

// 获取价格信息
export function api_getOrderPrice(params) {
  const url = '/koala/api/v1.5/curriculum/price-info'
  return http.post(url, params)
}

// 获取代理商价格信息
export function api_getAgentOrderPrice(params) {
  const url = '/koala/api/v1.7/agent-activity/price-info'
  return http.post(url, params)
}

// 订单绑定活动
export function api_bindAgentActivityOrder(params) {
  const url = '/koala/api/v1.7/agent-activity/bind-order'
  return http.post(url, params)
}

// 获取预定商品价格信息
export function api_getPrestoreOrderPrice(params) {
  const url = '/koala/api/v1.7/prestore/price-info'
  return http.post(url, params)
}

// 营销体验课下单
export function api_experiencePostOrder(params) {
  const url = '/koala/api/v1.7/special-curriculum/place-order'
  return http.post(url, params)
}

// 下单
export function api_postOrder(params) {
  const url = '/koala/api/v1.5/curriculum/place-order'
  return http.post(url, params)
}

// 预定下单[http://yapi.xhwx100.com/project/11/interface/api/14580]
export function api_prestoreOrder(params) {
  const url = '/koala/api/v1.7/prestore/place-order'
  return http.post(url, params)
}

// 快捷下单
export function api_postQuickOrder(params) {
  const url = '/koala/api/v1.5/curriculum/quick-pay/place-order'
  return http.post(url, params)
}

// 订单详情
export function api_getOrderDetail(params) {
  const url =
    '/koala/api/v1.5/pay/order/detail' + '?time=' + new Date().getTime()
  return http.get(url, { params })
}

// 退课详情
export function api_getDropoutDetail(params) {
  const url = '/koala/api/v1.5/pay/order/curriculum/dropout-details'
  return http.post(url, params)
}

// 预定退款详情[http://yapi.xhwx100.com/project/11/interface/api/14679]
export function api_getPrestoreDropoutDetail(params) {
  const url = '/koala/api/v1.7/pay/order/refund/detail'
  return http.post(url, params)
}

// 取消订单
export function api_cancelOrder(params) {
  const url = '/koala/api/v1.5/curriculum/cancel-order'
  return http.put(url, stringify(params))
}

// 取消预定券订单[http://yapi.xhwx100.com/project/11/interface/api/14733]
export function api_cancelPrestoreOrder(params) {
  const url = '/koala/api/v1.7/pay/order/cancel-order'
  return http.post(url, params)
}

// “我的订单”列表
export function api_getOrders(params) {
  const url = '/koala/api/v1.5/pay/order/list'
  return http.get(url, { params })
}

// 收货地址（非绑定订单）
export function api_getConsignee() {
  const url = '/koala/api/v1.5/consignee/list'
  return http.get(url)
}

/**
 * [购物车下单获取价格信息](http://yapi.xhwx100.com/project/11/interface/api/7627)
 */
export function api_getShoppingCartOrder(params) {
  const url = '/koala/api/v1.5/curriculum/batch/price-info'
  return http.post(url, params)
}

/**
 * [购物车提交订单](http://yapi.xhwx100.com/project/11/interface/api/7467)
 */
export function api_submitShoppingCartOrder(params) {
  const url = '/koala/api/v1.6/cart/place-order'
  return http.post(url, params)
}

/**
 * [订单分享老师信息记录接口](http://yapi.xhwx100.com/project/11/interface/api/13194)
 */
export function api_sendTeacherShareId(params) {
  const url = '/koala/api/v1.7/special-curriculum/order-share-record'
  return http.post(url, params)
}
/**
 * [订单分享老师信息记录接口](http://yapi.xhwx100.com/project/11/interface/api/13194)
 */
export function api_RenewsendTeacherShareId(params) {
  const url = '/koala/api/v1.5/curriculum/order-share-record'
  return http.post(url, params)
}

/**
 * [订单绑定活动接口](http://yapi.xhwx100.com/project/11/interface/api/14274)
 */
export function api_orderBindActivity(params) {
  const url = '/koala/api/v1.7/reporting-activity/bind-order'
  return http.post(url, params)
}

/**
 * [预定订单业绩绑定](http://yapi.xhwx100.com/project/11/interface/api/14589)
 */
export function api_prestoreShareBind(params) {
  const url = '/koala/api/v1.7/prestore/order-share-bind'
  return http.get(url, { params })
}

// 拼团关联预定支付价格信息[http://yapi.xhwx100.com/project/11/interface/api/15921]
export function api_mergeOrderInfo(params) {
  const url = '/koala/api/v1.5/pay/order/merge/pay-info'
  return http.get(url, { params })
}

/**
 * [是否是已经确认的收货地址](http://yapi.xhwx100.com/project/27/interface/api/21564)
 */
export function api_isConfirmStudentAddress(params) {
  return http.post('/koala/api/v1.7/student/location/is-confirm', params)
}

/**
 * [确认收货地址](http://yapi.xhwx100.com/project/27/interface/api/21572)
 */
export function api_confirmStudentAddress(params) {
  return http.post('/koala/api/v1.7/student/location/set-confirm', params)
}

/**
 * [拼团寒假订单页价格查询]（http://yapi.xhwx100.com/project/11/interface/api/23547）
 * @param {*} params
 */
export function api_getGroupbuyingPriceInfo(params) {
  return http.post('/koala/api/v1.7/reporting-activity/price-info', params)
}

/**
 * [拼团寒假订单页选课详情]（http://yapi.xhwx100.com/project/11/interface/api/23519)
 * @param {*} params
 */
export function api_getGroupbuyingChooseClass(params) {
  const url = 'koala/api/v1.7/reporting-activity/choose-class'
  // 429 重试： 后台自动增班逻辑
  return retryHttp429(http.post, { delay: [500, 4] })(url, params)
}

/*
 * [op推送订单接口 不做验证处理]
 */
export function api_getOrderDetailFromOP(params) {
  const url =
    '/koala/api/v1.5/pay/order/detail-noauth' + '?time=' + new Date().getTime()
  return http.get(url, { params })
}

/*
 * [生源地--推荐城市]（http://yapi.xhwx100.com/project/11/interface/api/28712）
 */
export function api_getRecommendLocation(params) {
  const url = '/koala/api/v1.5/personal_center/recommend-location'
  return http.get(url, { params })
}

/**
 * [正价课与专题课下单冲突](http://yapi.xhwx100.com/project/11/interface/api/29920)
 */
export function api_checkConflict(params) {
  const url = '/koala/api/v1.7/pay/order/curriculum/check-conflict'
  return http.post(url, params)
}

/** 
 * [获取组合退课详情](http://yapi.xhwx100.com/project/11/interface/api/29713)
 */
export function api_getPackageDropoutDetail(params) {
  const url = '/koala/api/v1.7/pay/order/refund/order-product-dropout-info'
  return http.get(url, { params })
}

/**
 * [退课原因](http://yapi.xhwx100.com/project/11/interface/api/29960)
 */
export function api_getDropoutReasonList(params) {
  const url = '/koala/api/v1.7/pay/order/refund/dropout-reason-list'
  return http.post(url, params)
}

/**
 * [用户发起退课申请](http://yapi.xhwx100.com/project/11/interface/api/29968)
 */
export function api_orderReplyDropout(params) {
  const url = '/koala/api/v1.7/pay/order/refund/apply-dropout'
  return http.post(url, params)
}

/**
 * [订单退课条件](http://yapi.xhwx100.com/project/11/interface/api/29976)
 */
export function api_getDropoutCondition(params) {
  const url = '/koala/api/v1.7/pay/order/refund/apply-term'
  return http.get(url, { params })
}
