import http from "utils/http"
import { api_getStudentCurriculaNew } from 'src/api/my'
// import { api_getCurricula } from 'src/api/my_mock'

export const getMyEndedCourseList = ({ commit }, params) => {
  // 获取已经结束的课程列表
  const p = Object.assign({status: 1}, params)
  return api_getStudentCurriculaNew(p).then(res => {
    commit("SET_MY_ENDED_COURSE_LIST", res.data.curriculum_list)
    return res.data
  })
}

export const getMyOnlineCourseList = ({ commit }, params) => {
  // 获取正在进行的课程列表
  const p = Object.assign({status: 0}, params)
  return api_getStudentCurriculaNew(p).then(res => {
    commit("SET_MY_ONLINE_COURSE_LIST", res.data)
    return res.data
  })
}

export const getSubject = ({ commit }, params) => {
  const url = "/koala/api/v1.5/curriculum/subjects"
  http.post(url, params).then(res => {
    commit("SET_SUBJECT_LIST", res.data.subject_list)
  })
}

export const getGradeBanner = ({ commit }, params) => {
  const url = "/koala/api/v1.5/curriculum/banner/list"
  http.get(url, {params}).then(res => {
    const {banner_list} = res.data
    commit("SET_GRADE_BANNER", banner_list)
  })
}

export const getAlbumList = async ({commit}, params) => {
  const url = "/koala/api/v1.5/curriculum/select/album"
  const { data } = await http.post(url, params)
  commit("SET_ALBUM_LIST", data.quality_course || [])
  return data
}

export const getGenreList = async ({commit}) => {
  const url = "/koala/api/v1.5/curriculum/genre"
  const { data } = await http.get(url)
  commit("SET_GENRE_LIST", data)
  return data
}

export const getCourseList = ({ commit }, params) => {
  const url = "/koala/api/v1.5/curriculum/select"
  return new Promise((resolve, reject) => {
    http.post(url, params).then(res => {
      commit("SET_COURSE_LIST", res.data)
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getMoreCourse = ({ commit }, params) => {
  const url = "/koala/api/v1.5/curriculum/select"
  return new Promise((resolve, reject) => {
    http.post(url, params).then(res => {
      commit("SET_MORE_COURSE_LIST", res.data)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getCity = async ({ commit, rootState }) => {
  // console.log(rootState.auth)
  try {
    const city = rootState.auth.user.city_location_is_municipality ? rootState.auth.user.province : rootState.auth.user.city
    commit("SET_CITY", city || '')
  } catch(err) {
    commit("SET_CITY", '')
  }
  
}
