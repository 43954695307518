const RISE_HEIGHT = 34
// 以下为
const IPHONEX_NAV_HEIGHT = 88 // IPHONEX等型号刘海屏加微信导航栏距离
const IPHONE12_NAV_HEIGHT = 91 // iphone12刘海屏加微信导航栏距离

let Vue

const NORMAL_TYPE = {
  padding: 'paddingBottom',
  margin: 'marginBottom',
  bootom: 'bootom',
}

const normalFixFun = (mode, el, binding, initVal, style) => {
  const { value } = binding
  const type = typeof value === 'object' ? value.type : value
  const distance = mode === 0 ? RISE_HEIGHT + initVal : initVal
  const key = NORMAL_TYPE[type]
  el.style[key] = `${distance}px`
}

// 绝对定位元素兼容方法
const absoluteFixFun = (mode, el, binding, bottom, ppd, blankDom) => {
  // 绝对定位的底部栏处理 增加一个空白在原元素下方
  const distance = mode === 0 ? RISE_HEIGHT : 0
  el.style.bottom = `${parseInt(bottom) + distance}px`
  blankDom.style.bottom = `${-34 + distance}px`
  const parentNode = el.parentNode
  parentNode.style.paddingBottom = `${parseInt(ppd) + distance}px`
}

// 处理微信浏览器上滑下滑导航栏问题
const handleResize = (position, el, binding, initVal, ppd, blankDom) => {
  const oldHeight = window.innerHeight
  window.addEventListener("resize",function () {
    const fun = position === 'absolute' ? absoluteFixFun : normalFixFun
    if(window.innerHeight>oldHeight){
      fun(0, el, binding, initVal, ppd, blankDom)
    }else {
      fun(1, el, binding, initVal, ppd, blankDom)
    }
  },false)
}


const insertFun = (el, binding) => {
  // 匹配iPhone X 之后的机型
  const style = window.getComputedStyle(el)
  if (!Vue.prototype.$UA.isIos || screen.height < 812) return
  // 无导航栏则进行兼容 有则不兼容
  const mode = Vue.prototype.$UA.weixin && 
    (window.screen.height - IPHONEX_NAV_HEIGHT === window.innerHeight || window.screen.height - IPHONE12_NAV_HEIGHT === window.innerHeight) ? 0 : 1
  const { bottom, background, position, zIndex } = style
  const { value } = binding
  if (value && (NORMAL_TYPE[value] || NORMAL_TYPE[value.type])) {
    // 非绝对定位的方式兼容
    const type = NORMAL_TYPE[value] || NORMAL_TYPE[value.type]
    const initVal = parseInt(style[type])
    normalFixFun(mode, el, binding, initVal)
    handleResize('static', el, binding, initVal)
  } else {
    if (position !== 'absolute' && position !== 'fixed') return
    const blankDom = document.createElement('div')
    blankDom.style.cssText = `height:${RISE_HEIGHT}px;position:${position};bottom:0;left:0;right:0;background:${background};z-index:${zIndex};`
    const parentNode = el.parentNode
    if (parentNode) {
      parentNode.append(blankDom)
    }
    const parentPaddingBottom = parseInt(
      window.getComputedStyle(parentNode).paddingBottom
    )
    absoluteFixFun(mode, el, binding, bottom, parentPaddingBottom, blankDom)
    handleResize('absolute', el, binding, bottom, parentPaddingBottom, blankDom)
  }
}

export default (instance) => {
  Vue = instance
  return {
    inserted: insertFun
  }
}
