import axios from 'axios'
import formatTime from 'utils/time'
import { isAndroidApp, isIosApp } from 'utils/version'
import globalCookieHandler from 'src/store/cookie'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import hmacSha1 from 'crypto-js/hmac-sha1'
const md5 = require('js-md5');
import Base64 from 'crypto-js/enc-base64'
import './event_tracking_pb_web'
const userAgent = navigator.userAgent.toLowerCase()
const isApp = /xiaoandroidwebview/.test(userAgent) || /xiaoioswebview/.test(userAgent)
const queue = []
let running = false
let hardOfHour = false
let timer = null
/**
 * 获取日志域名参数
 **/
let loggerParams = {}
function getLoggerParams() {
  if(loggerParams&&loggerParams.project) return loggerParams
  return renewalSls()
}

/**
 * 阿里日志权限更新
 **/
const renewalSls = async () => {
  return await axios.create({
    baseURL: `//${__API_SERVICE_HOST__}`
  }).get('koala/api/v2.1/log/access/tracking-log').then( res => {
    loggerParams = res.data
    return loggerParams
  })
}

/**
 * 生成 device id
 **/
async function getDeviceId() {
  if(!globalCookieHandler.device_id){
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    return globalCookieHandler.device_id = result.visitorId
  }
  return globalCookieHandler.device_id
}

/**
 * 构建 axios 实例
 */
const getHttpService = (loggerParams, params)=> {
  const { access_key_id, security_token } = loggerParams
  let body = getPBBody(params)
  let md5Str = md5(body).toUpperCase()
  let date = new Date().toGMTString()
  let Authorization = "LOG " + access_key_id + ":" + getSignature(md5Str, date, loggerParams, body)
  return axios.create({
    headers: {
      "Authorization": Authorization,
      "Content-Type": `application/x-protobuf`,
      "Content-MD5": md5Str,
      "x-log-date": date,
      "x-acs-security-token": security_token,
      "x-log-apiversion": `0.6.0`,
      "x-log-bodyrawsize": body.length,
      "x-log-signaturemethod": `hmac-sha1`,
    },
  })
}

function getSignString(md5Str, date, loggerParams, body){  
  const { logstore, security_token } = loggerParams
  return 'POST' + "\n"
  + md5Str + "\n"
  + "application/x-protobuf" + "\n"
  + date + "\n"
  + "x-acs-security-token:" + security_token + "\n"
  + "x-log-apiversion:0.6.0" + "\n"
  + "x-log-bodyrawsize:" + body.length + "\n"
  + "x-log-signaturemethod:hmac-sha1" + "\n"
  + "/logstores/" + logstore
}

function getSignature(md5Str, date, loggerParams, body){
  const { access_key_secret } = loggerParams
  let SignString = getSignString(md5Str, date, loggerParams, body)
  return Base64.stringify(hmacSha1(SignString, access_key_secret))
}

function getPBBody(params){
  params = JSON.parse(JSON.stringify(params))
  const log = new proto.Log()
  log.setTime(Math.floor(new Date().getTime()/1000))
  let arr = []
  Object.keys(params).forEach((item)=>{
    const content = new proto.LogContent()
    content.setKey(item)
    // 这两个字段不改成string PB 上不去
    if(item===`extra_field` || item===`timestamp`) {
      content.setValue(JSON.stringify(params[item]))
    } else{
      content.setValue(params[item])
    }
    arr.push(content)
  })
  log.setContentsList(arr)
  const logGroup = new proto.LogGroup()
  logGroup.setLogsList([log])
  const logTag = new proto.LogTag()
  logTag.setKey('xhwx')
  logTag.setValue('xhwx-aliLoggerWebSDK')
  logGroup.setLogtagsList([logTag])
  return logGroup.serializeBinary()
}

/**
 * 阿里日志权限有 60分钟 过期限制，我们每30分钟更新一下
 **/
if (!timer) {
  timer = setInterval(() => hardOfHour = true, 30*60*1000)
  window.addEventListener('beforeunload', () => {
    clearInterval(timer)
    timer = null
  })
}


// 上传队列下一个日志
function nextFn(){
  if(!running && queue.length>0){
    try {
      queue.shift()()
    } catch(err) {
      console.log(err)
    }    
  }
}

/**
 因为阿里云日志服务接口如果要用post方法需要设置 header；
 而 navigator.sendBeacon 设置 header 需要用 blob 形式，而阿里又不支持blob
 所以先放弃 navigator.sendBeacon
 **/
export default async (params = {}) => {
  let platform;
  if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    platform = 'h5'
  }else{
    platform = 'web'
  }
  if (isAndroidApp) {
    platform = 'android'
  }
  if (isIosApp) {
    platform = 'ios'
  }
  const fn = async () => {
    running = true
    if(hardOfHour){
      await renewalSls()
      hardOfHour = false
    }
    try {
      const {endpoint, project, logstore} = await getLoggerParams()
      if (!logstore || !project) return
      let finalParams = {
        user_agent: navigator.userAgent,
        timestamp: new Date().getTime(),
        user_id: globalCookieHandler.user_id || '',
        device_id: await getDeviceId() || '',
        ...params,
        platform: params.platform || platform
      }
      const api = `//${project}.${endpoint}/logstores/${logstore}`
      const httpService = getHttpService(loggerParams, finalParams)
      const postFn = () => httpService.post(api, getPBBody(finalParams)).then(()=>{
        running = false
        nextFn()
      }).catch(err => {
        console.log(err)
        // 出问题直接全部出队列 防止任务被挂起
        try {
          while(queue.length) {
            queue.shift()()
          }
        } catch(err2) {
          console.log(err2)
        }
      })
      return postFn()
    } catch(err) {
      return err
    }
  }
  queue.push(fn)
  nextFn()
}
