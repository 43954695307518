import Vue from 'vue'

import XiaoToastVue from './XiaoToast.vue'

const xiaoToastMethods = ['success', 'info', 'warning', 'success_1v1', 'warning_1v1','newInfo']


const XiaoToast = Vue.extend(XiaoToastVue)

/**
   * 位于上方的 toast 提示
   * @param {String|Object} message message or options
   * @returns {XiaoToast} XiaoToast instance
   */
function xiaoToast(message) {
  const data = {
    type: 'success',
    message: '',
    bottom: false,
    middle: false,
    middleAnimate: false
  }
  if (typeof message === 'string') {
    data.message = message
  } else if (typeof message === 'object') {
    Object.assign(data, message)
  } else {
    throw new Error(`Expect argument to be a String or an Object, but got "${typeof message}"`)
  }
  const vm = new XiaoToast({ data })
  vm.$mount()
  document.body.appendChild(vm.$el)
  return vm
}

/**
   * 位于下方的 toast 提示
   * @param {String|Object} message message or options
   * @returns {XiaoToast} XiaoToast instance
   */
xiaoToast.bottom = function xiaoToastBottom(message) {
  const options = {
    type: 'success',
    message: '',
    bottom: true
  }
  if (typeof message === 'string') {
    options.message = message
  } else if (typeof message === 'object') {
    Object.assign(options, message)
  } else {
    throw new Error(`Expect argument to be a String or an Object, but got "${typeof message}"`)
  }
  options.bottom = true
  return xiaoToast(options)
}

/**
   * 位于页面中部的 toast 提示
   * @param {String|Object} message message or options
   * @returns {XiaoToast} XiaoToast instance
   */
xiaoToast.middle = function xiaoToastMiddle(message) {
  const options = {
    type: 'success',
    message: '',
    middle: true,
    whiteSpace:'normal',
  }
  if (typeof message === 'string') {
    options.message = message
  } else if (typeof message === 'object') {
    Object.assign(options, message)
  } else {
    throw new Error(`Expect argument to be a String or an Object, but got "${typeof message}"`)
  }
  options.middle = true
  return xiaoToast(options)
}

/**
 * 位于页面中部的 toast 提示
 * 会动
 * @param {String|Object} message message or options
 * @returns {XiaoToast} XiaoToast instance
 */
xiaoToast.middleAnimate = function xiaoToastMiddle(message) {
  const options = {
    type: 'success',
    message: '',
    middleAnimate: true
  }
  if (typeof message === 'string') {
    options.message = message
  } else if (typeof message === 'object') {
    Object.assign(options, message)
  } else {
    throw new Error(`Expect argument to be a String or an Object, but got "${typeof message}"`)
  }
  options.middleAnimate = true
  return xiaoToast(options)
}


xiaoToastMethods.forEach(type => {
  xiaoToast[type.toUpperCase()] = type
  xiaoToast[type] = function (message) {
    return xiaoToast({
      type,
      message
    })
  }
  xiaoToast.bottom[type] = function (message) {
    return xiaoToast.bottom({
      type,
      message
    })
  }
  xiaoToast.middle[type] = function (message) {
    return xiaoToast.middle({
      type,
      message
    })
  }
  xiaoToast.middleAnimate[type] = function (message) {
    return xiaoToast.middleAnimate({
      type,
      message
    })
  }
})

export default xiaoToast