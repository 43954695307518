export default <template>
  <XiaoModal :visible.sync="visible" :showCloseIcon="false">
    <div :class="$style.modal">
      <div :class="$style.title">{{title}}</div>
      <div :class="$style.content">{{content}}</div>
      <div :class="$style.btn" @click="handleBtnClick">{{btn.text}}</div>
    </div>
  </XiaoModal>
</template>
<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'title'
    },
    content: {
      type: String,
      default: 'content'
    },
    btn: {
      default() {
        return {
          text: 'btn',
          handleBtnClick: () => {}
        }
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleBtnClick() {
      this.btn.handleBtnClick && this.btn.handleBtnClick()
    }
  },
  components: {
    XiaoModal: () => import('components/xiao-modal/XiaoModal.vue'),
  }
}
</script>
<style scoped lang="scss" module>
.modal {
  width: 7.73333333rem;
  background: #FFFFFF;
  border-radius: .26666667rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: .64rem .53333333rem;

  .title {
    font-size: .48rem;
    color: #464646;
  }
  .content {
    font-size: .4rem;
    color: #5A5A5A;
    margin-top: .32rem;
    margin-bottom: .53333333rem;
  }
  .btn {
    width: 6.66666667rem;
    height: 1.17333333rem;
    line-height: 1.17333333rem;
    background: #00C55D;
    border-radius: .58666667rem;
    text-align: center;
    font-size: .42666667rem;
    color: #FFFFFF;
  }
}
</style>