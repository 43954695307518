import * as actions from 'vux/actions/home'

const state = {
	teacher: [],
	playback: [],
	parentsPraise: []
}

const mutations = {
	SET_TEACHER_CUBE(state, list) {
		state.teacher = list
	},
	SET_PLAYBACK_LIST(state, list) {
		state.playback = list.playbacks
	},
	SET_PARENTS_PRAISE(state, list) {
		state.parentsPraise = list.parents_praises || []
	}
}

export default {
	namespaced: true,
	actions,
	state,
	mutations
}
