import Vue from 'vue'
import { api_checkStudentCity, api_setStudentCity, api_getCityByIp} from '../../api/location.js'
import CityPickerVue from './XiaoCityPicker4H5.vue'
import globalCookieHandler from '../../store/cookie.js'
const CityPicker = Vue.extend(CityPickerVue)
const isLogin = !!globalCookieHandler.token
function getCheckResult() {
  return api_checkStudentCity()
}
function setStudentCity(cityCode) {
  return api_setStudentCity(cityCode)
}
function getCityByIp() {
  return api_getCityByIp()
}

export function checkCity(status = false) {
  // 屏蔽学习报告检查
  const reportReg = /^\/report/
  if (window && typeof window == 'object') {
    const url = window.location.pathname
    if (reportReg.test(url)) return false
  }
  if (isLogin || status) {
    getCheckResult()
      .then(res => {
        const result = res.data.result
        if (!result) {
          const props = {}
          const methods = {}
          const picker = new CityPicker({ props, methods })
          picker.isDimmer = false
          picker.closeButton = false
          getCityByIp()
            .then(res => {
              if (res.data.city_code) {
                picker.city = res.data.city_code
                picker.province = res.data.city_code.slice(0, 2) + '0000'
              } else {
                picker.city = ''
                picker.province = ''
              }
              picker.confirm = () => {
                if (!picker.city) return
                const params = {
                  city_code: picker.city
                }
                setStudentCity(params)
                  .then(() => {
                    picker.innerVisible = false
                    // 恢复原标题
                    document.title = picker.oldTitle
                    if(picker.$el) {
                      document.body.removeChild(picker.$el)
                    }
                  })
                  .catch(err => {
                    // 避免晓服务同步延迟导致的无法关闭窗口
                    if (err && err.response && err.response.data) {
                      const code = err.response.data.err
                      if (code === 3614) {
                        picker.innerVisible = false
                        document.title = picker.oldTitle
                        if(picker.$el) {
                          document.removeChild(picker.$el)
                        }
                      }
                    }
                  })
              }
              // 发送请求  
              picker.$mount()
              document.body.appendChild(picker.$el)
              // 动画延时
              setTimeout(() => {
                picker.innerVisible = true
              }, 0)
            })
            .catch(() => {
              picker.confirm = () => {
                if (!picker.city) return
                const params = {
                  city_code: picker.city
                }
                setStudentCity(params)
                  .then(() => {
                    picker.innerVisible = false
                    document.title = picker.oldTitle
                  })
              }
              picker.$mount()
              document.body.appendChild(picker.$el)
              // 动画延时
              setTimeout(() => {
                picker.innerVisible = true
              }, 0)
            })
        }
      })
  }
}