exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.page_32V6P{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;position:fixed;left:0;right:0;top:0;bottom:0;background:#fff;z-index:100\n}\n.page_32V6P img{width:1.6rem\n}", ""]);

// exports
exports.locals = {
	"page": "page_32V6P"
};