export default <template>
<div class="wechatmpbinding">
  <div class="wechatmpbinding__success" v-if="isBindSuccess">解绑公众号成功</div>
  <div class="wechatmpbinding__failed" v-if="isBindFailed">解绑公众号失败</div>
  <FullLoading v-if="isLoading"></FullLoading>
</div>
</template>

<script>
import http from 'utils/http'
import jump from 'utils/jump'

import FullLoading from 'components/FullLoading.vue'

export default {
  components: { FullLoading },
  data() {
    return {
      isLoading: false,
      isBindSuccess: false,
      isBindFailed: false
    }
  },
  created() {
    // return false
    this.isLoading = true
    this.isBindSuccess = false
    this.isBindFailed = false
    http.post('/koala/api/v1.5/auth/unbind-wechat-mp')
      .then(() => {
        this.isLoading = false
        this.isBindSuccess = true
      })
      .catch(error => {
        this.isLoading = false
        this.isBindFailed = true
        if (error && error.response) {
          if (error.response.status === 401) {
            jump.login()
          } else {
            const err = error.response.data && error.response.data.err
            if (err === 3604) {
              this.$xiaoToast.warning('你还没有绑定公众号')
            } else {
              this.$xiaoToast.warning('解绑失败')
            }
          }
        } else {
          this.$xiaoToast.warning('解绑失败')
        }
      })
  }
}
</script>
