export default [{
  path: '/CaptchaLogin/phone',
  name: 'CaptchaLogin-phone',
  meta: {
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/CaptchaLogin/index.vue'], resolve)
  }
},
{
  path: '/CaptchaLogin/captcha/:phone',
  name: 'CaptchaLogin-captcha',
  meta: {
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/CaptchaLogin/captcha.vue'], resolve)
  }
},
{
  path: '/ForgetPassword/phone',
  name: 'ForgetPassword-phone',
  meta: {
    title: '忘记密码',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/ForgetPassword/index.vue'], resolve)
  }
},
{
  path: '/ForgetPassword/password/:phone',
  name: 'ForgetPassword-password',
  meta: {
    title: '忘记密码',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/ForgetPassword/password.vue'], resolve)
  }
},
{
  path: '/password/setting',
  name: 'setting-password',
  meta: {
    title: '设置密码',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/SetPassword/index.vue'], resolve)
  }
},
{
  path: '/PasswordLogin',
  name: 'PasswordLogin',
  meta: {
    title: '登录',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/PasswordLogin/index.vue'], resolve)
  }
},
{
  path: '/SwitchStudent',
  name: 'SwitchStudent',
  meta: {
    title: '切换学员',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/SwitchStudent/index.vue'], resolve)
  }
},
{
  path: '/UserCenter',
  name: 'UserCenter',
  meta: {
    title: '用户中心',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/UserCenter/index.vue'], resolve)
  }
},
{
  path: '/CompleteUserInfo',
  name: 'CompleteUserInfo',
  meta: {
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/CompleteUserInfo/index.vue'], resolve)
  }
},
{
  path: '/WechatBindPhone/:union_id/:open_id',
  name: 'WechatBindPhone',
  meta: {
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/WechatBindPhone/index.vue'], resolve)
  }
},
{
  path: '/NoCaptchaWebview/:scene',
  name: 'NoCaptchaWebview',
  meta: {
    title: '用户中心',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/NoCaptchaWebview/index.vue'], resolve)
  }
},
{
  path: '/playground',
  name: 'playground',
  component: resolve => {
    require(['src/views/account/playground.vue'], resolve)
  }
},
{
  path: '/wechatLoginSelect',
  name: 'wechatLoginSelect',
  meta: {
    title: '微信登录',
    isLoginRoute: true
  },
  component: resolve => {
    require(['src/views/account/WechatSelectMethods/index.vue'], resolve)
  }
},
]