import $http from 'utils/http'
export const getTrialList = ({commit}, params) => {
  const url = '/koala/api/v1.5/curriculum/free-trial/list'
  return new Promise((resolve, reject) => {
    $http.post(url, params)
      .then(res => {
        const {free_trials, total} = res.data
        commit('SET_TRIAL_LIST', free_trials)
        commit('SET_TRIAL_TOTAL', total)
        resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

export const getTrialOverview = ({commit}, params) => {
  const url = '/koala/api/v1.5/curriculum/free-trial/overview'
  $http.post(url, params)
    .then(res => {
      commit('SET_TRIAL_OVERVIEW', res.data)
    })
    .catch(err => {
      console.log(err)
    })
}

export const getTrialLesson = ({commit}, param) => {
  const url = '/koala/api/v1.5/curriculum/free-trial/get'
  return new Promise((resolve, reject) => {
    $http.post(url, param)
      .then(res => {
        commit('SET_LESSON_GETTED', param.curriculum_id)
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
