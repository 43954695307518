export default <template>
<transition :enter-class="$style.enter" :leave-to-class="$style.leaveTo" :enter-active-class="$style.transitionActive" :leave-active-class="$style.transitionActive"
  @after-leave="destroyElement">
  <div :class="$style.dialog" v-if="visible">
    <div :class="$style.dimmer" @touchstart.prevent.stop></div>
    <div :class="$style.box">
      <div :class="$style.boxHeader">{{ title }}</div>
      <pre :class="$style.boxBody">{{ content }}</pre>
      <div :class="$style.boxFooter">
        <button type="button" :class="[$style.btn, $style.okBtn]" @click="onClickOK">{{ okLabel }}</button>
        <div :class="$style.line"></div>
        <button type="button" :class="[$style.btn, $style.cancelBtn]" @click="onClickClose">{{ cancelLabel }}</button>
      </div>
    </div>
  </div>
</transition>
</template>

<style lang="scss" module src="./xiao-confirm.module.scss"></style>

<script>
export default {
  data() {
    return {
      visible: false,
      okLabel: '确定',
      cancelLabel: '取消',
      title: '',
      content: ''
    }
  },
  methods: {
    destroyElement() {
			const $el = this.$el
      this.$destroy()
      if ($el.parentNode) {
        $el.parentNode.removeChild($el)
      }
    },
    onClickOK() {
      this.visible = false
      this.$emit('confirm', { result: true })
    },
    onClickClose() {
      this.visible = false
      this.$emit('confirm', { result: false })
    }
  },
  mounted() {
    this.visible = true
  }
}
</script>
