import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (__USE_SENTRY_ALERT__) {
  Sentry.init({
    dsn:
      'https://8af6b37d35c641abb59f0663a23e1972@x-sentry.xiaojiaoyu100.com/9',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    logErrors: true,
    environment: __TARGET__,
    release: __RELEASE_VERSION__
  })
}

import router from 'src/router'
import store from 'vux/store'
import globalFilters from './vue-utils/filters'
import xiaoToast from './vue-utils/plugins/xiaoToast'
import xiaoConfirm from './vue-utils/plugins/xiaoConfirm'
import xiaoMessage from './vue-utils/plugins/xiaoMessage'
import UA from './vue-utils/plugins/ua'
import MP from './vue-utils/plugins/mp'
import AppSDK from './vue-utils/plugins/app-sdk'
import qs from 'querystring'
import './shareCityBetweenTabs'
import './bridge'
import loadTikuCssFile from './loadTikuCssFile'
import getMiniProgram from './getMiniProgram'
import installDirectives from './vue-utils/directives'
import filters from 'utils/filter.js'
import logger from 'src/utils/logger.js'
import { createRandomStr } from 'utils/mdRelevant'
import { GIO_PAGE_SET } from 'src/enum/gio'
import globalEventbus from './globalEventbus'
import eventTrackSDK from 'utils/eventTrackSDK'
import UserTopBar from 'components/UserTopBar/UserTopBar.vue'
import resizeFont from 'utils/resizeWechatFont.js'

const urlQueryObject = qs.parse(window.location.search.slice(1))
if (urlQueryObject.IS_FROM_MP == 'true') {
  sessionStorage.setItem('IS_FROM_MP', true)
}

Vue.use(UA)
Vue.use(MP)
Vue.use(AppSDK)
Vue.use(xiaoToast)
Vue.use(xiaoConfirm)
Vue.use(xiaoMessage)
Vue.use(globalFilters)
Vue.use(filters)

Vue.component('UserTopBar', UserTopBar)

installDirectives(Vue)

// cookie_id: 用于埋点时 标识唯一设备
if (!localStorage.getItem('cookie_id')) {
  const cookie_id = createRandomStr(32)
  localStorage.setItem('cookie_id', cookie_id)
}
Vue.prototype.$logger = (params) => {
  try {
    logger(params)
  } catch(err) {
    console.log(err)
  }
}
Vue.prototype.$globalEventbus = globalEventbus
Vue.prototype.$tracker = new eventTrackSDK()

require('src/sass/main.scss')
require('swiper/dist/css/swiper.css')

new Vue({
  store,
  router,
  render: (createElement) => createElement('router-view')
}).$mount('#app')

// iOS 必须绑定 touch 事件才能触发 :active 伪类的 CSS 效果
window.onload = function() {
  // 加载晓教学的题库的基础样式
  loadTikuCssFile()
  if (window.gio) {
    const isWeixin = Vue.prototype.$UA.weixin
    const browserType = isWeixin ? '微信浏览器' : '非微信浏览器'
    gio("page.set", GIO_PAGE_SET.BROWSER_TYPE, browserType)
  }
  // 加入小程序环境
  getMiniProgram()  

  //  强制禁止用户修改微信安卓客户端的字体大小
  const isWeixin = Vue.prototype.$UA.weixin
  const isAndroid = Vue.prototype.$UA.isAndroid
  if(isWeixin && isAndroid){
    resizeFont()
  }
}
