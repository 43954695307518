import Vue from 'vue'
import Toast from './index.vue'

const ToastNew = Vue.extend(Toast)
let toast = null

function msgToast(option) {
  if(toast) {
    return toast
  }
  toast = new ToastNew()
  toast.show(option)
  toast.$mount()
  document.body.appendChild(toast.$el)
  return toast
}

export default msgToast