export default <template>
<XiaoModal  :visible.sync="innerVisible" :box-style="$style.box" :showCloseIcon="false" :close-on-click-dimmer="isDimmer">
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <div :class="$style.currentStatus">
        <div :class="$style.highlight" v-if="type === 1">
          请选择所在省份/直辖市
        </div>
        <div v-if="type === 2">
          <div :class="$style.normal" @click="backToProvince">{{ province | dist}}</div>
          <div :class="$style.highlight">
            <div v-if="city">{{ city | dist }}</div>
            <div v-else>请选择城市</div>
            <div :class="$style.linkLine"></div>
          </div>
        </div>
        <div v-if="type === 3" @click="backToCity">
          <div :class="$style.normal">广东省</div>
          <div :class="$style.normal">
            中山市
            <div :class="$style.linkLine"></div>
          </div>          
          <div :class="$style.highlight">
            请选择区/县
            <div :class="$style.linkLine"></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.main" ref="body">
      <div 
        v-for="item in list"
        :key="item.code"
        :class="$style.item"
        @click="choose(item.code)"
      >
        <span :class="[$style.value, {[$style.active]: item.code === city}]">{{item.name}}</span>
        <span :class="$style.arrow" class="xiaoicon icon-arrow">&#xe613;</span>
      </div> 
    </div>
  </div>
  <div :class="$style.button" @click="confirm">
    确定
  </div>
</XiaoModal>
</template>

<script>
const SELECT_PROVINCE = 1
const SELECT_CITY = 2
const SELECT_DISTRICT = 3

import dist from 'xiao-utils/district'
import XiaoModal from 'components/xiao-modal/XiaoModal.vue'
export default {
  props: {
    isBottom: {
      type: Boolean,
      default: true
    },
    isDimmer: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      innerVisible: true,
      type: 1,
      province: '',
      city: '',
      district: '',
      list: [],
      oldTitle: ''
    }
  },
  methods: {
    // 获取选择类型
    getType() {
      if(!this.province) {
        this.type = SELECT_PROVINCE
      } else if(!this.city) {
        this.type = SELECT_CITY
      } else {
        if (this.depth) {
          this.type = this.depth
          return
        }
        this.type = SELECT_DISTRICT
      }
    },
    // 获取城市数据
    getData(type, code) {
      if(type === SELECT_PROVINCE) {
        return Object.keys(dist)
          .filter(item => item % 10000 === 0)
          .map(item => ({
            code: item,
            name: dist[item]
          }))
      }
      if(type === SELECT_CITY) {
        let province = code || this.province
        const cityList = []
        province = province.slice(0, 2)
        for (let code in dist) {
          if (dist.hasOwnProperty(code)) {
            const code1 = code.slice(0, 2), code2 = code.slice(2, 4), code3 = code.slice(4)
            if (code1 === province && code2 !== '00' && code3 === '00' ) {
              cityList.push({
                code: code,
                name: dist[code]
              })
            }
          }
        }
        if (cityList.length === 0) {
          for (let code in dist) {
            if (dist.hasOwnProperty(code)) {
              const code1 = code.slice(0, 2), code2 = code.slice(2, 4), code3 = code.slice(4)
              if (code1 === province && code2 === '00' && code3 !== '00') {
                cityList.push({
                  code: code,
                  name: dist[code]
                })
              }
            }
          }
        }
        return cityList
      }
      if(type === SELECT_DISTRICT){
        let city = code || this.city
        const districtList = []
        const province = this.province.slice(0, 2)
        city = city.slice(2, 4)
        if (city === '00') {
          return districtList
        }
        for (let code in dist) {
          if (dist.hasOwnProperty(code)) {
            const code1 = code.slice(0, 2), code2 = code.slice(2, 4), code3 = code.slice(4)
            if (code1 === province && code2 === city && code3 !== '00') {
              districtList.push({
                code: code,
                name: dist[code]
              })
            }
          }
        }
        return districtList
      }
    },
    // 返回省选择
    backToProvince() {
      this.province = ''
      this.city = ''
      this.district = ''
      this.getType()
      this.list = this.getData(this.type)
    },
    // 返回城市选择
    backToCity() {
      this.city = ''
      this.district = ''
      this.getType()
      this.list = this.getData(this.type, this.province)
    },
    // 选择项
    choose(id) {
      if(this.type === SELECT_PROVINCE) {
        this.province = id
        let cityList = this.getData(SELECT_CITY, id)
        if(cityList.length > 0) {
          this.type = SELECT_CITY
          this.list = cityList
        } else {
          this.$emit('success', {
            province: this.province
          })
        }
        return
      }
      if(this.type === SELECT_CITY) {
        this.city = id
        if (this.depth > 2) {
          let districtList = this.getData(SELECT_DISTRICT, id)
            if(districtList.length > 0) {
              this.type = SELECT_DISTRICT
              this.list = districtList
            } else {
              this.$emit('success', {
                province: this.province,
                city: this.city
              })
            }
          return
        }
        
      }
      if(this.type === SELECT_DISTRICT) {
        this.district = id
        this.$emit('success', {
          province: this.province,
          city: this.city,
          district: this.district
        })
      }
    },
    confirm() {
      this.$emit('confirm', {province: this.province, city: this.city})
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.province = ''
        this.city = ''
        this.districe = ''
      }else {
        this.getType()
        this.list = this.getData(this.type)
      }
    },
    list: {
      handler() {
        if (this.$refs.body) {
          this.$refs.body.scrollTop =  0
        }
      },
      deep: true
    }
  },
  created() {
    // document.getElementByTagName('title')[0].innerText = '请确认你当前所在城市'
    this.oldTitle = document.title
    document.title = '请确认你当前所在城市'
    console.log('title', document.title)
  },
  mounted() {
    this.getType()
    this.list = this.getData(this.type)
  },
  filter: {
    dist(val) {
      if(!value) return '未填写'
      return dist[value]
    }
  },
  components: {
    XiaoModal
  }
}
</script>

<style lang="scss" module src="./xiao_city_picker.module.scss"></style>