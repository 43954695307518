exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.modal_3b74k{width:7.866667rem;padding:.266667rem .533333rem .533333rem .533333rem;position:fixed;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);background:#fff;border-radius:.26666667rem\n}\n.modal_3b74k .student_20Jst{padding-bottom:.266667rem;border-bottom:1px solid #ebebeb\n}\n.modal_3b74k .student_20Jst .tips_1LIXX{font-size:.373333rem;color:#5a5a5a\n}\n.modal_3b74k .student_20Jst .info_2CIpw{text-align:center\n}\n.modal_3b74k .student_20Jst .infoName_1mS9-{font-size:.32rem;color:#464646;font-size:.32rem\n}\n.modal_3b74k .student_20Jst .infoPhone_2eEpO{font-size:.373333rem;font-family:\"xiaonum\";color:#00c55d;margin-left:.266667rem;font-size:.373333rem\n}\n.modal_3b74k .select_1Kq-T{padding-bottom:.533333rem;text-align:left\n}\n.modal_3b74k .select_1Kq-T .title_39G73{padding:.533333rem 0 .266667rem 0;font-size:.426667rem;color:#464646;text-align:left;font-weight:bold\n}\n.modal_3b74k .select_1Kq-T .radio_2ld4U{font-size:.426667rem;color:#464646\n}\n.modal_3b74k .select_1Kq-T .radio_2ld4U .label_1eqpV{display:block;height:.96rem;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center\n}\n.modal_3b74k .select_1Kq-T .radio_2ld4U .labelText_laLeQ{margin-left:.21333333rem\n}\n.modal_3b74k .select_1Kq-T .radio_2ld4U .radioBtn_2CeSJ+.radioIcon_3Z4UM::after{content:\"\\E762\";color:#ccc;font-size:.53333333rem\n}\n.modal_3b74k .select_1Kq-T .radio_2ld4U .radioBtn_2CeSJ:checked+.radioIcon_3Z4UM::after{content:\"\\E760\";color:#00c55d\n}\n.modal_3b74k .cancel_W1SWW{width:6.533333rem;height:1.173333rem;line-height:1.173333rem;background:#fff;border-radius:.586667rem;border:1px solid #b3b3b3;font-size:.426667rem;color:#8a8a8a;margin:0 auto\n}", ""]);

// exports
exports.locals = {
	"modal": "modal_3b74k",
	"student": "student_20Jst",
	"tips": "tips_1LIXX",
	"info": "info_2CIpw",
	"infoName": "infoName_1mS9-",
	"infoPhone": "infoPhone_2eEpO",
	"select": "select_1Kq-T",
	"title": "title_39G73",
	"radio": "radio_2ld4U",
	"label": "label_1eqpV",
	"labelText": "labelText_laLeQ",
	"radioBtn": "radioBtn_2CeSJ",
	"radioIcon": "radioIcon_3Z4UM",
	"cancel": "cancel_W1SWW"
};