import { nameRegExp } from 'utils/regexp'
import md5 from 'utils/md5.js'
import time from './time'

/**
 * 监测用户信息是否完整
 * @param  {Object} user 用户信息对象
 * @return {Boolean}      是否完整
 */

export function checkInfo(user) {
  if (nameRegExp.test(user.name) && user.grade) {
    return true
  } else {
    return false
  }
}

export function sameDay(stamp1, stamp2) {
  const date1 = new Date(stamp1 * 1000)
  const date2 = new Date(stamp2 * 1000)
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate()
}

export function xiaoTime(stamp) {
  const now = new Date()
  const date = new Date(stamp * 1000)
  if(now.getFullYear() === date.getFullYear()) {
    return `${date.getMonth() + 1}月${date.getDate()}日`
  } else {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  }
}

export function date(stamp) {
  const date = new Date(stamp * 1000)
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
}

export const RAF = (window.requestAnimationFrame || function(callback) {
  setTimeout(callback, 1000 / 60)
}).bind(window)

export const CAF = (window.cancelAnimationFrame || function(timer) {
  clearTimeout(timer)
}).bind(window)

/**
 * 获取当前页面的滚动距离，考虑到一些可能的兼容性问题，所以使用以下方法统一获取
 * @return {number}
 */
export function getViewportScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
}

export function formatHls(src) {
  if (!src || typeof src !== 'string') {
    return src
  }
  if (/hls/i.test(src) && !/m3u8/i.test(src) && !/\.mp4/.test(src)) { // 有hls但是没有m3u8的链接，这种形式的链接在Android Chrome中不能识别为hls
    if (src.indexOf('?') !== -1) {
      src += '&m3u8'
    } else {
      src += '?m3u8'
    }
    return src
  }
  return src
}

/*
* 向下找，所有指定组件
  1.只要在一个children组件内找到了一个，就不继续找下
* */
export function findChildNodes(vm, childName) {
  return vm.$children.reduce((formItems, i) => {
    if (i.$options.name === childName) {
      formItems.push(i)
      return formItems
    }
    return formItems.concat(findChildNodes(i, childName))
  }, [])
}

function objKeySort(obj) {
  let newkey = Object.keys(obj).sort()
  let newObj = {}
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]]
  }
  return newObj
}
function getSigns(datas) {
  let data = objKeySort(datas)
  let url = ''
  if (typeof data == 'undefined' || data == null || typeof data != 'object') {
    return ''
  }
  for (var k in data) {
    url += (url.indexOf('=') != -1 ? '&' : '') + k + '=' + data[k]
  }
  return url
}

export function signatureGenerator(params) {
  const paramsStr = getSigns(objKeySort(params))
  const md5Str = md5(paramsStr)
  const prefix = md5Str.substring(0, 2)
  const signature = md5Str.slice(2) + prefix
  return signature
}

export function smoothVerticalScrolling (el, distance, speed = 10) {
  if (!el) return
  if (!distance) return
  const direction = distance > 0 ? 'down' : 'up'
  let curDistance = 0
  const timer = window.setInterval(() => {
    if (direction === 'up') {
      const d = distance > curDistance - speed ? distance - curDistance : -speed 
      el.scrollBy(0, d)
      curDistance += d
    } else {
      const d = distance < curDistance + speed ? distance - curDistance : speed 
      el.scrollBy(0, d)
      curDistance += d
    }
    if (curDistance === distance) clearInterval(timer)
  }, 1)
}

export const isTruthy = (val) => {
  return val === 0 || !!val
}