
import $http from 'utils/http'
import jump from 'utils/jump'

/**
 * [讲义物流列表](http://yapi.xhwx100.com/project/11/interface/api/11790)
 */
export function getLogisticsList ({ commit }, params) {
  const url = '/koala/api/v1.7/textbook/logistics/list'
  return new Promise((resolve, reject) => {
    $http.post(url, params).then(res => {
      commit('SET_Logistics_List', res.data.list)
      resolve(res.data)
    }).catch(err => {
      if (err && err.response && err.response.status === 401) {
        jump.login()
        return false
      }
    })
  })
}

/**
 * [查看物流]](http://yapi.xhwx100.com/project/27/interface/api/12429)
 */
export function getTrace ({ commit }, params) {
  const url = '/koala/api/v1.7/textbook/send_packet/trace_get'
  $http.get(url, { params }).then(res => {
    commit("SET_Trace_List", res.data.list)
  })
}

/**
 * [修改地址确认]](http://yapi.xhwx100.com/project/11/interface/api/11835)
 */
export function consigneeAdress ({ commit }, params) {
  const url = '/koala/api/v1.7/textbook/logistics/consignee/use'
  return new Promise((resolve, reject) => {
    $http.put(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      console.log(err)
    })
  })
}

/**
 * [修改地址确认 - 订单详情]](http://yapi.xhwx100.com/project/11/interface/api/13905)
 */
export function consigneeAdressUpdate ({ commit }, params) {
  const url = '/koala/api/v1.5/pay/order/consignee/update/v2'
  return new Promise((resolve, reject) => {
    $http.put(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      console.log(err)
    })
  })
}