var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',[(_vm.visible)?_c('div',{staticClass:"xhwx_xiaoModal",class:[_vm.$style.modal,
      ( _obj = {}, _obj[_vm.$style.center] = _vm.center, _obj[_vm.$style.modal_pc] = _vm.$UA.pc, _obj ),
      ( _obj$1 = {}, _obj$1[_vm.$style.transformDefault] = _vm.transformDefault, _obj$1 ),
      ( _obj$2 = {}, _obj$2[_vm.$style.transformUpDown] = _vm.transformUpDown, _obj$2 ),
      ( _obj$3 = {}, _obj$3[_vm.$style.compatiblePC] = _vm.compatiblePC && _vm.$UA.pc, _obj$3 )
    ],style:(_vm.modalStyle),on:{"touchmove":_vm.onTouchMove}},[(_vm.showDimmer)?_c('div',{class:[_vm.$style.dimmer,( _obj$4 = {}, _obj$4[_vm.$style.dimmerChange] = _vm.dimmerChange, _obj$4 )],style:(_vm.dimmerStyle),on:{"click":function($event){$event.stopPropagation();return _vm.onClickDimmer($event)}}}):_vm._e(),_vm._v(" "),_c('div',{ref:"slotBox",class:[_vm.$style.box, _vm.boxStyle],style:(_vm.slotBoxStyle)},[_vm._t("default"),_vm._v(" "),(_vm.showCloseIcon)?_c('div',{class:_vm.$style.close,style:(_vm.closeStyle),on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}}):_vm._e()],2)]):_vm._e()])
var _obj;
var _obj$1;
var _obj$2;
var _obj$3;
var _obj$4;}
var staticRenderFns = []

export { render, staticRenderFns }