exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.boxStyle_2U-9j{position:absolute;width:100%;bottom:0;background:#fff;border-radius:.5333333333rem .5333333333rem 0px 0px\n}\n.modalHeader_3bPLF{position:relative\n}\n.iconClose_2wUJp{position:absolute;top:.5333333333rem;right:.5333333333rem;font-size:.4266666667rem;color:#b3b3b3\n}\n.titleWrapper_3wj2M{height:1.28rem;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-bottom:.5333333333rem;text-align:center;background:#fafafa;font-size:.4266666667rem;font-weight:500;color:#464646\n}", ""]);

// exports
exports.locals = {
	"boxStyle": "boxStyle_2U-9j",
	"modalHeader": "modalHeader_3bPLF",
	"iconClose": "iconClose_2wUJp",
	"titleWrapper": "titleWrapper_3wj2M"
};