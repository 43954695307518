import * as actions from 'vux/actions/address'

const state = {
  // SelectConsignee 是一个通用的选地址的页面，这个页面选定了地址之后，会把地址的id赋给这个值，然后做跳转
  selectedConsigneeId: '',

  /**
   * 进入选择收货地址的页面时，先把跳转链接保留下来
   * 1.目前有【讲义地址确认】和【订单确认】需要来这个页面，所以选完之后跳到哪里不确定
   * 2.选择收货地址的页面又可能去往【添加收货地址/编辑收货地址】页面，如果不保留在vuex,会丢失
   *
   * */
  selectedConsigneeRedirectUri: ''
}

const mutations = {
  
  SET_SELECTED_CONSIGNEE_ID(state, id) {
    state.selectedConsigneeId = id
  },
  SET_SELECTEDCONSIGNEE_REDIRECT_URI(state, uri) {
    state.selectedConsigneeRedirectUri = uri
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}