const enumGrades = {
  /** 中文名称 */
  "nameCN": {
    "1": "一年级",
    "2": "二年级",
    "3": "三年级",
    "4": "四年级",
    "5": "五年级",
    "6": "六年级",
    "7": "初一",
    "8": "初二",
    "9": "初三",
    "10": "高一",
    "11": "高二",
    "12": "高三"
  },
  /** 抢报 */
  "nameOrderCN": {
    "1": "新小一",
    "2": "新小二",
    "3": "新小三",
    "4": "新小四",
    "5": "新小五",
    "6": "新小六",
    "7": "新初一",
    "8": "新初二",
    "9": "新初三",
    "10": "新高一",
    "11": "新高二",
    "12": "新高三"
  },
  "nameOrderCNNow": {
    "1": "小一",
    "2": "小二",
    "3": "小三",
    "4": "小四",
    "5": "小五",
    "6": "小六",
    "7": "初一",
    "8": "初二",
    "9": "初三",
    "10": "高一",
    "11": "高二",
    "12": "高三"
  },
  /** 排序 */
  "sortedList": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
}

export default enumGrades