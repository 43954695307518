import $http from 'utils/http'
import { stringify } from 'common/qs'
export const getReportDetail = ({commit}, params) => {
  const url = '/koala/api/v1.5/lesson/in-class-report/details'
  return new Promise((resolve, reject) => {
    $http.get(url,{params}).then(res => {
      commit('SET_REPORT_DETAIL', res.data)
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getReportExe = ({commit}, params) => {
  const url = '/koala/api/v1.5/lesson/in-class-report/exercise'
  return new Promise((resolve, reject) => {
    $http.get(url,{params}).then(res => {
      commit('SET_REPORT_INFO', res.data)
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const readReport = ({commit}, params) => {
  const url = '/koala/api/v1.5/lesson/in-class-report/read'
  return new Promise((resolve, reject) => {
    $http.put(url, stringify(params)).then(res => {
      resolve(true)
    }).catch(err => {
      reject(err)
    })
  })
}

export const getTikuTitle = ({commit}, params) => {
  const url = '/koala/api/v1.5/lesson/teaching/in_class_exercise/info'
  return new Promise((resolve, reject) => {
    $http.post(url, params)
      .then(res => {
        commit('SET_TIKU_LIST', res.data.questionInfo)
        resolve(res)
      })
  })
}

export const resetTikuTitle = ({commit}) => {
  commit('RESET_TIKU_TITLE')
}
