import {
  api_getCourseDetail,
  api_getExperienceCourseDetail,
  api_getCourseSchedule,
  api_getReportStatus,
  api_getSharePromotion,
  api_getPriceBreak
} from 'src/api/course'

import {
  api_getTeacherInfo
} from 'src/api/teacher'

/** 课程详情 */
export function getCourseDetail({ commit }, params) {
  const api_getDetail = params.isExperience ? api_getExperienceCourseDetail : api_getCourseDetail
  const isExperience = params.isExperience
  if (isExperience) {
    delete params.isExperience
  }
  return api_getDetail(params).then(resp=>{
    let respData = resp.data
    if (isExperience) {
      commit('GET_COURSE_DETAIL', respData)
      return respData
    } else {
      return api_getSharePromotion(params).then(res=>{
        respData.promotion = res.data.promotion
        commit('GET_COURSE_DETAIL', respData)
        return respData
      }).catch(err=>{
        commit('GET_COURSE_DETAIL', respData)
        return respData
      })
    }
  })
}

/** 满减优惠信息 */
export function getPriceBreak({ commit }, params) {
  return api_getPriceBreak(params).then(resp => {
    commit("SET_PRICE_BREAK", resp.data)
    return resp.data
  })
}

/** 主讲老师信息 */
export function getCourseTeacher({ commit }, params) {
  return api_getTeacherInfo(params).then(resp => {
    commit("SET_COURSE_TEACHER", resp.data)
    return resp.data
  })
}

/** 课程课表 */
export function getCourseSchedule({ commit }, params) {
  return api_getCourseSchedule(params).then(resp => {
    commit("GET_COURSE_SCHEDULE", resp.data.lessons)
    return resp.data
  })
}

/** 课堂报告状态 */
export function getReportStatus({ commit }, params) {
  return api_getReportStatus(params).then(resp => {
    commit("SET_CLASS_REPORT", resp.data)
    return resp.data
  })
}

export const resetCourseInfo = ({commit}) => {
  commit('RESET_COURSE_INFO')
}
