exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.modal_3FTPa[data-v-72f903e2]{width:7.73333333rem;background:#fff;border-radius:.26666667rem;position:fixed;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);padding:.64rem .53333333rem\n}\n.modal_3FTPa .title_2Ja4u[data-v-72f903e2]{font-size:.48rem;color:#464646\n}\n.modal_3FTPa .content_1BsQo[data-v-72f903e2]{font-size:.4rem;color:#5a5a5a;margin-top:.32rem;margin-bottom:.53333333rem\n}\n.modal_3FTPa .btn_1nb4n[data-v-72f903e2]{width:6.66666667rem;height:1.17333333rem;line-height:1.17333333rem;background:#00c55d;border-radius:.58666667rem;text-align:center;font-size:.42666667rem;color:#fff\n}", ""]);

// exports
exports.locals = {
	"modal": "modal_3FTPa",
	"title": "title_2Ja4u",
	"content": "content_1BsQo",
	"btn": "btn_1nb4n"
};