import * as actions from 'vux/actions/logistics'

const state = {
  logisticsList: [], // 物流列表数据
  traceList: [] // 查看物流列表
}

const mutations = {
  SET_Logistics_List (state, list) {
    state.logisticsList = list
  },
  SET_Trace_List (state, list) {
    state.traceList = list
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
}
