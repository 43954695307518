export default <template>
  <div>
    <div :class="$style.wrapper">
      <div :class="$style.inputWrapper">
        <label :class="$style.label" v-if="!!this.label" @click="inputFocus">{{this.label}}</label>
        <input type="text" ref="input" :value="captcha" @input="handleCaptchaChange" maxlength="4" placeholder="4位纯数字" :class="[$style.captchaInput, {[$style.needTip]: isWrongCaptcha, [$style.padding]:!!label}]">

        <div :class="$style.right">
          <div :class="$style.tips" v-show="isWrongCaptcha && showTip">*验证码不匹配</div>
          <div v-show="captcha.length" @click="handleClear" :class="$style.closeIcon" class="xiaoicon">&#xe686;</div>
        </div>
      </div>
      <button type="button" :class="[$style.btn, isPhoneValid ? (isNewStyle ? $style.newValid: $style.valid) : $style.disabled, countDownRunning ? (isNewStyle ? $style.newIsRunning: $style.isRunning) : '']" :disabled="countDownRunning || !isPhoneValid" @click="fetchCaptcha">
        <span v-if="countDownRunning">重新获取{{countdownInstance._currentTime}}s</span>
        <span v-else>获取验证码</span>
      </button>
      <NoCaptcha ref="NoCaptcha" scene="message" @pass="handlePassNoCatcha"></NoCaptcha>
    </div>
    <div style="position:relative;">
      <div :class="$style.phone__tips" v-if="showVoice">
        <template v-if="showVoiceBt">
          <template v-if="showResult">
            <i class="xiaoicon">&#xe760;</i>
            <span style="margin-left:0.05rem;">语音验证码已发送，请注意接听电话</span>
          </template>
          <template v-else>
            <span>收不到验证码？试试</span>
            <a @click.stop="toShowVoice">语音验证码</a>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module src="./FetchCaptcha.module.scss"></style>


<script>
import { api_getCaptcha, api_getVoiceCode } from 'api/account'
import CountDown from 'xiao-utils/countdown'
import NoCaptcha from '../components/NoCaptcha.vue'
import { phoneRegExp } from 'utils/regexp'

export default {
  components: { NoCaptcha },
  props: {
    isNewStyle: {
      type: Boolean,
      default: false
    },
    phone: String,
    // 是否立即发起验证码请求
    shouldFetchImmediate: {
      type: Boolean,
      default: false
    },
    isWrongCaptcha: {
      type: Boolean,
      default: false
    },
    showTip: {
      default: true,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    showVoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      captcha: '',
      countdownInstance: null,
      noCaptchaData: {
        afs_token: '',
        afs_sig: '',
        afs_session_id: '',
        afs_scene: ''
      },
      hadClick: false,
      showVoiceBt: false,
      showResult: false
    }
  },
  computed: {
    countDownRunning() {
      return this.countdownInstance && this.countdownInstance._isRunning
    },
    params() {
      let ret = {
        phone: this.trimedPhone
      }
      if (this.noCaptchaData.afs_token) {
        ret.afs_token = this.noCaptchaData.afs_token
        ret.afs_sig = this.noCaptchaData.afs_sig
        ret.afs_session_id = this.noCaptchaData.afs_session_id
        ret.afs_scene = this.noCaptchaData.afs_scene
      }
      return ret
    },
    isPhoneValid() {
      return phoneRegExp.test(this.trimedPhone)
    },
    trimedPhone() {
      return this.phone.replace(/\s+/g, '')
    }
  },
  methods: {
    handlePassNoCatcha(data) {
      this.noCaptchaData = data
      if (this.autoVoiceSend) {
        // 语音验证码参数
        this.autoVoiceSend = false
        this.toShowVoice()
      } else {
        this.fetchCaptcha()
      }
    },
    handleClear() {
      this.captcha = ''
      this.$emit('captchaChange', this.captcha)
      this.$refs.input.focus()
    },
    handleCaptchaChange($event) {
      this.captcha = $event.target.value
      this.$emit('captchaChange', this.captcha)
    },
    fetchCaptcha() {
      api_getCaptcha(this.params)
        .then(() => {
          let timeCount = 1 // 控制触发次数
          this.countdownInstance = new CountDown(60, {
            update: val => {
              if (!this.countdownInstance) {
                return null
              }
              if (val <= 45 && timeCount > 0) {
                timeCount--
                this.showVoiceCodeBt()
                this.$emit('touchShowVoice')
              }
            },
            finish: () => {
              // 原本倒计时结束时显示 改为
              // if (this.countdownInstance && !this.countdownInstance._isRunning) {
              //   this.showVoiceCodeBt()
              //   this.$emit('touchShowVoice')
              // }
            }
          })
          this.countdownInstance.start()
        })
        .catch(err => {
          if (err.response && err.response.data.err === 40105) {
            this.$refs.NoCaptcha.toggleVisible(true)
          } else if (err.response && err.response.data.err === 40104) {
            if (err.response.data.data.limited_by === 'min') {
              this.$xiaoToast.warning('发送过于频繁，请稍后再试')
            } else if (err.response.data.data.limited_by === 'hour') {
              this.$xiaoToast.warning('发送过于频繁，请在1小时后重试')
            } else if (err.response.data.data.limited_by === 'day') {
              this.$xiaoToast.warning('发送过于频繁，请明天再试')
            }
          }
        })
    },
    handleDestroy() {
      if (this.countDownRunning) {
        localStorage.setItem(
          'captchaCountdownLeft',
          this.countdownInstance._currentTime
        )
        localStorage.setItem('captchaCountdownNow', Date.now())
      }
      this.countdownInstance && this.countdownInstance.destroy()
    },
    inputFocus() {
      this.$refs.input.focus()
    },
    showVoiceCodeBt() {
      this.showVoiceBt = true
    },
    toShowVoice() {
      if (!this.isPhoneValid) {
        console.log('手机号码不正确')
        return null
      }
      let params = {
        phone: this.trimedPhone
      }
      if (this.noCaptchaData.afs_token) {
        params.afs_token = this.noCaptchaData.afs_token
        params.afs_sig = this.noCaptchaData.afs_sig
        params.afs_session_id = this.noCaptchaData.afs_session_id
        params.afs_scene = this.noCaptchaData.afs_scene
      }
      api_getVoiceCode(params)
        .then(() => {
          console.log('语言验证码发送成功')
          this.showResult = true
        })
        .catch(err => {
          if (err.response && err.response.data.err === 40105) {
            this.$refs.NoCaptcha.toggleVisible(true)
            this.autoVoiceSend = true
          } else if (err.response && err.response.data.err === 40104) {
            if (err.response.data.data.limited_by === 'min') {
              this.$xiaoToast.warning('发送过于频繁，请稍后再试')
            } else if (err.response.data.data.limited_by === 'hour') {
              this.$xiaoToast.warning('发送过于频繁，请在1小时后重试')
            } else if (err.response.data.data.limited_by === 'day') {
              this.$xiaoToast.warning('发送过于频繁，请明天再试')
            }
          } else {
            this.$xiaoToast.warning('未知异常')
          }
        })
    }
  },
  created() {
    let captchaCountdownNow = localStorage.getItem('captchaCountdownNow')
      ? Number(localStorage.getItem('captchaCountdownNow'))
      : 0
    let captchaCountdownLeft = localStorage.getItem('captchaCountdownLeft')
      ? Number(localStorage.getItem('captchaCountdownLeft'))
      : 0
    if (Date.now() > captchaCountdownNow + captchaCountdownLeft * 1000) {
      localStorage.removeItem('captchaCountdownLeft')
      localStorage.removeItem('captchaCountdownNow')
      if (this.shouldFetchImmediate) {
        this.fetchCaptcha()
      }
    } else {
      let countdownTime = parseInt(
        (captchaCountdownNow + captchaCountdownLeft * 1000 - Date.now()) / 1000
      )
      let timeCount = 1
      this.countdownInstance = new CountDown(countdownTime, {
        update: val => {
          if (!this.countdownInstance) {
            return null
          }
          if (val <= 45 && timeCount > 0) {
            timeCount--
            this.showVoiceCodeBt()
            this.$emit('touchShowVoice')
          }
        },
        finish: () => {
          // if (this.countdownInstance && !this.countdownInstance._isRunning) {
          //   this.showVoiceCodeBt()
          //   this.$emit('touchShowVoice')
          // }
        }
      })
      this.countdownInstance.start()
    }

    window.addEventListener('beforeunload', this.handleDestroy)
  },
  beforeDestroy() {
    this.handleDestroy()
  }
}
</script>