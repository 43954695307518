import { ossImageFormatter } from 'xiao-utils/oss'

const ossAvatarFormatterCached = Object.create(null)
const ossRectFormatterCached = Object.create(null)

const DevicePixelRatio = window.devicePixelRatio

export function ossAvatarFormatter(size) {
  if (!ossAvatarFormatterCached[size]) {
    const drpSize = Math.ceil(DevicePixelRatio) * size
    // 对应的 OSS 后缀为 ?x-oss-process=image/format,jpg/interlace,1/resize,m_mfit,w_$size,h_$size/crop,x_0,y_0,w_$size,h_$size,g_se
    ossAvatarFormatterCached[size] = ossImageFormatter({
      format: 'jpg',
      interlace: 1,
      resize: {
        m: 'mfit',
        w: drpSize,
        h: drpSize
      },
      crop: {
        x: 0,
        y: 0,
        w: drpSize,
        h: drpSize,
        g: 'se'
      }
    })
  }
  return ossAvatarFormatterCached[size]
}

export function ossAvatarFormat(src, size) {
  const formatter = ossAvatarFormatter(size)
  return formatter(src)
}

export function ossRectFormatter(width, height) {
  if (typeof width !== 'number' || width <= 0) {
    throw new Error('width必须为正数')
  }
  if (typeof height !== 'number' || height <= 0) {
    height = 'auto'
  }
  const cacheKey = `${width}x${height}`
  if (!ossRectFormatterCached[cacheKey]) {
    const drpWidth = Math.ceil(DevicePixelRatio) * width
    const formatterString = ({
      format: 'jpg',
      interlace: 1,
      resize: {
        m: 'mfit',
        w: drpWidth
      }
    })
    if (height !== 'auto') {
      const drpHeight = Math.ceil(DevicePixelRatio) * height
      formatterString.resize.h = drpHeight
      formatterString.crop = {
        x: 0,
        y: 0,
        w: drpWidth,
        h: drpHeight,
        g: 'se'
      }
    }
    ossRectFormatterCached[cacheKey] = ossImageFormatter(formatterString)
  }
  return ossRectFormatterCached[cacheKey]
}

export function ossRectFormat(src, width, height) {
  const formatter = ossRectFormatter(width, height)
  return formatter(src)
}