exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.wrapper_2-O2j{position:relative;width:100%\n}\n.label_2E0Id{position:absolute;left:0;top:56%;-webkit-transform:translateY(-50%);transform:translateY(-50%);font-size:.3733rem;color:#5a5a5a\n}\n.phoneInput_3KCBv{height:1.28rem;border:none;width:100%;border-bottom:.02rem solid #ccc;font-size:.64rem;outline:none;line-height:1;color:#464646;font-family:xiaonum;caret-color:#00c55d;background:none;-webkit-user-select:text !important;-moz-user-select:text !important;-ms-user-select:text !important;user-select:text !important\n}\n.phoneInput_3KCBv:focus{border-color:#00c55d\n}\n.phoneInput_3KCBv::-webkit-input-placeholder{font-size:.37rem;color:#ccc\n}\n.phoneInput_3KCBv::-ms-input-placeholder{font-size:.37rem;color:#ccc\n}\n.phoneInput_3KCBv::placeholder{font-size:.37rem;color:#ccc\n}\n.phoneInput_3KCBv:-webkit-autofill{transition:background-color 5000s ease-in-out 0s\n}\n.phoneInput_3KCBv.padding_-PbNs{padding-left:1.973333rem\n}\n.phoneInput_3KCBv.needTip_14uLt{border-color:#fd7663\n}\n.iosInput_1cR1J::-webkit-input-placeholder{padding-top:.37rem\n}\n.iosInput_1cR1J::-ms-input-placeholder{padding-top:.37rem\n}\n.iosInput_1cR1J::placeholder{padding-top:.37rem\n}\n.closeIcon_1kaYx{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);color:#ccc;font-size:.533333rem\n}\n.closeIcon_1kaYx:active{color:#8a8a8a\n}\n.tips_jghA1{position:absolute;right:.853333rem;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);color:#fd7663;font-size:.346667rem;line-height:1\n}", ""]);

// exports
exports.locals = {
	"wrapper": "wrapper_2-O2j",
	"label": "label_2E0Id",
	"phoneInput": "phoneInput_3KCBv",
	"padding": "padding_-PbNs",
	"needTip": "needTip_14uLt",
	"iosInput": "iosInput_1cR1J",
	"closeIcon": "closeIcon_1kaYx",
	"tips": "tips_jghA1"
};