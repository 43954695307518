// 专门为了微信 sdk 做的

const state = {
  url: ''
}

const mutations = {
  setUrl(state, { url }) {
    state.url = url
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
