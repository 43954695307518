import store from 'vux/store'
const city = sessionStorage.getItem('city')
if (city) {
  store.commit('course/SET_CITY', city)
} else {
  setTimeout(() => {
    store.dispatch('course/getCity').then(() => {
      // 初始触发用
      localStorage.setItem('syncCityInitTrigger', 1)
      localStorage.removeItem('syncCityInitTrigger')
    })
  }, 1000)
}


function handleStorageChange(event) {
  // 这个判断不能改  一定要判断是不是null
  if(event.newValue === null) return
  if (event.key == 'syncCityInitTrigger') {
    //接收其他 tab 来的 trigger, 把 city 再传回去
    let city = sessionStorage.getItem('city')
    if(/^\d+$/.test(city)) {
      localStorage.setItem('syncCityFromLocalStorage', city)
      // 立即移除掉是因为，如果localStorage的值保持不变，就不会触发事件了
      localStorage.removeItem('syncCityFromLocalStorage')
    }
  } else if (event.key == 'syncCityFromLocalStorage') {
    store.commit('course/SET_CITY', event.newValue)
  }
}

window.addEventListener("storage", handleStorageChange, false)

