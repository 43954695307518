function getActivityShareParams() {
  const params = sessionStorage.getItem('activity_share_params')
  if (!params) {
    return params
  }
  let parsedParams = null
  try {
    parsedParams = JSON.parse(params)
  } catch (err) {
    // @todo 上报 sentry
  }
  return parsedParams
}

function setActivityShareParams(query) {
  if (query && (query.activity_id || query.share_id || query.grade)) {
    const params = getActivityShareParams() || {}
    if (query.activity_id) {
      params.activity_id = query.activity_id
    }
    if (query.activity_inviter_id) {
      params.activity_inviter_id = query.activity_inviter_id
    }
    if (query.share_id) {
      params.share_id = query.share_id
    }
    if (query.grade) {
      params.grade = query.grade
    }
    sessionStorage.setItem('activity_share_params', JSON.stringify(params))
  }
}

export const activityShareSession = {
  getActivityShareParams,
  setActivityShareParams,
}
