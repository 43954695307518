export default <template>
  <XiaoModal :visible.sync="visible" :box-style="$style.box" :showCloseIcon="false" :closeOnClickDimmer="false">
    <div :class="$style.title">安全验证</div>
    <div :class="$style.desc">请拖动滑块完成验证</div>
    <div id="NoCaptcha" :class="$style.noCaptchaWrapper"></div>
    <!--No-Captcha渲染的位置，其中 class 中必须包含 nc-container-->
  </XiaoModal>
</template>
<style lang="scss" module src="./NoCaptcha.module.scss"></style>
<style lang="scss" src="./NoCaptcha.scss"></style>
<script>
import XiaoModal from 'components/xiao-modal/XiaoModal.vue'
export default {
  props: {
    scene: {
      type: String,
      default: 'other'
    },
  },
  components: { XiaoModal },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    formattedScene() {
      if (this.scene === 'login') {
        return 'nc_login_h5'
      } else if (this.scene === 'message') {
        return 'nc_message'
      } else {
        return 'nc_other'
      }
    } 
  },
  methods: {
    toggleVisible(val) {
      this.visible = val
    },
    initNoCaptcha() {
      var nc_token = ["FFFF000000000178E014", (new Date()).getTime(), Math.random()].join(':')
      var nc = NoCaptcha.init({
        renderTo: '#NoCaptcha',
        appkey: 'FFFF000000000178E014',
        scene: this.formattedScene,
        token: nc_token,
        trans: { "key1": "code200" },
        elementID: ["usernameID"],
        is_Opt: 0,
        language: "cn",
        timeout: 10000,
        retryTimes: 5,
        errorTimes: 5,
        inline: false,
        apimap: {
          // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
          // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
        },
        bannerHidden: false,
        initHidden: false,
        callback: data => {
          this.visible = false
          this.$emit('pass', {
            afs_token: nc_token,
            afs_sig: data.sig,
            afs_session_id: data.csessionid,
            afs_scene: 'FFFF000000000178E014'
          })
        }
      })
      NoCaptcha.setEnabled(true)
      NoCaptcha.upLang('cn', {
        'LOADING': "加载中...", //加载
        'SLIDER_LABEL': "拖动滑动以完成验证", //等待滑动
        'CHECK_Y': "验证通过", //通过
        'ERROR_TITLE': "出错了，请刷新一下", //拦截
        'CHECK_N': "验证失败", //准备唤醒二次验证
        'OVERLAY_INFORM': "经检测你当前操作环境存在风险，请输入验证码", //二次验证
        'TIPS_TITLE': "验证码错误，请重新输入" //验证码输错时的提示
      })
      nc.reset() //请务必确保这里调用一次reset()方法

    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.initNoCaptcha()
        })
      }
    }
  }
}

</script>
