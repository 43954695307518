import http from 'utils/http'

/**
 * api/v1.5/location/check
 * https://gitlab.xinghuolive.com/xiaozhibo_service/koala/blob/master/location.md?spm=a2c40.cloud_prod_task_detail.0.0.12d57859YJGTFV&file=location.md#%E5%88%A4%E6%96%AD%E6%98%AF%E5%90%A6%E9%9C%80%E8%A6%81%E5%A1%AB%E6%89%80%E5%9C%A8%E5%9F%8E%E5%B8%82
 */
export const api_checkStudentCity = () => {
  const url = '/koala/api/v1.5/location/check'
  return http.get(url, { params: {_: Date.now()}})
}

/**
 * api/v1.5/location/update
 * https://gitlab.xinghuolive.com/xiaozhibo_service/koala/blob/master/location.md?spm=a2c40.cloud_prod_task_detail.0.0.12d57859YJGTFV&file=location.md#%E5%88%A4%E6%96%AD%E6%98%AF%E5%90%A6%E9%9C%80%E8%A6%81%E5%A1%AB%E6%89%80%E5%9C%A8%E5%9F%8E%E5%B8%82
 */
export const api_setStudentCity = params => {
  const url = '/koala/api/v1.5/location/update'
  return http.post(url, params)
}

// 根据ip获取地址
export  const api_getCityByIp = params => {
  const url = '/koala/api/v1.5/curriculum/ip/address/info'
  return http.get(url, {params})
}

// 获取下级地址码-省份排序 【http://yapi.xhwx100.com/project/11/interface/api/22552】
export  const api_getSortLocation = params => {
  const url = '/koala/api/v1.7/location/lower-list-sort'
  return http.get(url, {params})
}
// 获取省-市-区地址码 【http://yapi.xhwx100.com/project/11/interface/api/22232】
export  const api_getLocationDetail = params => {
  const url = '/koala/api/v1.7/location/admin-code'
  return http.get(url, {params})
}


// 搜索城市 [http://yapi.xhwx100.com/project/11/interface/api/28714]
export  const api_searchLocation = (params, config) => {
  const url = '/koala/api/v1.7/location/search-location'
  return http.get(url, { params, ...config })
}

// 推荐城市 [http://yapi.xhwx100.com/project/11/interface/api/28712]
export  const api_getRecommendLocation = (params) => {
  const url = '/koala/api/v1.5/personal_center/recommend-location'
  return http.get(url, { params })
}