// 依赖axios 封装请求
import axios from 'axios'
import globalCookieHandler from 'src/store/cookie'
import xiaoToast from 'utils/xiao-toast'

export function authGetter() {
  return globalCookieHandler.token
}

const instance = axios.create({
  baseURL: `//${__API_SERVICE_HOST__}`,
  headers: {
    'Xiao-User-Agent': 'h5'
  }
})

instance.interceptors.request.use(
  config => {
    config.headers['Authorization'] = authGetter()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 拦截响应
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      globalCookieHandler['token'] = null
      globalCookieHandler['user_id'] = null
    } else if (error.response && error.response.status === 406) {
      xiaoToast.warning(error.response.data.msg || '操作失败')
    }
    return Promise.reject(error)
  }
)

export default instance