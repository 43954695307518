import http from 'utils/http'

// auth code 登录
export function api_authLogin(params) {
  const url = '/koala/api/v1.5/auth/login'
  return http.get(url, { params })
}

/**
 * @deprecated
 */
export function api_getUserInfo(params) {
  const url = '/koala/api/v1.5/user/info'
  return http.get(url, { params })
}

export function api_getStudentStatistics() {
  const url = '/koala/api/v1.5/personal_center/statistics'
  return http.get(url)
}

/**
 * 这个接口用来替换原来的 api_getUserInfo 和 api_getStudentStatistics
 */
export function api_getMyInfo() {
  const url = '/koala/api/v1.5/personal_center/my-info'
  return http.get(url)
}