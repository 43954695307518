import http from "utils/http"
import http1 from "utils/http1"

/**
 * 提交主观题答案
 * @param {object} params
 * @param {string} [params.lesson_id] - 课次id
 * @param {string[]} [params.answer_img_list] - 图片名字和后缀
 */
export function api_subjectsSelect(params) {
  const url = "/koala/api/v1.5/curriculum/subjects"
  return http.post(url, params)
}

/**
 * [获取出堂诊断主观题详情](http://yapi.xhwx100.com/mock/11/api/v1.7/lesson/after-class-exercise/subjective/content68)
 * @param {number} lesson_id
 */
export function api_getSubjective(params) {
  const url = "/koala/api/v1.7/lesson/after-class-exercise/subjective/content"
  return http.get(url, { params })
}

/**
 * [获取同步练习题详情](http://yapi.xhwx100.com/project/11/interface/api/26543)
 * @param {number} curriculum_id
 */
export function api_getSyncExerciseDetail(params){
  const url = '/koala/api/v1.7/sync-exercise/lesson/content'
  return http.get(url, { params })
}

/**
 * 选课接口
 * @param {object} params
 * @param {number} params.page_num - 第几页，从1开始
 * @param {number} params.num_per_page - 每页多少门课程
 * @param {string} params.latest - 最新， 取值 'asc' 或 'desc'
 * @param {string} params.popularity - 人气， 取值 'asc' 或 'desc'
 * @param {string} params.money - 价格， 取值 'asc' 或 'desc'
 * @param {string[]} [params.curriculum_id_list]
 * @param {number[]} [params.grade_list] - 年级
 * @param {number[]} [params.subject_list] - 学科
 * @param {number[]} [params.genre_list] - 类型（长期课、短期课、免费公开课等）
 * @param {number[]} [params.quarter_list] - 季度
 * @param {number} [params.year] - 年份（和季度一起使用）
 * @param {number} [params.quarter] - 季度（和年份一起使用）
 * @param {number} [params.broadcast_status] - 直播状态: 1为直播, 0未直播
 * @param {number[]} [params.price] - 价格范围
 * @param {string} [params.album_id] - 精品推荐专辑ID
 */
export function api_curriculumSelect(params) {
  const url = "/koala/api/v1.6/curriculum/select"
  return http.post(url, params)
}

export function api_getIntroduction(params) {
  const url = "/koala/api/v1.5/curriculum/description/list"
  return http.get(url, { params }).then((res) => {
    const resp = { ...res }
    const url = (resp.data.oss_content && resp.data.oss_content.oss_url) || ""
    return new Promise((resolve, reject) => {
      if (url) {
        api_getAnonymous(url)
          .then((response) => {
            resp.data.content_list = response.data || []
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        resolve(resp)
      }
    })
  })
}

/**
 * [抢报系统活动获取课程介绍](http://yapi.xhwx100.com/project/11/interface/api/14301)
 * @param {Object} params
 */
export function api_reportingActivityIntro(params) {
  const url = "/koala/api/v1.7/reporting-activity/group-buying/desc"
  return http.get(url, { params }).then((res) => {
    const resp = { ...res }
    const url = resp.data.desc_url
    return new Promise((resolve, reject) => {
      if (url) {
        api_getAnonymous(url)
          .then((response) => {
            resp.data.content_list = response.data || []
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        resolve(resp)
      }
    })
  })
}

/**
 * [请求get]
 */
export function api_getAnonymous(url, params) {
  return http.get(url, { params })
}

/**
 * 课程详情
 * @param {object} params
 * @param {string} params.curriculum_id
 */
export function api_getCourseDetail(params) {
  const url = "/koala/api/v1.5/curriculum/details"
  params._ = Date.now()
  return http.get(url, { params })
}

export function api_getExperienceCourseDetail(params) {
  const url = "/koala/api/v1.7/special-curriculum/curriculum-detail"
  params._ = Date.now()
  return http.get(url, { params })
}

export function api_preCheckBeforeOrder(params) {
  const url = "/koala/api/v1.7/special-curriculum/order-pre-check"
  return http.post(url, params)
}

/**课程 分享解锁活动*/
export function api_getSharePromotion(params) {
  const url = "/koala/api/v1.5/curriculum/share-promotion"
  return http.get(url, { params })
}

/** 联报优惠信息 */
export function api_getCombDiscounts(params) {
  const url = "/koala/api/v1.5/curriculum/combination-discount/list"
  return http.get(url, { params })
}

/**
 * [获取课程详情满减优惠](http://yapi.xhwx100.com/project/11/interface/api/10683)
 * @param {*} params
 */
export function api_getPriceBreak(params) {
  const url = "/koala/api/v1.5/curriculum/price-break-info"
  return http.get(url, { params })
}

/** 课程课表 */
export function api_getCourseSchedule(params) {
  const url = "/koala/api/v1.5/curriculum/schedule"
  return http.get(url, { params })
}

/** 课堂报告状态 */
export function api_getReportStatus(params) {
  const url = "/koala/api/v1.5/lesson/in-class-report/status"
  return http.get(url, { params })
}

/** 1对1课程详情 */
export function api_getOtoCourseDetail(params) {
  const url = "/sloth/api/v2.0/lesson/curriculum/detail"
  return http1.get(url, { params })
}

/** 1对1课程表 */
export function api_getOtoCourseSchedule(params) {
  const url = "/sloth/api/v2.0/lesson/h5/curriculum/detail/list"
  return http1.get(url, { params })
}

// 预约VIP课程
export function api_applyVipFreeTrial(params) {
  const url = "/sloth/api/v1.0/lesson/audition/promise/by/phone"
  return http1.post(url, params)
}

/** 获取题库详情 */
export function api_getTikuTitles(params) {
  const url = "/koala/api/v1.5/lesson/teaching/in_class_exercise/info"
  return http.post(url, params)
}

/** 获取1对1预约试听名师列表 */
export function api_getOtoRegisterTeacherList(params) {
  const url = "/koala/api/v1.6/promise/audition/oto-teacher-cube/list"
  return http.get(url, { params })
}

/** 获取1对1预约试听banner信息*/
export function api_getOtoRegisterBanner(params) {
  const url = "/koala/api/v1.6/promise/audition/banner/info"
  return http.get(url, { params })
}
/** 1对1预约试听发送验证码*/
export function api_sendOtoRegisterCaptcha(params) {
  const url = "/koala/api/v1.6/promise/audition/captcha/send"
  return http.post(url, params)
}

/**
 * [返券信息](https://gitlab.xinghuolive.com/xiaozhibo_service/koala/blob/master/ex_v1_5_coupon_template.md)
 * @param {object} params
 * @param {String[]} params.curriculum_id_list - 购买的课程ID
 */
export function api_getReturnCouponInfo(params) {
  const url = "/koala/api/v1.5/coupon-template/pay/return/got_list"
  return http.post(url, params)
}

/**
 * [领取购课返券](https://gitlab.xinghuolive.com/xiaozhibo_service/koala/blob/master/ex_v1_5_coupon_template.md)
 * @param {object} params
 * @param {String[]} params.template_id_list - 优惠卷模板IDList
 */
export function api_takeReturnCoupon(params) {
  const url = "/koala/api/v1.5/coupon-template/pay/return/get"
  return http.post(url, params)
}

/**
 * [获取推荐课程](候补)
 * @param {object} params
 * @param {Array[]} params.curriculum_id_list - 系列课id
 */
export function api_getRecommendCourse(params) {
  const url = "/koala/api/v1.5/curriculum/recommend"
  return http.post(url, params)
}

/**
 * 入学诊断
 * [课程判断](http://dev-yapi.xhwx100.com/project/11/interface/api/4650)
 */
export function api_checkQuestionnaire(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/curriculum"
  return http.get(url, { params })
}

/**
 * [联报判断](http://dev-yapi.xhwx100.com/project/11/interface/api/4646)
 */
export function api_checkCombineQuestionnaire(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/combination"
  return http.get(url, { params })
}

/**
 * [单独课程基础信息](http://yapi.xhwx100.com/project/11/interface/api/4566)
 * @param {*} params
 */
export function api_getQuestionaireDetail(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/info"
  return http.get(url, { params })
}

/**
 * [联报基础信息](http://dev-yapi.xhwx100.com/project/11/interface/api/4750)
 */
export function api_getCombineQuestionaireDetail(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/info/combination"
  return http.get(url, { params })
}

/**
 * [新版联报做题](http://dev-yapi.xhwx100.com/project/11/interface/api/4750)
 * @param {object} params
 * @param {string} params.joint_discount_id - 联报id
 */
export function api_getJointQuestionnaire(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/info/joint"
  return http.post(url, params)
}

/**
 * [试卷信息（出题）](http://yapi.xhwx100.com/project/11/interface/api/4570)
 */
export function api_startAdmissionTest(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/paper"
  return http.get(url, { params })
}

/**
 * [提交试卷](http://dev-yapi.xhwx100.com/project/11/interface/api/4578)
 */
export function api_submitQuestionaire(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/submit"
  return http.post(url, params)
}

// /**
//  * [联报提交试卷](http://dev-yapi.xhwx100.com/project/11/interface/api/4754)
//  */
// export function api_submitCombineQuestionaire(params) {
//   const url = '/koala/api/v1.6/curriculum/questionnaire/submit/combination'
//   return http.post(url, params)
// }

/**
 * [答题草稿](http://dev-yapi.xhwx100.com/project/11/interface/api/4582)
 */
export function api_submitQuestionaireTemp(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/draft"
  return http.post(url, params)
}

/**
 * [入学诊断推荐课程](http://dev-yapi.xhwx100.com/project/11/interface/api/4574)
 */
export function api_getQuestionaireIntroduce(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/recommend"
  return http.get(url, { params })
}

/**
 * [入学诊断联报测试结果](http://dev-yapi.xhwx100.com/project/11/interface/api/4754)
 */
export function api_getCombineQuestionaireResult(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/exam/combination"
  return http.get(url, { params })
}

/**
 * [新版联报考试结果](http://dev-yapi.xhwx100.com/project/11/interface/api/4754)
 * @param {object} params
 * @param {string} params.joint_discount_id - 联报id
 * @param {string} params.paper_id - 试卷id
 */
export function api_getJointQuestionnaireResult(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/exam/joint"
  return http.post(url, params)
}

/**
 * [入学诊断自助换课入学诊断推荐](http://yapi.xhwx100.com/project/11/interface/api/8034)
 * @param {*} params
 */
export function api_getChangeCourseQuestionaire(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/recommend/change"
  return http.get(url, { params })
}

/**
 * [重做测试](http://dev-yapi.xhwx100.com/project/11/interface/api/4994)
 */
export function api_QuestionnaireRedo(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/paper/redo"
  return http.get(url, { params })
}

/**
 * [入学诊断报告详情](http://yapi.xhwx100.com/project/11/interface/api/11133)
 * @author [liminshi](http://yapi.xhwx100.com/user/profile/27)
 * @param {object} params - 请求参数
 * @param {string} [params.student_id] -
 * @param {string} [params.questionnaire_paper_id] - 试卷id
 * @param { import('axios').AxiosRequestConfig } options - 其它axios配置，例如`timeout`、`cancelToken`等
 * @return { import('axios').AxiosPromise<{ oss_url: string }> }
 */
export function api_curriculumQuestionnaireReportInfo(params, options) {
  return http.get("/koala/api/v1.6/curriculum/questionnaire/report/info", {
    params,
    options,
  })
}

// 提交主观题答案
export function subjectiveSubmit(params) {
  const url = "/koala/api/v1.7/lesson/after-class-exercise/subjective/submit"
  return http.post(url, params)
}

// 提交同步练习答案
export function syncExerciseSubmit(params) {
  const url = '/koala/api/v1.7/sync-exercise/upload'
  return http.put(url, params)
}
/**
 * [下单课程检查](http://yapi.xhwx100.com/project/11/interface/api/18207)
 */
export function api_getCurriculumQuarterCheck(params) {
  const url = "/koala/api/v1.5/curriculum/order-curriculum-check"
  return http.post(url, params)
}

/**
 * [获取入学诊断报告id]（http://yapi.xhwx100.com/project/27/interface/api/19665）
 */
export function api_getReportId(params) {
  const url = "/koala/api/v1.6/curriculum/questionnaire/report/id"
  return http.get(url, { params })
}

/**
 * [查询是否购买课程]（http://yapi.xhwx100.com/project/27/interface/api/20259）
 */
export function curriculumBuyStatusApi(params) {
  const url = "/koala/api/v1.6/curriculum/buy/status"
  return http.get(url, { params })
}

/**
 * [判断学生有没有购买过长期课]
 */
export function api_getLongTerm(params) {
  const url = "/koala/api/v1.7/pay/order/have-bought/long-term"
  return http.get(url, { params })
}

/**
 * 获取班型列表
 */
export const api_getLevelList = () => {
  let url = "/gorilla/api/v1.5/infrastructure/level/list"
  let params = {
    start: 0,
    length: 50,
  }
  return http.post(url, params)
}

// 精品课推荐专项
/**
 * 判断是否特殊精品推荐
 */
export const api_getAblumIfSpecial = (params) => {
  const url = "/koala/api/v1.5/curriculum/album/is_special"
  return http.get(url, { params })
}

/**
 * 特殊精品推荐购物车课程详情信息
 */
export const api_getSpecialAblumCourseInfor = (params) => {
  const url = "/koala/api/v1.5/curriculum/info/by/special_album"
  return http.post(url, params)
}

/**
 * [获取推荐课程包含：课程列表id列表,获取可筛选季度,可筛选讲义类型](http://yapi.xhwx100.com/project/27/interface/api/27126)
 * questionnaire_id
 * curriculum_id
 * curriculum_type
 */
export function api_getRecommendIdList(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/recommend_id_list'
  return http.get(url, { params })
}
/**
 * [根据推荐课程id列表返回课程详细信息]（http://yapi.xhwx100.com/project/27/interface/api/27131）
 * curriculum_id_list
 * publish_code
 * quarter
 */
export function api_getRecommendCourseInfo(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/recommend_info'
  return http.post(url, params)
}

/**
 * [获取购买课程的推荐课程列表：课程列表id列表,可筛选讲义类型](http://yapi.xhwx100.com/project/27/interface/api/27126)
 * questionnaire_id
 * curriculum_id
 */
export function api_getChangeRecommendIdList(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/change/recommend_id_list'
  return http.get(url, { params })
}
/**
 * [根据推荐课程id列表返回课程详细信息]（http://yapi.xhwx100.com/project/27/interface/api/27131）
 * curriculum_id_list
 * publish_code
 */
export function api_getChangeRecommendCourseInfo(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/change/recommend_info'
  return http.post(url, params)
}

/**
 * [取消入学测试](http://yapi.xhwx100.com/project/11/interface/api/27206)
 */
export function api_cancelTest(params) {
  const url = '/koala/api/v1.7/renew-activity/submit-exam'
  return http.post(url, params)
}