var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.dialog_1jrqY{position:fixed;top:0;left:0;bottom:0;right:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;z-index:110\n}\n.dimmer_3r3ny{position:absolute;top:0;left:0;bottom:0;right:0;background:rgba(0,0,0,.7)\n}\n.box_2kzNr{position:absolute;top:50%;left:50%;width:7.2rem;margin-top:-50%;margin-left:-3.6rem;padding-top:.8rem;background:#fff;border-radius:.346667rem;text-align:center;line-height:1\n}\n.enter_1MJWr{opacity:0\n}\n.enter_1MJWr .box_2kzNr{-webkit-transform:scale(1.1);transform:scale(1.1)\n}\n.leaveTo_3VkcA{opacity:0\n}\n.leaveTo_3VkcA .box_2kzNr{-webkit-transform:scale(0.9);transform:scale(0.9)\n}\n.transitionActive_pGi-X{transition:opacity .3s ease-out\n}\n.transitionActive_pGi-X .box_2kzNr{transition:-webkit-transform .3s ease-out;transition:transform .3s ease-out;transition:transform .3s ease-out, -webkit-transform .3s ease-out\n}\n.title_2w7yF{margin-bottom:.66666667rem;font-size:.48rem;font-weight:500;color:#464646;line-height:.48rem\n}\n.phone_FUBbH{margin-bottom:.48rem;font-size:.56rem;font-weight:500;color:#00c55d;line-height:.56rem\n}\n.tip_1ZQ3p{font-size:.42666667rem;font-weight:400;color:#8a8a8a;line-height:.42666667rem\n}\n.btnConfirm_2obG0{box-shadow:0px 16px 32px 0px rgba(0,197,93,.4);width:6.29333333rem;margin:.8rem 0;border-radius:.64rem\n}\n.btnClose_1NrJB{position:absolute;bottom:-1.04rem;z-index:111;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:.773333rem;height:.773333rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background:url(" + escape(require("./images/notes_close_nor@3x.png")) + ") 0 0 no-repeat;background-size:contain\n}\n.btnClose_1NrJB:active{background-image:url(" + escape(require("./images/notes_close_pre@3x.png")) + ")\n}", ""]);

// exports
exports.locals = {
	"dialog": "dialog_1jrqY",
	"dimmer": "dimmer_3r3ny",
	"box": "box_2kzNr",
	"enter": "enter_1MJWr",
	"leaveTo": "leaveTo_3VkcA",
	"transitionActive": "transitionActive_pGi-X",
	"title": "title_2w7yF",
	"phone": "phone_FUBbH",
	"tip": "tip_1ZQ3p",
	"btnConfirm": "btnConfirm_2obG0",
	"btnClose": "btnClose_1NrJB"
};