import jump from './jump'
import xiaoToast from './xiao-toast'

export default {
  catchHttp401Error(err) {
    if (err && err.response && err.response.status === 401) {
      xiaoToast.warning('请先登录！')
      setTimeout(() => { // 延迟300ms是为了让用户能看到提示toast
        jump.login()
      }, 300)
      return Promise.reject(false)
    }
    return Promise.reject(err)
  }
}