import { has } from "xiao-utils/cooker"

const state =  {
  grade_list: [],
  subject_list: [],
  level_list: [],
  semesters: [],
  // 时段
  time_ranges: [],
  curriculums: [],
  
  // 
  filterGrade: {
    zh_cn: '',
    code: ''
  },
  filterSubject: {
    zh_cn: '',
    code: ''
  },
  filterLevel: {
    zh_cn: '',
    code: ''
  },
  filterSemester: {
    semester: null,
    start_at: -Infinity,
    end_at: Infinity
  },
  filterTimeRange: {
    rangeId: -1,
    curriculum: []
  },
  orderSystemWinterGrades: [], //抢报活动包含的课程可选择年级
  size: 10, // 默认多少条
}

const mutations = {
  SET_GRADE_LIST(state, list) {
    state.grade_list = list
  },
  SET_SUBJECT_LIST(state, list) {
    state.subject_list = list
  },
  SET_LEVEL_LIST(state, list) {
    state.level_list = list.map(li => {
      return {
        zh_cn: li.zh_cn.slice(0,3),
        code: li.code
      }
    })
  },

  SET_SEMESTERS(state, list) {
    state.semesters = list
  },

  INIT_TIME_RANGES(state, list) {
    state.time_ranges = list.map((li, index) => {
      return {
        rangeId: index,
        curriculum: [],
        disabled: true,
        start_at: li.start_at,
        end_at: li.end_at
      }
    })
  },

  SET_TIME_RANGES(state, list) {
    for (let k=0; k < state.time_ranges.length; k++) {
      state.time_ranges[k].disabled = true
      state.time_ranges[k].curriculum = []
    }
    for(let i = 0; i < list.length; i++) {
      const { curriculum_id, lesson_time_list } = list[i]
      // console.log(lesson_time_list)
      for (let j=0; j < lesson_time_list.length; j++) {
        const time = lesson_time_list[j]
        const timestamp = time.split(":")[0] * 60 + time.split(":")[1]
        for (let k=0; k < state.time_ranges.length; k++) {
          const { start_at, end_at } = state.time_ranges[k]
          const startStamp =  start_at.split(":")[0] * 60 + start_at.split(":")[1]
          const endStamp =  end_at.split(":")[0] * 60 + end_at.split(":")[1]
          if (timestamp >= startStamp && timestamp <= endStamp) {
            state.time_ranges[k].disabled = false
            state.time_ranges[k].curriculum.push(curriculum_id)
          }
        }
      }
    }
  },
  
  SET_CURRICULUM_LIST(state, list) {
    state.curriculums = list 
  },
  SET_FILTER_GRADE(state, filter) {
    state.filterGrade = filter
  },
  SET_FILTER_SUBJECT(state, filter) {
    state.filterSubject = filter
  },
  SET_FILTER_LEVEL(state, filter) {
    state.filterLevel = filter
  },
  SET_FILTER_SEMESTER(state, semester) {
    state.filterSemester = semester
  },
  SET_FILTER_TIME_RANGE(state, time_range) {
    state.filterTimeRange = time_range
  },
  SET_ORDERSYSTEM_WINTER_GRADES(state, grades) {
    state.orderSystemWinterGrades = grades
  }
}
const getters = {
  grade_hash(state) {
    const hash = {}
    const grade_list = state.grade_list
    for (let i=0; i<grade_list.length; i++) {
      const grade = grade_list[i]
      const code = grade.code
      hash[code] = grade
    }
    return hash
  },
  subject_hash(state) {
    const hash = {}
    const subject_list = state.subject_list
    for (let i=0; i<subject_list.length; i++) {
      const subject = subject_list[i]
      const code = subject.code
      hash[code] = subject
    }
    return hash
  },
  level_hash(state) {
    const hash = {}
    const level_list = state.level_list
    for (let i=0; i<level_list.length; i++) {
      const level = level_list[i]
      const code = level.code
      hash[code] = level
    }
    return hash
  },

  resolveCurriculum(state) {
    // console.log(state)
    if (!Array.isArray(state.curriculums)) return []
    return state.curriculums.filter(c => {
      const code = state.filterGrade.code
      return !code ? true : c.grade_list.includes(code)
    }).filter(c => {
      const code = state.filterSubject.code 
      return !code ? true : c.subject_list.includes(code)
    }).filter(c => {
      const code = state.filterLevel.code 
      return !code ? true : c.level === code
    }).filter(c => {
      const { start_at, end_at } = state.filterSemester
      // 由于运营系统存储日期的问题 截止时间要加多24小时
      return (c.arrangement_start >= start_at && c.arrangement_end <= (end_at + 24*60*60 -1 ))
      || (c.arrangement_end >= start_at && c.arrangement_end <= (end_at + 24*60*60 -1 ))
      || (c.arrangement_start >= start_at && c.arrangement_start <= (end_at + 24*60*60 -1 ))
    }).filter(c => {
      const list = state.filterTimeRange.curriculum || []
      return list.length ? list.includes(c.curriculum_id) : true
    })
  },

  grades(state, getters) {
    const gradeSet = new Set()
    for (let i=0; i<state.curriculums.length; i++) {
      const grade_list = state.curriculums[i] && state.curriculums[i].grade_list || []
      for (let j=0; j< grade_list.length; j++) {
        gradeSet.add(grade_list[j])
      } 
    }
    const grades = []
    gradeSet.forEach(s => {
      grades.push(getters.grade_hash[s])
    })
    return grades.sort(function(grade1, grade2) {
      return grade1.code - grade2.code
    })
    
  },

  subjects(state, getters) {
    const subjectSet = new Set()
    for (let i=0; i<getters.resolveCurriculum.length; i++) {
      const subject_list = getters.resolveCurriculum[i] && getters.resolveCurriculum[i].subject_list || []
      for (let j=0; j< subject_list.length; j++) {
        subjectSet.add(subject_list[j])
      } 
    }
    const subjects = []
    subjectSet.forEach(s => {
      subjects.push(getters.subject_hash[s])
    })
    // console.log(subjects)
    return subjects
  },

  levels(state, getters) {
    const levelSet = new Set()
    for (let i=0; i<getters.resolveCurriculum.length; i++) {
      const curriculum = getters.resolveCurriculum[i]
      levelSet.add(curriculum.level)
    }
    const levels = []
    levelSet.forEach(s => {
      levels.push(getters.level_hash[s])
    })
    // console.log(subjects)
    return levels
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations
}