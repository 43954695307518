export default <template>
<transition :enter-class="$style.enter" :leave-to-class="$style.leaveTo" :enter-active-class="$style.transitionActive" :leave-active-class="$style.transitionActive"
  @after-leave="destroyElement">
  <div :class="$style.dialog" v-if="visible">
    <div :class="$style.dimmer" @touchstart.prevent.stop></div>
    <div :class="$style.box">
      <p :class="$style.title">当前微信已绑定如下账号</p>
      <p :class="$style.phone">{{ phone }}</p>
      <p :class="$style.tip">是否换绑为当前登录账号？</p>
      <button type="button" :class="['btn', 'btn-basic', $style.btnConfirm]" @click.once="onClickOK">确定</button>
      <div :class="$style.btnClose" @click="onClickClose"></div>
    </div>
  </div>
</transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    destroyElement() {
      const $el = this.$el
      this.$destroy()
      if ($el.parentNode) {
        $el.parentNode.removeChild($el)
      }
    },
    onClickOK() {
      this.visible = false
      this.$emit('confirm', { result: true })
    },
    onClickClose() {
      this.visible = false
      this.$emit('confirm', { result: false })
    }
  },
  mounted() {
    this.visible = true
  }
}
</script>

<style lang="scss" module>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  user-select: none;
  z-index: 110;
}

.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .7);
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.2rem;
  margin-top: -50%;
  margin-left: -3.6rem;
  padding-top: 0.8rem;
  background: #FFF;
  border-radius: 0.346667rem;
  text-align: center;
  line-height: 1;
}

.enter {
  opacity: 0;
  .box {
    transform: scale(1.1);
  }
}

.leaveTo {
  opacity: 0;
  .box {
    transform: scale(.9);
  }
}

.transitionActive {
  transition: opacity .3s ease-out;
  .box {
    transition: transform .3s ease-out;
  }
}

.title {
  margin-bottom: 0.66666667rem;
  font-size: 0.48rem;
  font-weight: 500;
  color: rgba(70, 70, 70, 1);
  line-height: 0.48rem;
}
.phone {
  margin-bottom: 0.48rem;
  font-size: 0.56rem;
  font-weight: 500;
  color: rgba(0, 197, 93, 1);
  line-height: 0.56rem;
}
.tip {
  font-size: 0.42666667rem;
  font-weight: 400;
  color: rgba(138, 138, 138, 1);
  line-height: 0.42666667rem;
}
.btnConfirm {
  box-shadow: 0px 16px 32px 0px rgba(0, 197, 93, 0.4);
  width: 6.29333333rem;
  margin: 0.8rem 0;
  border-radius: 0.64rem;
}

.btnClose {
  position: absolute;
  bottom: -1.04rem;
  z-index: 111;
  left: 50%;
  transform: translateX(-50%);
  width: 0.773333rem;
  height: 0.773333rem;
  user-select: none;
  background: url("./images/notes_close_nor@3x.png") 0 0 no-repeat;
  background-size: contain;
  &:active {
    background-image: url("./images/notes_close_pre@3x.png");
  }
}
</style>