/**
 * 日期显示规范
 * 
 * 1. 格式一（日期—日期）
 * 
 * 以下例子假设今年为2018年，注意`—`不是英文的短横线`-`
 * 优先级：同一天 > 跨年 > 报班即学 > 非今年 > 今年
 * 
 * 同一天：12月31日/2019年1月1日
 * 跨年：2019年1月1日—2020年12月31日
 * 报班即学：报班即学—12月31日/报班即学—2019年12月31日
 * 非今年：2019年1月1日—2019年12月31日
 * 今年：1月1日—12月31日
 * 
 * 2. 格式一（日期 星期 时间—时间）
 * 
 * 以下例子假设今年为2018年，注意`—`不是英文的短横线`-`
 * 
 * 非今年：2019年1月1日 星期二 10:00—12:00
 * 今年：12月31日 星期二 10:00—12:00
 * 
 * 3. 格式二（日期—日期）
 * 
 * 不区分跨年/是否今年/是否同一天等
 * 
 * 2018/12/12—2018/12/31
 * 
 * 4. 格式二（星期 时间—时间）
 * 
 * 不区分跨年/是否今年/是否同一天等
 * 
 * 星期二 10:00—12:00
 * 
 * 5. 格式二（日期）
 * 
 * 不区分跨年/是否今年/是否同一天等
 * 
 * 2019/01/01
 * 
 * 6. 格式一（下次直播时间）
 * 
 * 以下例子假设今年为2018年
 * 
 * 非今年：2019年1月1日 星期二 10:45
 * 今年：12月31日 星期一 10:45
 * 
 * 7. 格式二（下次直播时间）
 * 
 * 不区分跨年/是否今年/是否同一天等
 * 
 * 2019/01/01 星期二 10:45
 * 
 * 8. 格式二（日期 时间）
 * 
 * 不区分跨年/是否今年/是否同一天等
 * 
 * 2019/01/01 10:45
 * 
 * 9. 格式二（日期 时间 精确到秒）
 * 
 * 2019/01/01 10:45:03
 * 
 */

import { pad } from 'xiao-utils/lang'
import { dateTimeFormat, dateTimeFormatter } from 'xiao-utils/time'

/**
 * 按照“日期显示规范：格式一（日期—日期）”显示日期范围
 * @param {number} startTS 开始日期时间戳（秒）
 * @param {number} endTS 结束日期时间戳（秒）
 * @return {string}
 */
export function formatTimeRange1(startTS, endTS, isFormat = false) {
  // 购物车的格式
  const dS = new Date(startTS * 1000), dE = new Date(endTS * 1000), now = new Date()
  const dSy = dS.getFullYear(), dSm = dS.getMonth() + 1, dSd = dS.getDate()
  const dEy = dE.getFullYear(), dEm = dE.getMonth() + 1, dEd = dE.getDate()
  const dNy = now.getFullYear()
  const nowTS = now.getTime()
  if (isFormat) {
    if (dSy === dEy && dSm === dEm && dSd === dEd) { // 同一天
      return dNy === dSy ? `${dSm}/${dSd}` : `${dSy}/${dSm}/${dSd}`
    } else if (nowTS >= dS.getTime() && nowTS < dE.getTime()) { // 报班即学
      return dNy === dEy ? `报班即学—${dEm}/${dEd}` : `报班即学—${dEy}/${dEm}/${dEd}`
    } else if (dSy !== dEy || dSy !== dNy) { // 跨年/非今年
      return `${dSy}/${dSm}/${dSd}—${dEy}/${dEm}/${dEd}`
    } else {
      return `${dSm}/${dSd}—${dEm}/${dEd}`
    }
  } else {
    if (dSy === dEy && dSm === dEm && dSd === dEd) { // 同一天
      return dNy === dSy ? `${dSm}月${dSd}日` : `${dSy}年${dSm}月${dSd}日`
    } else if (nowTS >= dS.getTime() && nowTS < dE.getTime()) { // 报班即学
      return dNy === dEy ? `报班即学—${dEm}月${dEd}日` : `报班即学—${dEy}年${dEm}月${dEd}日`
    } else if (dSy !== dEy || dSy !== dNy) { // 跨年/非今年
      return `${dSy}年${dSm}月${dSd}日—${dEy}年${dEm}月${dEd}日`
    } else {
      return `${dSm}月${dSd}日—${dEm}月${dEd}日`
    }
  }
  
}

const daysCN = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']

/**
 * 按照“日期显示规范：格式一（日期+星期+时间）”显示时间范围
 * @param {number} startTS 开始日期时间戳（秒）
 * @param {number} endTS 结束日期时间戳（秒）
 * @return {string}
 */
export function formatTimeRange2(startTS, endTS) {
  const dS = new Date(startTS * 1000), dE = new Date(endTS * 1000)
  const dSy = dS.getFullYear(), dSm = dS.getMonth() + 1, dSd = dS.getDate()
  const dEy = dE.getFullYear(), dEm = dE.getMonth() + 1, dEd = dE.getDate()
  if (dSy !== dEy || dSm !== dEm || dSd !== dEd) {
    return formatTimeRange1(startTS, endTS)
  }
  const result = `${dSm}月${dSd}日 ${daysCN[dS.getDay()]} ${pad(dS.getHours())}:${pad(dS.getMinutes())}—${pad(dE.getHours())}:${pad(dE.getMinutes())}`
  return dSy === (new Date()).getFullYear() ? result : `${dSy}年${result}`
}

const timeRange3Formatter = dateTimeFormatter('yyyy/MM/dd')

/**
 * 按照“日期显示规范：格式二（日期—日期）”显示日期范围
 * @param {number} startTS 开始日期时间戳（秒）
 * @param {number} endTS 结束日期时间戳（秒）
 * @return {string}
 */
export function formatTimeRange3(startTS, endTS) {
  return `${timeRange3Formatter(startTS * 1000)}—${timeRange3Formatter(endTS * 1000)}`
}

/**
 * 按照“日期显示规范：格式二（星期 时间—时间）”显示日期范围
 * @param {number} startTS 开始日期时间戳（秒）
 * @param {number} endTS 结束日期时间戳（秒）
 * @return {string}
 */
export function formatTimeRange4(startTS, endTS) {
  const dS = new Date(startTS * 1000), dE = new Date(endTS * 1000)
  return `${daysCN[dS.getDay()]} ${pad(dS.getHours())}:${pad(dS.getMinutes())} - ${pad(dE.getHours())}:${pad(dE.getMinutes())}`
}

/**
 * 按照“日期显示规范：格式一（下次直播时间）”显示日期时间
 */
export function formatNextLiveTime(ts) {
  if (typeof ts !== 'number' || ts !== ts || ts <= 0) {
    return '--'
  }
  const targetYear = (new Date(ts * 1000)).getFullYear()
  const formatString = targetYear === (new Date()).getFullYear() ? 'M月d日 星期w hh:mm' : 'yyyy年M月d日 星期w hh:mm'
  return dateTimeFormat(ts * 1000, formatString)
}

/**
 * 按下面格式显示日期
 * 跨年或非今年：2019/01/01—2019/12/31
 * 今年：01/01—12/31
 */
export function formatDateRange(startDate, endDate, format="yyyy/MM/dd") {
  const dS = new Date(startDate * 1000), dE = new Date(endDate * 1000), now = new Date()
  if (dS.getFullYear() === dE.getFullYear() && dS.getFullYear() === now.getFullYear()) {
    return `${dateTimeFormat(dS, 'MM/dd')} - ${dateTimeFormat(dE, 'MM/dd')}`
  }
  
  return `${dateTimeFormat(dS, format)} - ${dateTimeFormat(dE, format)}`
}