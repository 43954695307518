export default <template>
  <div :class="$style.toastContainer" v-if="isShow">
    <i class="xiaoicon" :class="[iconClass]"></i>
    <span :class="$style.msg">{{msg}}</span>
  </div>
</template>
<script>
import { parsePadding } from '@antv/f2/lib/util/common'
export default{
  data(){
    return {
      msg: '',
      type: '',
      iconClass: null,
      isShow: false
    }
  },
  methods: {
    show({msg = 'msg', type = 'success', duration = 3000}) {
      this.msg = msg
      this.type = type
      this.iconClass = this.$style[`icon-${type}`]
      this.duration = duration
      this.isShow = true
      setTimeout(() => {
        this.isShow = false
      }, duration)
    }
  }
}
</script>
<style scoped lang="scss" module>
.toastContainer {
  background: #5A5A5A;
  border-radius: .32rem;
  padding: .32rem .42666667rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  z-index: 1001;

  .icon-success {
    color: #00C55D;
    &::after {
      content: '\e670';
    }
  }
  .icon-warning {
    color: #fd7663;
    &::after {
      content: '\e672';
    }
  }

  .msg {
    color: white;
  }
}
</style>