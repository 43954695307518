const state = {
  basicInfo: {
    curriculum: {},
    lesson: {},
    student: {}
  }
}

const mutations = {
  SET_BASIC_INFO(state, basicInfo) {
    state.basicInfo = basicInfo
  }
}

export default {
  namespaced: true,
  state,
  mutations
}