// 基于 https://github.com/kelektiv/node-uuid 改造的在浏览器端用 JS 创建 uuid 的方法
const getRandomValues = (typeof(crypto) != 'undefined' && crypto.getRandomValues.bind(crypto)) ||
                        (typeof(msCrypto) != 'undefined' && msCrypto.getRandomValues.bind(msCrypto))

const byteToHex = []
for (let i = 0; i < 256; ++i) {
  byteToHex[i] = (i + 0x100).toString(16).substr(1)
}

function bytesToUuid(buf) {
  let i = 0
  const bth = byteToHex
  return bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]]
}

function bytesToUuid_(buf) {
  let i = 0
  const bth = byteToHex
  return bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] + '-' +
          bth[buf[i++]] + bth[buf[i++]] + '-' +
          bth[buf[i++]] + bth[buf[i++]] + '-' +
          bth[buf[i++]] + bth[buf[i++]] + '-' +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]] +
          bth[buf[i++]] + bth[buf[i++]]
}

let RNGfunc
if (getRandomValues) {
  const rnds8 = new Uint8Array(16)
  RNGfunc = function whatwgRNG() {
    getRandomValues(rnds8)
    return rnds8
  }
} else {
  var rnds = new Array(16)
  RNGfunc = function mathRNG() {
    for (var i = 0, r; i < 16; i++) {
      if ((i & 0x03) === 0) r = Math.random() * 0x100000000
      rnds[i] = r >>> ((i & 0x03) << 3) & 0xff
    }
    return rnds
  }
}

export function uuidv4() {
  const rnds = RNGfunc()

  rnds[6] = (rnds[6] & 0x0f) | 0x40
  rnds[8] = (rnds[8] & 0x3f) | 0x80

  return bytesToUuid(rnds)
}

export function uuidv4_() {
  const rnds = RNGfunc()

  rnds[6] = (rnds[6] & 0x0f) | 0x40
  rnds[8] = (rnds[8] & 0x3f) | 0x80

  return bytesToUuid_(rnds)
}
