import shareImageUrl from 'src/images/logo@2x.png'
import { stringify } from 'xiao-utils/qs'
import WechatMpBinding from 'src/views/binding/WechatMpBinding.vue'
import WechatMpUnbind from 'src/views/binding/WechatMpUnbind.vue'
import AccountRoute from './account'
import ExperienceLesson from './experience-lesson'
import subRouter from './sub-router'
export default [
  ...AccountRoute,
  ...ExperienceLesson,
  ...subRouter,
  {
    path: '/',
    name: 'home',
    meta: {
      title: '星火网校',
      pc: '/',
      share: {
        title: '星火网校',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require(['src/views/home/Home.vue'], resolve)
    }
  },
  {
    path: '/choose-course',
    name: 'choose-course',
    meta: {
      title: '选择课程',
      pc: '/choose-course',
      showShoppingCart: true,
      share: {
        title: '选课中心',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require(['src/views/choose/center/Choose.vue'], resolve)
    }
  },
  {
    path: '/term/:quarter/:grade',
    name: 'term',
    meta: {
      title: '长期课',
      showShoppingCart: true,
      pcRedirect (to) {
        return __PC__DOMAIN__ + to.fullPath
      },
      asyncShare: true
    },
    component: resolve => {
      require(['src/views/choose/term/Term.vue'], resolve)
    }
  },
  {
    path: "/experience-term/:quarter/:grade",
    name: "experience-term",
    // meta: {
    //   title: "长期课",
    //   mobileRedirect: mobileRedirectFullPath,
    //   pin: {
    //     shareType: "home"
    //   }
    // },
    component: resolve => {
      require(['src/views/choose/term/ZhuantiExperienceTerm.vue'], resolve)
    }
  },

  {
    path: '/album/:id',
    name: 'album',
    meta: {
      title: '精品推荐',
      showShoppingCart: true,
      asyncShare: true
    },
    component: resolve => {
      require(['src/views/choose/album/Album.vue'], resolve)
    }
  },
  {
    path: '/choose/coupon/:id',
    name: 'use-coupon',
    meta: {
      title: '优惠券适用课程',
      share: {
        title: '优惠课程',
        desc: '记得用上优惠券哦！',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require(['src/views/choose/coupon/CouponCourses.vue'], resolve)
    }
  },
  {
    path: '/course-list',
    name: 'course-list',
    meta: {
      title: '我的课程',
      pc: '/my/course'
    },
    component: resolve => {
      require(['src/views/course-list/MyCourseList.vue'], resolve)
    }
  },
  {
    path: '/no-course-help',
    name: 'no-course-help',
    meta: {
      title: '帮助',
      pc: '/my/course'
    },
    component: resolve => {
      require(['src/views/my/no-course-help/Index.vue'], resolve)
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    meta: {
      title: '课程详情',
      showShoppingCart: true,
      asyncShare: true
    },
    component: resolve => {
      require.ensure([], (require) => {
        resolve(require('src/views/newDetail/detail.vue'))
      }, 'class-detail')
    }
  },
  {
    path: '/distdetail/:id',
    name: 'DistDetail',
    meta: {
      title: '邀请有奖',
      pcRedirect (to) {
        return `${__PC__DOMAIN__}/course/detail/${to.params.id}`
      },
      share: {
        title: '邀请有奖',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require.ensure([], (require) => {
        resolve(require('src/views/distdetail/DistDetail.vue'))
      }, 'distdetail')
    }
  },
  //跳转教师资格证
  {
    path: '/certificate/:id',
    name: 'certificate',
    meta: {
      title: '所授课程',
    },
    component: resolve => {
      require(['src/views/detail/teacher/certificate.vue'], resolve)
    }
  },
  //跳转报课返券规则
  {
    path: '/returnCouponRules',
    name: 'returnCouponRules',
    meta: {
      title: '优惠券使用规则'
    },
    component: resolve => {
      require(['src/views/detail/returncoupon/rule.vue'], resolve)
    }
  },
  {
    path: '/detail/oto/:id',
    name: 'oto-course-detail',
    meta: {
      title: '1对1课程详情',
      pc: '/course/detail/oto/:id',
      asyncShare: true
    },
    component: resolve => {
      require.ensure([], (require) => {
        resolve(require('src/views/oto-course-detail/detail/index.vue'))
      }, 'detail')
    }
  },
  {
    path: '/oto-register',
    name: 'oto-register',
    meta: {
      title: '1对1课程预约',
      pcRedirect (to) {
        return __PC__DOMAIN__ + to.fullPath
      },
      asyncShare: true
    },
    component: resolve => {
      require.ensure([], (require) => {
        resolve(require('src/views/oto-course-detail/register/index.vue'))
      }, 'detail')
    }
  },
  {
    path: '/playback/:lessonId',
    name: 'Playback',
    meta: {
      title: '观看回放',
      pc: '/playback/:lessonId'
    },
    component: r => require.ensure([], (require) => { r(require('src/views/playback/Playback.vue')) }, 'playback')
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      title: '个人中心',
      pc: '/my/courses',
      needUserInfo: true
    },
    component: resolve => {
      require(['src/views/my/home/MyHome.vue'], resolve)
    }
  },
  {
    path: '/my/logistics',
    name: 'MyLogistics',
    meta: {
      title: '讲义物流',
      pc: '/my/logistics'
    },
    component: resolve => {
      require(['src/views/my/logistics/LogisticsList.vue'], resolve)
    }
  },
  {
    path: '/my/logistics/details',
    name: 'logisticsDetails',
    meta: {
      title: '查看物流',
      pc: '/my/logistics'
    },
    component: resolve => {
      require(['src/views/my/logistics/LogisticsDetails.vue'], resolve)
    }
  },
  {
    path: '/my/logistics/adress',
    name: 'logisticsAdress',
    meta: {
      title: '修改地址',
      pc: '/my/logistics'
    },
    component: resolve => {
      require(['src/views/my/logistics/ConsigneeAdress.vue'], resolve)
    }
  },
  {
    path: '/my/orders',
    name: 'MyOrders',
    meta: {
      title: '我的订单',
      pc: '/my/orders'
    },
    component: resolve => {
      require(['src/views/my/orders/MyOrders.vue'], resolve)
    }
  },
  {
    path: '/my/vip-orders',
    name: 'VipOrders',
    meta: {
      title: '我的订单',
      pc: '/my/orders'
    },
    component: resolve => {
      require(['src/views/my/orders/VipOrders.vue'], resolve)
    }
  },
  {
    path: '/my/pintuan',
    name: 'MyPintuan',
    meta: {
      title: '我的拼团',
      pc: '/my/pintuan'
    },
    component: r => require.ensure([], (require) => { r(require('src/views/my/pintuan/MyPintuan.vue')) }, 'pintuan')
  },
  {
    path: '/my/balance',
    name: 'MyBalance',
    meta: {
      title: '我的余额',
      pc: '/my/balance'
    },
    component: r => require.ensure([], (require) => { r(require('src/views/my/balance/MyBalance.vue')) }, 'MyBalance'),
  },
  {
    path: '/my/bonus',
    name: 'MyBonus',
    meta: {
      title: '我的奖励金',
      pc: '/my/bonus'
    },
    component: r => require.ensure([], (require) => { r(require('src/views/my/bonus/MyBonus.vue')) }, 'MyBonus'),
  },
  {
    path: '/my/beans',
    name: 'MyBeans',
    meta: {
      title: '我的抵用金',
      pc: '/my/beans'
    },
    component: r => require.ensure([], (require) => { r(require('src/views/my/beans/MyBeans.vue')) }, 'MyBeans')
  },
  {
    path: '/my/coupons',
    name: 'myCoupons',
    meta: {
      title: '我的优惠券',
      pc: '/my/coupons'
    },
    component: resolve => {
      require(['src/views/my/coupons/Coupons.vue'], resolve)
    }
  },
  {
    path: '/my/address-confirm/:id',
    name: 'myAddressConfirm',
    component: resolve => {
      require(['src/views/my/addressConfirm/AddressConfirm.vue'], resolve)
    }
  },
  {
    path: '/coupons/:id',
    name: 'fetch-coupon',
    meta: {
      title: '领取优惠券',
      asyncShare: true
    },
    component: resolve => {
      require(['src/views/coupons/Coupons.vue'], resolve)
    }
  },
  {
    path: '/report/:id/:user',
    name: 'report',
    component: resolve => {
      require(['src/views/report/Report.vue'], resolve)
    },
    meta: {
      DONTCHECKINFO: true,
      title: '学习报告',
      pc: '/report/:id/:user',
      share: {
        title: '学习报告',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    }
  },
  {
    path: '/order/confirm',
    name: 'orderConfirm',
    component: resolve => {
      require(['src/views/order/confirm/new/order-confirm.vue'], resolve)
    },
    meta: {
      title: '确认订单',
      pcRedirect (to) {
        return `${__PC__DOMAIN__}/order?${stringify(to.query)}`
      }
    }
  },
  {
    path: '/order/dropout/:orderNum/:curriculumId',
    name: 'DropoutDetail',
    component: r => require.ensure([], () => r(require('src/views/order/dropout/DropoutDetail.vue')), 'order'),
    meta: {
      title: '退课详情',
      pc: '/order/:orderNum'
    }
  },
  {
    path: '/order/:id',
    name: 'myOrderDetail',
    component: r => require.ensure([], () => r(require('src/views/order/detail/class-order/new-order-detail.vue')), 'order'),
    meta: {
      title: '订单详情',
      pc: '/order/:id'
    }
  },
  {
    path: '/vip-order/dropout/:orderNum',
    name: 'VipDropoutDetail',
    component: r => require.ensure([], () => r(require('src/views/order/dropout/VipDropoutDetail.vue')), 'order'),
    meta: {
      title: '退课详情',
      pc: '/vip-order/:orderNum'
    }
  },
  {
    path: '/vip-order/:orderNum',
    name: 'VipOrderDetail',
    component: r => require.ensure([], () => r(require('src/views/order/detail/vip/VipOrderDetail.vue')), 'order'),
    meta: {
      title: '订单详情',
      pcRedirect (to) {
        return __PC__DOMAIN__ + to.fullPath
      }
    }
  },
  {
    path: '/consigneeAdress',
    name: 'consigneeAdress',
    component: r => require.ensure([], () => r(require('src/views/order/detail/class-order/ConsigneeAdress.vue')), 'order'),
    meta: {
      title: '修改地址',
      pc: '/order/:id'
    }
  },
  {
    path: '/vip-checkout/:orderNum',
    redirect: 'VipCheckout'
  },
  {
    path: '/checkout/vip-:orderNum',
    name: 'VipCheckout',
    meta: {
      title: '支付',
      pc: '/vip-checkout/:orderNum'
    },
    component: resolve => {
      require(['src/views/checkout/vip/VipCheckout.vue'], resolve)
    }
  },
  {
    path: '/bank/:orderNum',
    name: 'Bank',
    meta: {
      title: '支付',
      pc: '/bank/:orderNum'
    },
    component: resolve => {
      require(['src/views/checkout/vip/BankInfo.vue'], resolve)
    }
  },
  {
    path: '/checkout/:order',
    name: 'checkout',
    meta: {
      title: '支付',
      pcRedirect (to) {
        return `${__PC__DOMAIN__}/checkout/${to.params.order}?${stringify(to.query)}`
      }
    },
    component: resolve => {
      require(['src/views/checkout/Checkout.vue'], resolve)
    }
  },
  {
    path: '/address/confirm-delivery-address',
    name: 'confirm-delivery-address',
    meta: {
      title: '讲义寄送地址确认',
    },
    component: resolve => {
      require(['src/views/address/confirm-delivery-address/index.vue'], resolve)
    }
  },
  {
    path: '/address/list',
    name: 'address-list',
    meta: {
      title: '收货地址'
    },
    component: resolve => {
      require(['src/views/address/list/index.vue'], resolve)
    }
  },
  {
    path: '/address/manage',
    name: 'address-manage',
    meta: {
      title: '收货地址'
    },
    component: resolve => {
      require(['src/views/address/edit/index.vue'], resolve)
    }
  },
  {
    path: '/grade',
    name: 'select-grade',
    meta: {
      title: '选择年级'
    },
    component: resolve => {
      require(['src/views/home/SelectGrade.vue'], resolve)
    }
  },
  {
    path: "/check-status",
    name: "check-status",
    meta: {
      title: '支付详情'
    },
    component: resolve => {
      require(['src/views/order/success/CheckStatus.vue'], resolve)
    }
  },
  {
    path: '/order/success/:id',
    name: 'order-success',
    meta: {
      title: '支付详情',
      pc: '/ordercompleted/:id'
    },
    component: resolve => {
      require(['src/views/order/success/OrderSuccess.vue'], resolve)
    }
  },
  {
    path: '/order/vip-success/:orderNum',
    name: 'VipOrderSuccess',
    meta: {
      title: '支付详情',
      pc: '/vip-order/:orderNum'
    },
    component: resolve => {
      require(['src/views/order/success/VipOrderSuccess.vue'], resolve)
    }
  },
  {
    path: '/liveroom/:lesson',
    name: 'live-guide',
    meta: {
      title: '直播指引',
      pc: '/liveroom/:lesson'
    },
    component: resolve => {
      require(['src/views/live/Guide.vue'], resolve)
    }
  },
  {
    path: '/public-liveroom/:id',
    name: 'public-liveroom',
    meta: {
      pc: '/liveroom/:id',
      title: '直播间',
      asyncShare: true
    },
    component: r => require.ensure([], () => r(require('src/views/public-liveroom-v2/Liveroom.vue')), 'public-liveroom')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: resolve => {
      require(['src/404.vue'], resolve)
    }
  },
  {
    path: '/my-calendar',
    name: 'MyCalendar',
    meta: {
      title: '课程表',
      pc: '/my/courses'
    },
    component: r => require.ensure([], () => r(require('src/views/my/calendar/MyCalendar.vue')), 'personal-center')
  },
  {
    path: '/pintuan/:id',
    name: 'PintuanActivity',
    meta: {
      title: '拼团详情',
      asyncShare: true,
    },
    component: r => require.ensure([], () => r(require('src/views/pintuan/PintuanActivity.vue')), 'pintuan')
  },
  {
    path: '/pintuanShare/:id',
    name: 'PintuanShare',
    meta: {
      title: '分享海报，拼团购课',
      asyncShare: true,
    },
    component: r => require.ensure([], () => r(require('src/views/pintuan/components/pintuanActivityShare/PintuanActivityShare.vue')), 'pintuanShare')
  },
  {
    path: '/mvpshare',
    name: 'ShareMVP',
    meta: {
      asyncShare: true,
      title: 'MVP'
    },
    component: r => require.ensure([], () => r(require('src/views/mvp/ShareMvp.vue')), 'share-mvp')
  },
  {
    path: '/protocol',
    name: "UserProtocol",
    meta: {
      title: '星火网校用户协议'
    },
    component: r => require.ensure([], () => r(require('src/views/protocol/Protocol.vue')), 'protocol')
  },
  {
    path: '/hof',
    name: 'HOF',
    meta: {
      title: '名人堂',
      pc: '/hof',
      share: {
        title: '星火网校名人堂',
        desc: '学霸都在星火网校，一起学习收获多',
        imgUrl: shareImageUrl
      }
    },
    component: r => require.ensure([], () => r(require('src/views/hof/HOF.vue')), 'hof')
  },
  {
    path: '/teacher/gallery',
    name: 'TeacherGallery',
    meta: {
      title: '星火网校',
      pc: '/teacher/gallery',
      share: {
        title: '星火网校',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: r => require.ensure([], () => r(require('src/views/teacher/list/List.vue')), 'teacher')
  },
  {
    path: '/teacher/home/:id',
    meta: {
      pc: '/teacher/home/:id',
      title: '老师主页',
      asyncShare: true
    },
    name: 'teacherHome',
    component: r => require.ensure([], () => r(require('src/views/teacher/home/Home.vue')), 'teacher')
  },
  {
    path: '/students-feedbacks',
    meta: {
      pc: '/students-feedbacks',
      title: '星火网校',
      share: {
        title: '星火网校为什么受学生欢迎？',
        desc: '百万家长的选择告诉你真相',
        imgUrl: shareImageUrl
      }
    },
    name: 'StudentsFeedbacks',
    component: r => require.ensure([], () => r(require('src/views/students-feedbacks/StudentsFeedbacks.vue')), 'parentspraise')
  },

  {
    path: '/lesson-evaluation',
    meta: {
      title: '我的评价'
    },
    name: 'LessonEvaluation',
    component: r => require.ensure([], () => r(require('src/views/lesson-evaluation/LessonEvaluation.vue')), 'lesson-evaluation')
  },
  {
    path: '/questionnaire/:id',
    meta: {
      title: '问卷',
      asyncShare: true
    },
    name: 'questionnaire',
    component: r => require.ensure([], () => r(require('src/views/questionnaire/index.vue')), 'questionnaire')
  },
  {
    path: '/mp-login',
    meta: {
      title: '星火网校'
    },
    name: 'mp-login',
    component: r => require.ensure([], () => r(require('src/views/mp/login.vue')), 'mp-login')
  },

  // 微信公众号的绑定和解绑
  {
    path: '/wechatmpbinding',
    meta: {
      title: '星火网校'
    },
    component: WechatMpBinding
  },
  {
    path: '/wechatmpunbind',
    meta: {
      title: '星火网校'
    },
    component: WechatMpUnbind
  },
  {
    // 学员轨迹
    path: '/trace',
    meta: {
      asyncShare: true,
      title: '学习轨迹'
    },
    name: 'student-trace',
    component: r => require.ensure([], () => r(require('src/views/trace/index.vue')), 'trace')
  },
  {
    // 入学诊断
    path: '/admission-test',
    meta: {
      title: '入学诊断',
      asyncShare: true,
    },
    name: 'admission-test',
    component: resolve => {
      require(['src/views/admission-test/index.vue'], resolve)
    }
  },
  {
    path: '/admission-result',
    meta: {
      title: '测试结果'
    },
    name: 'admission-result',
    component: resolve => {
      require(['src/views/admission-test/result/result.vue'], resolve)
    }
  },
  // 入学诊断报告
  {
    path: '/questionnaire-report/:report_id',
    meta: {
      title: '入学诊断报告'
    },
    name: "admission-report",
    component: resolve => {
      require(['src/views/admission-test/report/index.vue'], resolve)
    }
  },
  {
    path: '/change-course',
    name: 'change-course',
    meta: {
      title: '自助换课',
      pc: '/my/changeCourse',
      share: {
        title: '自助换课',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require(['src/views/change-course/ChangeCourse.vue'], resolve)
    }
  },
  {
    path: '/appdownload',
    name: 'AppDownload',
    meta: {
      title: '星火网校',
      share: {
        title: '下载星火网校APP',
        desc: '中小学在线辅导品牌',
        imgUrl: shareImageUrl
      }
    },
    component: resolve => {
      require(['src/views/downloads/Downloads.vue'], resolve)
    }
  },
  {
    path: '/assistance',
    name: 'Assistance',
    meta: {
      title: '帮助中心'
    },
    component: resolve => {
      require(['src/views/my/assistance/Assistance.vue'], resolve)
    }
  },
  {
    path: '/assistance/question/:questionId',
    name: 'AssitanceQuestionDetail',
    meta: {
      title: '问题详情'
    },
    component: resolve => {
      require(['src/views/my/assistance/QuestionDetail.vue'], resolve)
    }
  },
  {
    path: '/study-report/:lesson_id/:student_id',
    meta: {
      title: '学习报告'
    },
    name: 'study-report',
    component: resolve => {
      require(['src/views/study-report/index.vue'], resolve)
    }
  },
  {
    path: '/period-exam-report/:report_id',
    meta: {
      title: '阶段测试报告'
    },
    name: 'period-exam-report',
    component: resolve => {
      require(['src/views/period-exam-report/index.vue'], resolve)
    }
  },
  // 课程推广
  {
    path: '/course-popularize/:cid',
    name: 'coursePopularize',
    meta: {
      title: '星火网校'
    },
    component: resolve => {
      require(['src/views/popularize/index.vue'], resolve)
    }
  },
  {
    path: '/shopping-cart',
    name: 'shoppingCart',
    meta: {
      title: '购物车',
      pc: '/shopping-cart',
    },
    component: resolve => {
      require(['src/views/shopping-cart/shopping-cart.vue'], resolve)
    }
  },
  {
    path: '/price-break/:id',
    name: 'priceBreak',
    meta: {
      title: '满减优惠活动',
    },
    component: resolve => {
      require(['src/views/shopping-cart/price-break/price-break.vue'], resolve)
    }
  }, {
    path: '/wechat-oauth',
    name: 'wechat-oauth',
    meta: {
      isLoginRoute: true
    },
    component: resolve => {
      require(['src/views/account/wechatOauth.vue'], resolve)
    }
  },
  {
    path: '/addtutor/:tutor_id',
    name: 'addTutorWechat',
    meta: {
      title: '添加辅导老师微信'
    },
    component: resolve => {
      require(['src/views/tutor/index.vue'], resolve)
    }
  },
  {
    path: '/select-consignee',
    name: 'select-consignee',
    meta: {
      title: '收货地址'
    },
    component: resolve => {
      require(['src/views/address/SelectConsignee/index.vue'], resolve)
    }
  },
  {
    path: '/rush-submit/:id',
    name: 'rushSubmit',
    meta: {
      title: '2020暑假家长会活动'
    },
    component: resolve => {
      require(['src/views/rush-submit/rush-submit.vue'], resolve)
    }
  },
  {
    path: '/addClassTeacher',
    name: 'addClassTeacher',
    meta: {
      title: '联系老师'
    },
    component: resolve => {
      require(['src/views/tutor/addClassTutor.vue'], resolve)
    }
  },
  {
    path: '/cashWithdraw',
    name: 'cashWithdraw',
    meta: {
      title: '余额提现'
    },
    component: resolve => {
      require(['src/views/cash-withdraw/index.vue'], resolve)
    }
  },
  {
    path: '/cashWithdrawRecord',
    name: 'cashWithdrawRecord',
    meta: {
      title: '提现记录'
    },
    component: resolve => {
      require(['src/views/cash-withdraw/record.vue'], resolve)
    }
  },
  {
    path: '/cashWithdrawDetail',
    name: 'cashWithdrawDetail',
    meta: {
      title: '提现详情'
    },
    component: resolve => {
      require(['src/views/cash-withdraw/WithdrawDetail.vue'], resolve)
    }
  },
  {
    path: '/prestore/:id',
    name: 'prestore',
    component: resolve => {
      require(['src/views/prestore-activity/index.vue'], resolve)
    }
  },
  // 作品墙
  {
    path: '/gallery',
    name: 'gallery',
    meta: {
      title: '作品墙'
    },
    component: (resolve) => {
      require(['src/views/gallery/index.vue'], resolve)
    }
  },
  // 作品墙详情
  {
    path: '/gallery-detail-list/:id',
    name: 'gallery-detail-list',
    component: (resolve) => {
      require(['src/views/gallery/galleryDetailList.vue'], resolve)
    }
  },
  // 作品墙：上传作品
  {
    path: '/gallery-add/:id',
    name: 'gallery-add',
    meta: {
      title: '上传作品',
    },
    component: (resolve) => {
      require(['src/views/gallery/addGallery.vue'], resolve)
    }
  },
  // 作品墙：作品详情
  {
    path: '/gallery-detail/:id',
    name: 'gallery-detail',
    meta: {
      title: '作品详情',
    },
    component: (resolve) => {
      require(['src/views/gallery/galleryDetail.vue'], resolve)
    }
  },
  {
    path: '/app-message-center',
    name: 'AppMessageCenter',
    meta: {
      title: '消息'
    },
    component: resolve => {
      require(['src/views/app-message-center/Index.vue'], resolve)
    }
  },
  {
    path: '/subjective-answer-upload/:lesson_id',
    name: 'subjective-answer-upload',
    component: resolve => {
      require(['src/views/subjective-answer-upload/index.vue'], resolve)
    }
  },
  {
    path: '/sync-exercise-answer-upload/:lesson_id',
    name: 'sync-exercise-answer-upload',
    component: resolve => {
      require(['src/views/subjective-answer-upload/index.vue'], resolve)
    }
  },
  {
    path: '/subjective/:lesson_id',
    name: 'subjective-detail',
    meta: {
      title: '主观题详情',
    },
    component: resolve => {
      require(['src/views/subjective/index.vue'], resolve)
    }
  },
  {
    path: '/sync-exercise/:lesson_id',
    name: 'sync-exercise-detail',
    meta: {
      title: '同步练习详情',
    },
    component: resolve => {
      require(['src/views/subjective/index.vue'], resolve)
    }
  },
  {
    path: '/feed-back',
    name: 'feedBack',
    meta: {
      pc: '/feed-back',
      title: '意见反馈',
    },
    component: r =>
      require.ensure(
        [],
        () => r(require("src/views/feed-back/index.vue")),
        'feedBack'
      )
  },
  {
    path: '/feed-back/result',
    name: 'feed-back-result',
    meta: {
      title: '反馈结果',
      pc: 'feed-back/result'
    },
    component: r => require.ensure([], () => r(require('src/views/feed-back/result.vue')), 'feed-back-result')
  },
  {
    path: '/feed-back/details',
    name: 'feed-back-details',
    meta: {
      title: '反馈详情',
      pc: 'feed-back/details'
    },
    component: r => require.ensure([], () => r(require('src/views/feed-back/details.vue')), 'feed-back-details')
  },
  {
    path: '/keyPointsByTeacher/:lessonId',
    name: 'keyPointsByTeacher',
    meta: {
      title: '老师笔记'
    },
    component: r => require.ensure([], () => r(require('src/views/keyPointsByTeacher/keyPointsByTeacher.vue')), 'keyPointsByTeacher')
  },
  // 续科专题路由
  {
    path: '/renewal/:activityId',
    name: 'renewal-activity-index',
    meta: {
      title: '星火网校'
    },
    component: resolve => {
      require(['src/views/experience-lesson/renewal/index/index.vue'], resolve)
    }
  },
  {
    path: '/renewal-order-confirm',
    name: 'renewal-order-confirm',
    meta: {
      title: '确定订单'
    },
    component: resolve => {
      require(['src/views/experience-lesson/renewal/order/confirm/index.vue'], resolve)
    }
  },
  // 组卷
  {
    path: '/test-paper/:paperId',
    name: 'test-paper',
    meta: {
      pc: '/test-paper/:paperId',
    },
    component: resolve => {
      require(['src/views/test-paper/index.vue'], resolve)
    }
  },
  // 体验课时
  {
    path: '/trial-class',
    name: 'trial-class',
    component: resolve => {
      require(['src/views/experience-lesson/trialclass/index.vue'], resolve)
    }
  },
  {
    path: '/trial-class-player/:id',
    name: 'trial-class-player',
    component: resolve => {
      require(['src/views/experience-lesson/trialclass/videoplayer/index.vue'], resolve)
    }
  },
  {
    path: '/fission/detail/:id',
    name: 'fission-detail',
    component: resolve => {
      require(['src/views/fission/index.vue'], resolve)
    }
  },
  {
    path: '/order/posterShare/:id',
    name: 'order-posterShare',
    meta: {
      title: '海报分享',
    },
    component: resolve => {
      require(['src/views/poster/index.vue'], resolve)
    }
  },
  {
    path: '*',
    redirect: 'notfound'
  }
]