export default <template>
  <XiaoBottomModal 
    title="完善资料"
    overflowSinking="4.25rem"
    :visible.sync="innerVisible"
  >
    <UserInfoForm @user-info-saved="handleSave"/>
  </XiaoBottomModal>
</template>

<script>
import XiaoBottomModal from 'components/xiao-modal/XiaoBottomModal.vue'
import PhoneInput from 'src/views/account/components/PhoneInput.vue'
import PasswordInput from 'src/views/account/components/PasswordInput.vue'
import FetchCaptcha from 'src/views/account/components/FetchCaptcha.vue'
import UserInfoForm from './UserInfoForm.vue'
import {
  api_passwordLogin,
  api_getPhoneStatus,
  api_captchaLogin,
  api_getVoiceCode,
} from 'api/account'
import globalCookieHandler from 'src/store/cookie'
import afterLoginMixin from 'src/vue-utils/mixins/afterLogin.js'
import { api_getCityCode } from 'src/api/experience-lesson'

export default {
  mixins: [afterLoginMixin],
  components: {
    XiaoBottomModal,
    UserInfoForm,
    PasswordInput,
    FetchCaptcha
  },
  props: {
    visible: {
      typeof: Boolean,
      default: false
    },
    closeOnClickDimmer: {
      typeof: Boolean,
      default: true
    },
    customize: {
      typeof: Boolean,
      default: false
    }
  },
  computed: {
    innerVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSave(infor) {
      this.innerVisible = false
      this.$emit('save', infor)
    },
    handleClose() {
      this.innerVisible = false
    }
  }
}
</script>

<style lang="scss" module>
.boxStyle {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #ffffff;
  border-radius: 0.32rem 0.32rem 0px 0px;
  overflow: hidden;
}
.modalHeader {
  height: 1.28rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5333333333333333rem;
  text-align: center;
  background: #fafafa;
  font-size: 0.4266666666666667rem;
  font-weight: 500;
  color: #464646;
  .iconClose {
    position: absolute;
    top: 50%;
    right: 0.5866666666666667rem;
    transform: translateY(-50%);
    font-size: 0.4266666666666667rem;
    color: #b3b3b3;
  }
}
</style>