import Vue from 'vue'
export default function getMiniProgram() {
  try {
    if (wx && wx.miniProgram) {
      wx.miniProgram.getEnv(function(res) {
        Vue.prototype.$UA.miniProgram = res.miniprogram
      })
    } else {
      Vue.prototype.$UA.miniProgram = false
    }
  } catch(err) {
    console.log(err)
  }
}