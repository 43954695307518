const day = ['星期天','星期一','星期二','星期三','星期四','星期五','星期六']
export default function(value, format) {
  let date = value instanceof Date ? value : new Date(value * 1000)
  let map = {
    "M": date.getMonth() + 1, //月份
    "D": date.getDate(), //日
    "h": date.getHours(), //小时
    "m": date.getMinutes(), //分
    "s": date.getSeconds(), //秒
    "w": day[date.getDay()]
  }
  let result = format.replace(/([YMDhmsw])+/g, function(all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
      }
      return v
    } else if (t === 'Y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all  
  })
  return result
}
