import http from 'utils/http'
import http401 from 'utils/http401'
const catchHttp401Error = http401.catchHttp401Error

/**
 * 购物车详细信息[http://yapi.xhwx100.com/project/11/interface/api/7612]
 */
export function api_getCartDetails() {
  const url = '/koala/api/v1.6/cart/details'
  return http.get(url).catch(catchHttp401Error)
}

/**
 * 购物车删除课程[http://yapi.xhwx100.com/project/11/interface/api/7572]
 */
export function api_removeCourse(params) {
  const url = '/koala/api/v1.6/cart/remove'
  return http.put(url, params).catch(catchHttp401Error)
}

/**
 * 购物车获取课程价格[http://yapi.xhwx100.com/project/11/interface/api/7462]
 */
export function api_getCoursesPrice(params) {
  const url = '/koala/api/v1.5/curriculum/batch/price-info'
  return http.post(url, params).catch(catchHttp401Error)
}

/**
 * 购物车根据满减id获取满减优惠活动[http://yapi.xhwx100.com/project/11/interface/api/8927]
 */
export function api_getPriceBreakCourses(params) {
  const url = '/koala/api/v1.6/price-break/curriculums'
  return http.post(url, params).catch(catchHttp401Error)
}

/**
 * [获取联报关联课程列表](http://yapi.xhwx100.com/project/11/interface/api/9738)
 */
export const api_getJointRelated = (params) => {
  const url = '/koala/api/v1.6/joint-discount/curriculums'
  return http.post(url, params).catch(catchHttp401Error)
}

/**
 * 购物车满减凑单页获取学科列表
 */
export function api_getSubject(params) {
  const url = '/koala/api/v1.5/subject/list'
  return http.post(url, params).catch(catchHttp401Error)
}

/**
 * 入学诊断
 * [课程判断](http://dev-yapi.xhwx100.com/project/11/interface/api/4650)
 */
export function api_checkQuestionnaire(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/curriculum'
  return http.get(url, { params }).catch(catchHttp401Error)
}

/**
 * 联报入学诊断
 * [课程判断](http://dev-yapi.xhwx100.com/project/11/interface/api/4650)
 */
export function api_checkJointQuestionnaire(params) {
  const url = '/koala/api/v1.6/curriculum/questionnaire/joint'
  return http.post(url, params).catch(catchHttp401Error)
}

/**
 * 加入购物车[http://yapi.xhwx100.com/project/11/interface/api/7562]
 */
export function api_addCart(params) {
  const url = '/koala/api/v1.6/cart/add'
  return http.put(url, params).catch(catchHttp401Error)
}

/**
 * 获取购物车数量[http://yapi.xhwx100.com/project/11/interface/api/7557]
 */
export function api_getCartCount() {
  const url = '/koala/api/v1.6/cart/hint'
  return http.get(url).catch(catchHttp401Error)
}

/**
 * [联报弹窗价格计算](http://yapi.xhwx100.com/project/11/interface/api/13680)
 */
export function api_getJointPriceInfo(params) {
  const url = '/koala/api/v1.6/joint-discount/price-calculate'
  return http.post(url, params).catch(catchHttp401Error)
}
