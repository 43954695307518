import http from './http'
import store from 'vux/store'

const randomMeta = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
  "U", "V", "W", "X", "Y", "Z",
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j",
  "k", "l", "m", "n", "o", "p", "q", "r", "s", "t",
  "u", "v", "w", "x", "y", "z"]

function createRandomStr(length) {
  let randomString = ''
  for (let i = 0; i < length; ++i) {
    const idx = Math.floor(62 * Math.random())
    randomString += randomMeta[idx]
  }
  return randomString
}

const wxConfigBase = {
  debug: process.env.NODE_ENV === 'development',
  appId: __WXSDKID__,
  jsApiList: [
    'onMenuShareTimeline',
    'onMenuShareAppMessage',
    'onMenuShareQQ',
    'onMenuShareQZone'
  ]
}

let wxSigned = false

function onWechatShare(shareMeta) {
  // 分享到朋友圈
  wx.onMenuShareTimeline(shareMeta)
  // 分享给朋友
  wx.onMenuShareAppMessage(shareMeta)
  // 分享到 QQ
  wx.onMenuShareQQ(shareMeta)
  // 分享到 QQ 空间
  wx.onMenuShareQZone(shareMeta)
}

wx.error(res => {
  console.log(res)
  // 如果签名过期，在这里重新签名和config
})

/*

iOS（针对微信 6.5.3 及以后的版本（WebkitWebview）History 模式）

window.__wxjs_is_wkwebview

1. 第一次进入页面，使用 window.location.href.split('#')[0] 作为签名的 URL 进行签名（后端签名），签名完成调用1次 wx.config
2. 每次进入需要分享的页面的时候，都要设置分享信息（调用 onWechatShare），如果是第一次进入页面，必须等 wx.config 成功后再调用
3. 必须等 url 发生变化后再设置分享信息，如果在 vue-router 的勾子函数中设置，最好使用异步

Android 及旧版本的 iOS

1. 每次切换新页面都需要用新页面的 URL 进行签名和调用 wx.config
2. 同 iOS
3. 同 iOS

*/

function wxSignAndConfig() {
  let signUrl = ''
  if (window.__wxjs_is_wkwebview) {
    signUrl = store.state.wx.url.split('#')[0]
  } else {
    signUrl = window.location.href.split('#')[0]
  }
  const nonceStr = createRandomStr(32)
  const timestamp = Math.floor(new Date().getTime() / 1000)
  const params = {
    url: encodeURIComponent(signUrl),
    noncestr: nonceStr,
    timestamp
  }
  const newWxConfig = Object.assign({}, wxConfigBase, {
    timestamp,
    nonceStr
  })
  return http.get('/koala/api/v1.5/wechat/sign', { params })
    .then(resp => {
      newWxConfig.signature = resp.data.signature
      wx.config(newWxConfig)
      if (window.__wxjs_is_wkwebview) {
        wxSigned = true
      }
      return newWxConfig
    })
}

export default {
  simpleConfig(meta, path) {
    this.config(() => {
      const shareMeta = {
        title: meta.title,
        desc: meta.desc,
        link: __HOST__ + path,
        imgUrl: meta.imgUrl
      }
      onWechatShare(shareMeta)
    })
  },
  config(onShareConfig) {
    if (wxSigned) {
      setTimeout(() => {
        wx.ready(() => {
          onShareConfig()
        })
      }, 300)
    } else {
      wxSignAndConfig().then(() => {
        wx.ready(() => {
          onShareConfig()
        })
      })
    }
  },
  /**
   * 配置微信分享
   * @param {object} shareMeta
   * @param {string} shareMeta.title - 分享标题
   * @param {string} shareMeta.desc - 分享描述
   * @param {string} shareMeta.link - 分享链接，该链接域名必须与当前企业的可信域名一致
   * @param {string} shareMeta.imgUrl - 分享图标
   * @param {Function} [shareMeta.success] - 用户确认分享后执行的回调函数
   * @param {Function} [shareMeta.cancel] - 用户取消分享后执行的回调函数
   */
  configWxShare(shareMeta) {
    this.config(() => {
      // 分享到朋友圈
      wx.onMenuShareTimeline(shareMeta)
      //分享给朋友
      wx.onMenuShareAppMessage(shareMeta)
      // 分享到 QQ
      wx.onMenuShareQQ(shareMeta)
      // 分享到 QQ 空间
      wx.onMenuShareQZone(shareMeta)
    })
  }
}
