export default <template>
  <div :class="$style.topBarWrap">
    <template v-if="user && user.phone">
      <img :class="isRenewal ? $style.newAvatar :$style.avatar"
          :src="user.portrait_url"
          alt="">
      <div :class="$style.newInfo">
        <span :class="$style.name">{{user.remark_name || user.name}}</span>
        <span :class="$style.phone">{{user.phone | hidePhoneNumber}}</span>
      </div>
      <div :class="$style.box" v-if="!inWebview">
        <!-- <template v-if="!isRenewal">
          <div :class="$style.switchStudent"
            v-if="studentNum > 1 "
            @click="switchStudent">切换学员</div>
          <div :class="$style.logoutWrap"
            @click="logout">
            <div :class="$style.logout">退出登录</div>
          </div>
        </template> -->
        <template>
          <div :class="$style.newSwitchStudent"
            @click="switchStudentNew">切换账号</div>
        </template>
      </div>
    </template>
    <div :class="$style.headerWrapper" v-else>
      <div :class="$style.goLogin">
        <div :class="$style.avatar">
          <img
            src="./avatar_dis@2x.png"
            alt="加载失败"
          >
        </div>
        未登录
      </div>
      <button
        :class="$style.btnLogin"
        @click="login"
      >登录</button>
    </div>
    <!--登录注册弹窗-->
    <Login
      :visible.sync="loginModalVisible"
      @afterLogin="handleLogin"
      :needCompleteInfor="true"
      :customize="true"
    ></Login>
    <!--完善信息弹窗-->
    <CompleteInforModal
      :visible.sync="completeInforModalVisible"
      @save="handleCompleteInfor"
    ></CompleteInforModal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import globalCookieHandler from 'src/store/cookie'
import afterLoginMixin from 'src/vue-utils/mixins/afterLogin.js'
import { api_getStudentInfo } from 'src/api/account'
export default {
  name: 'UserTopBar',
  mixins: [afterLoginMixin],
  data() {
    return {
      loginModalVisible: false,
      completeInforModalVisible: false
    }
  },
  props: {
    isRenewal: {
      type: Boolean,
      default: false
    }
  },
  created () {
    window.onpageshow = function (event) {
      if (event.persisted) {
        // 页面来自缓存， reload，解决iosAPP页面缓存问题
        window.location.reload()
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user,
      studentNum: state => state.studentNum
    }),
    inWebview() {
      return this.$UA.webview
    }
  },
  methods: {
    switchStudentNew() {
      this.loginModalVisible = true
    },
    login() {
      if (this.$UA.webview) {
        let redirectUrl = this.$route.fullPath
        const fullUrl = __HOST__ + redirectUrl
        if (this.$UA.androidWebview) {
          Callback.redirect('Login', [fullUrl])
        } else if (this.$UA.iosWebview) {
          window.webkit.messageHandlers.redirectLogin.postMessage({ body: fullUrl })
        }
      } else {
        this.loginModalVisible = true
      }
    },
    async handleCompleteInfor(infor) {
      try {
        const res = await api_getStudentInfo()
        const student = _.get(res, 'data.student', null)
        if (!student) return
        const info = this.studentFormat(student)
        this.$store.commit('auth/SET_USER_INFO', info)
        this.$emit('completeInfor', infor)
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      }
    },
    switchStudent () {
      // 切换学员回来后都是回到入学测试入口
      let redirectUrl = this.$route.fullPath
      redirectUrl = redirectUrl.replace(this.$route.path, '/admission-test')
      if (this.$UA.webview) {
        const fullUrl = __HOST__ + redirectUrl
        if (this.$UA.androidWebview) {
          Callback.redirect("switchStudent", [fullUrl])
        } else if (this.$UA.iosWebview) {
          window.webkit.messageHandlers.switchStudent.postMessage({
            body: fullUrl
          })
        }
      } else {
        this.$router.push(redirectUrl)
        // redirectUrl已经encodeURIComponent，不需要二次处理
        this.$router.push(`/SwitchStudent?redirecturi=${redirectUrl}`)
      }
    },
    logout () {
      if (this.$UA.webview) {
        if (this.$UA.androidWebview) {
          Callback.redirect("webLoginOut", [])
        } else if (this.$UA.iosWebview) {
          window.webkit.messageHandlers.webLoginOut.postMessage({})
        }
      } else {
        globalCookieHandler['token'] = null
        globalCookieHandler['user_id'] = null
        try {
          if (window.gio) {
            gio('clearUserId')
          }
        } catch (err) {
          // do nothing
        }
        this.$store.commit('course/CLEAR_CITY')
        localStorage.removeItem('student_info')
        sessionStorage.removeItem('XTAG')
        sessionStorage.removeItem('XHCODE')
        location.replace('/my')
      }
    },
    handleLogin(res) {
      const student = _.get(res, 'data.student', null)
      if (!student) return
      globalCookieHandler.token = res.data.token
      globalCookieHandler.user_id = student.id
      this.$store.commit('auth/SET_AUTH_TOKEN', res.data.token)
      this.loginModalVisible = false
      const info = this.studentFormat(student)
      this.$store.commit('auth/SET_USER_INFO', info)
      const infoCompleted = !!(info.name && info.grade && info.province)
      this.$emit('login')
      if (!infoCompleted) {
        this.loginModalVisible = false
        this.completeInforModalVisible = true
      } else {
        this.$emit('refresh')
      }
    }
  },
  components: {
    Login: () => import('components/login/login.vue'),
    CompleteInforModal: () => import('components/CompleteInforModal/index.vue')
  }
}
</script>

<style lang="scss" module>
@import 'app/sass/utils/_mixin.scss';
.headerWrapper {
  width: 100%;
  padding: 0 0.32rem 0 0;
  height: 0.96rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: .0133333333333333rem solid #EBEBEB;
}
.goLogin {
  display: flex;
  align-items: center;
  font-size: 0.3733333333333334rem;
  font-weight: 400;
  color: #5a5a5a;
  .avatar {
    width: 0.74666667rem;
    height: 0.74666667rem;
    margin-right: 0.21333333rem;
    border-radius: 0.85333333rem;
    margin-left: 0.32rem!important;
  }
}
.btnLogin {
  @include button(1.8133333333333332rem, 0.7466666666666667rem, #00c55d);
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 0.1066666666666667rem;
}
.topBarWrap {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: .96rem;
  background: rgba(255, 255, 255, 1);
  align-items: center;
  font-size: 0;
}
.avatar {
  border-radius: 50%;
  width: 0.74666667rem;
  height: 0.74666667rem;
  margin: 0 0.10666667rem 0 0.32rem;
}
.newAvatar {
  border-radius: 50%;
  width: .74666667rem;
  height: .74666667rem;
  margin: 0 0.10666667rem 0 0.4rem;
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: rgba(90, 90, 90, 1);
  .name {
    height: 0.37333333rem;
    font-size: 0.37333333rem;
    font-weight: 400;
    line-height: 0.37333333rem;
    margin-bottom: 0.10666667rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .phone {
    height: 0.32rem;
    font-size: 0.32rem;
    font-weight: 400;
    line-height: 0.32rem;
  }
}
.newInfo {
  flex: 1;
  display: flex;
  color: rgba(90, 90, 90, 1);
  align-items: center;
  .name {
    font-size: .37333333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5A5A5A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 .21333333rem 0 .08rem; 
  }
  .phone {
    font-size: .37333333rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #B3B3B3;
  }
}
.switchStudent {
  display: inline-block;
  padding: 0 10px 0 16px;
  height: .96rem;
  font-size: 0.37333333rem;
  font-weight: 400;
  color: rgba(90, 90, 90, 1);
  line-height: .96rem;
  vertical-align: middle;
}
.newSwitchStudent {
  display: flex;
  align-items: center;
  background: #F7F7F8;
  height: .74666667rem;
  font-size: .32rem;
  font-family: SFProText-Regular, SFProText;
  font-weight: 400;
  color: #5A5A5A;
  border-radius: .10666667rem;
  padding: 0 .26666667rem;
  margin-right: .32rem;
}
.box {
  display: flex;
  align-items: center;
}
.logoutWrap {
  display: inline-block;
  padding-right: 10px;
  box-sizing: border-box;
  vertical-align: middle;
}
.logout {
  padding: 0 8px; // 没有写错，是px
  height: 0.64rem;
  background: rgba(229, 249, 238, 1);
  border-radius: 0.10666667rem;
  font-size: 0.37333333rem;
  font-weight: 400;
  color: rgba(0, 197, 93, 1);
  line-height: 0.64rem;
}
</style>
