import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from 'vux/store'
import globalCookieHandler from 'src/store/cookie'
import pcRedirect from 'utils/pcRedirect'
import wxsdk from 'utils/wxsdk'
import shareImageUrl from 'src/images/logo@2x.png'
import { deepGet } from 'utils/lang'
import { api_authCode } from 'src/api/checkout'
import xiaoToast from 'utils/xiao-toast'
import { activityShareSession } from 'src/store/session'
import { LOGGER_ENTER_ROUTES } from 'enum/logger'
import logger from 'utils/logger'
import { api_authCodeLogin } from 'src/api/account'

function parseQuery(params) {
  if (typeof params !== 'object') return ''
  return Object.keys(params).reduce((t, key, i) => {
    if (i == 0) {
      t += `?${key}=${params[key]}`
    } else {
      t += `&${key}=${params[key]}`
    }
    return t
  }, '')
}

function formatLink(link, authCode) {
  if (!link) link = ''
  const includeParameter = link.indexOf('?') > 0
  return includeParameter
    ? `${link}&authCode=${authCode}`
    : `${link}?authCode=${authCode}`
}
Vue.use(Router)

// 需要进行埋点的路由, 对应路由的name
const sendEnterRoutes = [
  'experience-lesson-experience-courses',
  'CaptchaLogin-phone',
  'wechatLoginSelect',
]

const singleton = new Router({
  mode: 'history',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes,
})

singleton.beforeEach((to, from, next) => {
  const ua = Vue.prototype.$UA
  if (ua.pc) {
    // 如果登录了 直接获取authCode加入query跳转
    if (!globalCookieHandler.token) {
      if (to.meta.pc) {
        const link = pcRedirect(to)
        const queryStr = parseQuery(to.query)
        if (to.meta.needQuery && queryStr) {
          window.location.href = `${__PC__DOMAIN__}${link}${queryStr}`
        } else {
          window.location.href = `${__PC__DOMAIN__}${link}`
        }
        return
      } else if (typeof to.meta.pcRedirect === 'function') {
        window.location.href = to.meta.pcRedirect(to)
        return
      }
    }
    api_authCode()
      .then(res => {
        if (!res || !res.data) return
        const authCode = res.data.auth_code
        let link = ''
        if (to.meta.pc) {
          const queryStr = parseQuery({
            ...to.query,
            authCode,
          })
          if (to.meta.needQuery && queryStr) {
            window.location.href = `${__PC__DOMAIN__}${pcRedirect(
              to
            )}${queryStr}`
          } else {
            link = formatLink(pcRedirect(to), authCode)
            window.location.href = `${__PC__DOMAIN__}${link}`
          }
          return
        } else if (typeof to.meta.pcRedirect === 'function') {
          link = formatLink(to.meta.pcRedirect(to), authCode)
          window.location.href = link
          return
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  if (!store.state.wx.url) {
    store.commit('wx/setUrl', { url: document.URL })
  }

  if (to.meta.title) {
    document.title = to.meta.title || '星火网校'
  }
  if (to.query.share_id) {
    activityShareSession.setActivityShareParams({
      share_id: to.query.share_id,
    })
  }
  if (to.query.review === 'true') {
    localStorage.setItem('isFromIosReview', true)
  }
  // 注册来源
  if (to.query.xhcode) {
    sessionStorage.setItem('XHCODE', Number(to.query.xhcode))
  }
  if (to.query.authCode) {
    const params = {
      auth_code: to.query.authCode,
    }
    api_authCodeLogin(params)
      .then(res => {
        globalCookieHandler.token = res.data.token
        globalCookieHandler.user_id = res.data.student.id
        store.commit('auth/SET_AUTH_TOKEN', res.data.token)
        let url = to.fullPath.replace(/&?authCode=[^&]+/g, '')
        next({
          path: url,
          replace: true,
        })
      })
      .catch(() => {
        xiaoToast.warning('授权错误，请重新登录')
        next({ path: '/CaptchaLogin/phone' })
      })
  } else {
    getUserInfo()
  }

  function getUserInfo() {
    // 完善用户信息不能进入请求学生数据的逻辑，否则可能会反复跳回
    if (
      to.name === 'CompleteUserInfo' ||
      to.name === 'order-success' ||
      to.name === 'setting-password' ||
      to.name === 'experience-lesson-friends-generalize' ||
      to.name === 'experience-lesson-friends-generalize-order' ||
      to.name === 'experience-lesson-friends-generalize-detail'
    ) {
      next()
    } else if (
      to.name === 'experience-lesson-order-system' ||
      to.name === 'PintuanActivity' ||
      to.name === 'PintuanShare' ||
      to.name === 'experience-lesson-new-order-system' ||
      to.name === 'order-posterShare' || 
      to.name === 'renewal-activity-index' || 
      to.name === 'fission-detail' ||
      to.name === 'order-system-award'
    ) {
      fetchUserInfoRemote('basic', false)
    } else if (!store.state.auth.hasInitUserInfo) {
      fetchUserInfoRemote('full')
    } else if (to.meta.needUserInfo) {
      fetchUserInfoRemote()
    } else {
      next()
    }
  }

  /**
   * 拿用户信息
   * @param {type}
   * basic 基本信息，full: 全部信息
   **/
  function fetchUserInfoRemote(type = 'basic', needComplete = true) {
    let dispatchType =
      type === 'basic' ? 'auth/getUserBasicInfo' : 'auth/getUserInfo'
    if (globalCookieHandler['token'] && globalCookieHandler['user_id']) {
      const params = {
        user_id: globalCookieHandler['user_id'],
      }
      store.dispatch(dispatchType, params).then(
        () => {
          if (!store.state.auth.infoCompleted && needComplete) {
            //由当前链接改为目标路由链接，因为是目标路由需要填写用户信息
            // sessionStorage.setItem('afterLoginRoute', window.location.href)
            sessionStorage.setItem('afterLoginRoute', `${__HOST__}${to.fullPath}`)
            next({ name: 'CompleteUserInfo' })
          } else {
            if (needComplete) {
              store.dispatch('auth/setInitUserInfoStatus', true)
            }
            next()
          }
        },
        () => {
          globalCookieHandler['token'] = null
          globalCookieHandler['user_id'] = null
          next()
        }
      )
    } else {
      store.dispatch('auth/setInitUserInfoStatus', false)
      globalCookieHandler['token'] = null
      globalCookieHandler['user_id'] = null
      next()
    }
  }
})

singleton.afterEach(to => {
  /**
   * 分享文案分3种：
   * 1. 有固定分享文案的，在`afterEach`同步设置，路由配置中必须设置`share: {title, desc, imgUrl}`
   * 2. 没有固定分享文案，需要异步设置的，在各自的页面异步回调中设置，路由配置中必须设置`asyncShare: true`
   * 3. 其它没有设置`share`或`asyncShare`的，用统一的文案
   *
   * 每次都要重新配置微信 sdk 啊，必须啊，不配置就被微信艹
   */
  const ua = Vue.prototype.$UA
  if (ua.weixin) {
    if (to.meta && to.meta.share) {
      wxsdk.simpleConfig(to.meta.share, to.fullPath)
    } else if (!to.meta || !to.meta.asyncShare) {
      wxsdk.simpleConfig(
        {
          title: '星火网校',
          desc: '中小学在线辅导品牌',
          imgUrl: shareImageUrl,
        },
        to.fullPath
      )
    } // else { 没有固定分享文案，需要异步设置的 }
  } else if (ua.qq) {
    if (to.meta && to.meta.share) {
      const shareMeta = to.meta.share
      setShareInfo({
        title: shareMeta.title,
        summary: shareMeta.desc,
        pic: shareMeta.imgUrl,
        url: to.fullPath,
      })
    } else if (!to.meta || !to.meta.asyncShare) {
      setShareInfo({
        title: '星火网校',
        summary: '中小学在线辅导品牌',
        pic: shareImageUrl,
        url: to.fullPath,
      })
    } // else { 没有固定分享文案，需要异步设置的 }
  }

  // 通知app是否需要显示购物车
  const showShoppingCart = !!to.meta.showShoppingCart
  if (ua.iosWebview) {
    // 兼容app旧包没有注册该监听事件
    if (deepGet(window, ['webkit', 'messageHandlers', 'isShowShoppingCart'])) {
      window.webkit.messageHandlers.isShowShoppingCart.postMessage({
        body: showShoppingCart,
      })
    }
  } else if (ua.androidWebview) {
    Callback.redirect('isShowShoppingCart', [
      showShoppingCart ? 'true' : 'false',
    ])
  }

  // 埋点
  const { name } = to
  if (sendEnterRoutes.includes(name)) {
    const param = {
      topic: 'page',
      event: 'access',
      content: {
        user_id: globalCookieHandler.user_id || '',
        device_id: localStorage.getItem('cookie_id') || '',
        page_id: name,
        page_desc: LOGGER_ENTER_ROUTES[name],
      },
    }
    if (name === 'CaptchaLogin-phone' || name === 'wechatLoginSelect') {
      const afterLoginRoute = sessionStorage.getItem('afterLoginRoute')
      // 微信环境下，已在选择登录方式页埋点
      if (name === 'CaptchaLogin-phone' && ua.weixin) return
      if (
        afterLoginRoute &&
        afterLoginRoute.indexOf('experience-lesson/experience-courses') >= 0
      ) {
        logger(param)
      }
    } else {
      logger(param)
    }
  }
})

export default singleton
